import unitOfWork from '@controllers';

const fetchBackground = (setImgLink) => {
    const cancelController = new AbortController();

    unitOfWork.site.get(cancelController.signal, setImgLink);

    return cancelController;
};

export { fetchBackground };
