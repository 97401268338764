import axios from 'axios';
import { logger } from '.';

const env = process.env.NODE_ENV || 'development';
const baseUrl = '/api';

const callApi = async (endpoint, method, data, user, headers, query) => {
  const options = {
    url: baseUrl + endpoint,
    method,
    headers,
  };

  if (env === 'development') {
    options.proxy = {
      protocol: 'http',
      host: 'localhost',
      port: 8000,
    };
  }

  if (method === 'get') {
    Object.assign(options, data ? { params: data } : {});
  } else {
    Object.assign(options, { data } || {});
    Object.assign(options, { params: query } || {});
  }

  if (user && user.token) {
    options.headers = {
      Authorization: `Bearer ${user.token}`,
    };
  }

  try {
    const result = await axios({
      ...options,
    });
    logger?.log(
      '-----------------------------------------------------------------------',
    );
    logger?.log(method, endpoint, data, query);
    logger?.log(result.data);
    logger?.log(
      '-----------------------------------------------------------------------',
    );

    return result.data.data;
  } catch (e) {
    logger?.error(e);
    logger?.error(e.response);
    throw e;
  }
};

const get = ({ endpoint, query, user, header }) =>
  callApi(endpoint, 'get', query, user, header);
const post = ({ endpoint, data, user, header, query }) =>
  callApi(endpoint, 'post', data, user, header, query);
const put = ({ endpoint, data, user, header, query }) =>
  callApi(endpoint, 'put', data, user, header, query);
const del = ({ endpoint, data, user, header, query }) =>
  callApi(endpoint, 'delete', data, user, header, query);

export const request = {
  get,
  post,
  put,
  del,
};
