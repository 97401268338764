import React, { useRef, useState, memo, useCallback } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import styles from './style.module.scss';
import { connect } from 'react-redux';
import actions from '@/application/actions';
import EditOnRight from './EditOnRight';
import { Button } from 'primereact/button';

function ListNote(props) {
  const {
    state: {
      masterData: { noteGroupRules },
      user: { noteGroups },
    },
  } = props;
  const toast = useRef(null);

  const [formData, setFormData] = useState({
    isOpen: false,
    data: null,
  });

  const selectNoteRule = useCallback((data) => () => {
    setFormData({
      isOpen: true,
      data: {
        ...data,
        noteGroups: data.noteGroups.list.map(({ id }) => id),
      },
    });
  });

  const onCloseEdit =  () => setFormData({ isOpen: false, data: null });

  const onBtnNewClick = useCallback(() => {
    setFormData({
      isOpen: true,
      data: null,
    });
  }, []);

  const actionColumn = (rowData) => {
    return (
      <div className="items-center justify-center flex gap-[4px]">
        <i
          className="pi pi-user-edit text-2xl cursor-pointer"
          onClick={selectNoteRule(rowData)}
        ></i>
      </div>
    );
  };
  const notesTemplate = ({ noteGroups }) => {
    return (
      <div className={styles.noteGroupText}>
        {noteGroups.list.map(({ name, id }) => (
          <p key={`value_note_group_${id}`}>{name}</p>
        ))}
      </div>
    );
  };

  const paginatorTemplate = {
    layout:
      'CurrentPageReport  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
            position: 'absolute',
            left: 0,
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="container py-4 h-full">
      <h3 className="px-3 text-3xl text-600 uppercase mb-6 py-8 flex items-center">
        Danh sách nhóm quyền ghi chú
        <Button
          type="button"
          label="Tạo mới"
          onClick={onBtnNewClick}
          className={classNames(styles.btnNew, {
            hidden: formData.isOpen,
          })}
        />
      </h3>

      <div
        className={classNames('fadeinright', {
          hidden: !formData.isOpen,
        })}
      >
        <h3
          className="text-2xl flex gap-3 align-items-center cursor-pointer"
          onClick={onCloseEdit}
        >
          <i className="pi pi-angle-left" />
          <span className="font-normal mr-1 inline-block">
            {(formData?.data && formData?.data?.name) || 'Tạo mới'}
          </span>
        </h3>
      </div>

      <DataTable
        value={noteGroupRules.list}
        paginator
        sortField="isBan"
        sortOrder={1}
        paginatorTemplate={paginatorTemplate}
        rows={12}
        paginatorClassName="relative"
        className={classNames('fadeinleft max-h-[770px] overflow-auto', {
          hidden: formData.isOpen,
        })}
      >
        <Column
          alignHeader="left"
          className="text-center"
          field="name"
          header="Tên nhóm"
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          header="Nhóm ghi chú"
          body={notesTemplate}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          header="Hành động"
          body={actionColumn}
        ></Column>
      </DataTable>

      <EditOnRight
        formData={formData}
        noteGroups={noteGroups}
        actions={props.actions}
        onBack={onCloseEdit}
        toast={toast}
      />

      <Toast ref={toast} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(memo(ListNote));
