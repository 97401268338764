import unitOfWork from '@controllers';

const submitForm = (
  shipService,
  setToastMessage,
  fetchShipServices,
  closeEditButton,
) => {
  if (shipService.id) {
    unitOfWork.shipService.update(
      shipService,
      setToastMessage,
      fetchShipServices,
      closeEditButton,
    );
  } else {
    unitOfWork.shipService.create(
      shipService.name,
      setToastMessage,
      fetchShipServices,
      closeEditButton,
    );
  }
};

export default { submitForm };
