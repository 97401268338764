import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectPageById, selectTotalOrderByPage } from '../slice';
import styles from './MarketByPage.module.scss';

function MarketByPage() {
  const pages = useSelector(selectTotalOrderByPage);
  const pageByIds = useSelector(selectPageById);

  return (
    <div className={clsx('mx-[16px]')}>
      <div className={clsx(styles.header, styles.row)}>
        <div className={styles.cell}>Kênh TMĐT</div>
        <div className={styles.cell}>Số đơn</div>
      </div>

      <div className={clsx(styles.body)}>
        {Object.keys(pages).map((pageId) => (
          <div className={clsx(styles.row)} key={pageId}>
            <div className={styles.cell}>{pageByIds[pageId].name}</div>
            <div className={clsx(styles.cell)}>{pages[pageId] || 0}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MarketByPage;
