// library
import moment from 'moment';
import clsx from 'clsx';
import React, { useRef, useContext, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { Button, MarketInfoTable, ToastMessage } from '@components';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import toastMessageType from '@constant/ToastMessage';
import styles from './NewMarketInfo.module.scss';

import { useThrottle } from '@hooks';
import event from './event';
import unitOfWork from '@controllers';
import { UserContext } from '@contexts/App';

function NewMarketInfo() {
  const eventHandler = useOutletContext();

  const toast = useRef(null);
  const [ecommercePages, setEcommercePages] = React.useState([]);
  const [createdAt, setCreatedAt] = React.useState(new Date());
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: { title: '', duration: 3000, desc: '' },
  });

  // Manager only
  const user = useContext(UserContext);
  const isManager = useMemo(() => {
    return user.role.type === 10;
  });
  const [users, setUsers] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleSelectedCreatedAt = React.useCallback((date) => {
    const startDate = moment().endOf('day');

    if (date > startDate) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Ngày cập nhật không được quá ngày hiện tại',
        },
      });
      return;
    } else {
      setCreatedAt(date);
    }
  }, []);

  const submitPagesOnEnterWithThrottle = useThrottle((e) => {
    if (e.key === 'Enter') {
      event.submitPages(createdAt.getTime(), ecommercePages, setToastMessage);
    }
  }, 1000);

  React.useLayoutEffect(() => {
    document.addEventListener('keyup', submitPagesOnEnterWithThrottle);

    return () => {
      document.removeEventListener('keyup', submitPagesOnEnterWithThrottle);
    };
  }, [createdAt, ecommercePages]);

  const fetchMarketInfo = React.useCallback(
    useThrottle(() => {
      if (createdAt) {
        const cancelController = eventHandler.fetchMarketInfo(
          createdAt.getTime(),
          selectedUser,
          setEcommercePages,
        );

        return () => {
          cancelController.abort();
        };
      } else {
        setEcommercePages(
          ecommercePages.map((page) => ({
            id: page.id,
            name: page.name,
            msgQuantity: 0,
            paid: 0,
            orders: 0,
          })),
        );
      }
    }, 1000),
    [ecommercePages],
  );

  const submitPage = useThrottle(
    React.useCallback(
      () =>
        event.submitPages(
          createdAt.getTime(),
          selectedUser,
          ecommercePages,
          setToastMessage,
        ),
      [ecommercePages, createdAt, selectedUser],
    ),
    1000,
  );

  React.useEffect(fetchMarketInfo, [createdAt, selectedUser]);

  React.useEffect(() => {
    if (isManager) {
      const cancelController = new AbortController();
      unitOfWork.user.getAll(cancelController.signal, setUsers, toast.value, {
        role: 'marketter',
        isActive: true,
      });

      return () => cancelController.abort();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="flex align-items-center">
        <div className={clsx(styles.datePicker)}>
          <label htmlFor="endDatePicker" className={styles.datePickerLabel}>
            Ngày cập nhật
          </label>

          <DatePicker
            selected={createdAt}
            onChange={handleSelectedCreatedAt}
            className={styles.select}
            dateFormat="dd/MM/yyyy"
          />
        </div>

        {isManager && (
          <Dropdown
            optionLabel="username"
            placeholder="Select a role"
            options={users}
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.value)}
          />
        )}
      </div>

      <div className={clsx(styles.content)}>
        <div className={clsx(styles.table)}>
          <MarketInfoTable
            pages={ecommercePages}
            inputDisabled={{
              value: parseInt(createdAt) === 0,
              style: true,
            }}
            eventHandler={{
              onInputChange: (...params) =>
                event.onInputChange(...params, setEcommercePages),
            }}
            hide={['orders']}
          />

          <div className={clsx(styles.btns)}>
            <Button
              content="Lưu"
              disabled={parseInt(createdAt) === 0}
              onClick={submitPage}
            />
          </div>
        </div>

        <div className={styles.guide}>
          <h3>Hướng dẫn sử dụng</h3>
          <ol>
            <li>
              Chọn <b>ngày cập nhật</b> <br />
              (Nếu đơn đã được tạo thì chỉ có thể cập nhật trong vòng 1 giờ tính
              từ thời điểm tạo)
            </li>
            <li>
              Nhập <b>Số tiền</b>, <b>Số tin nhắn</b>
              <br />
              (cột <b>Tiền / Tin nhắn</b> sẽ tự động cập nhật)
            </li>
            <li>
              Click nút <b>Lưu</b> (hoặc gõ <b>Enter</b>) để lưu lại
            </li>
          </ol>
        </div>
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />

      <Toast ref={toast} />
    </React.Fragment>
  );
}

export default NewMarketInfo;
