import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import User from '../../user';

class Users extends BaseModel {
  static properties = {
    list: _p(User, new User()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: User;
  */
}

export default Users;
