import { _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities/list-filter';
import QtDate from '../../value-object/qtDate';

class OrderFilter extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    statusID: null,
    shipServiceID: null,
    ecommerceID: null,
    productID: null,
    from: _pc(QtDate, QtDate.startOf('d')),
    to: _pc(QtDate, QtDate.endOf('d')),
  };

  /*
  page: number | null;
  */

  get value() {
    const result = { ...this };
    const status = parseInt(result.status);

    if (Number.isNaN(status) || !status) {
      delete result.status;
    }

    return result;
  }
}

export default OrderFilter;
