import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import Note from '../note';

class Notes extends BaseModel {
  static properties = {
    list: _p(Note, new Note()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: Note;
  */
}

export default Notes;
