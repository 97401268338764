export const tabItems = [
  {
    link: '/market/new',
    name: 'Cập nhật',
    phone: <i className="fa-solid fa-pencil" />,
  },
  {
    link: '/market/statistic-detail',
    name: 'Thống kê chi tiết',
    phone: <i className="fa-solid fa-chart-pie" />,
  },
  {
    link: '/market/statistic',
    name: 'Thống kê tổng',
    phone: <i className="fa-solid fa-chart-pie" />,
  },
];
