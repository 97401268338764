const user = {
    username: ' ',
    name: ' ',
    role: {
        type: 0,
        name: ' ',
    },
    token: '',
};

export { user };
