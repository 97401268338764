import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '..';
import OrderFilter from './filter';

class OrderState extends BaseModel {
  static properties = {
    filter: _p(OrderFilter, new OrderFilter()),
  };
}

export default OrderState;
