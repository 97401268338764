import Joi from 'joi';

const customMessages = {
  email: 'Email không hợp lệ',
  phone: 'SĐT chỉ chứa số với độ dài từ 10 đến 11 ký tự',
};

const getMessage = (name, type, ...params) => {
  let requiredMessage = '';

  switch (type) {
    case 'number.base':
      requiredMessage = 'phải là số';
      break;
    case 'number.positive':
      requiredMessage = `> 0`;
      break;
    case 'number.min':
      requiredMessage = `> ${params[0]}`;
      break;
    case 'string.base':
      requiredMessage = 'không rỗng';
      break;
    case 'string.min':
      requiredMessage = `${params[0] ? `> ${params[0]}` : 'không rỗng'}`;
      break;
    case 'string.empty':
      requiredMessage = 'không rỗng';
      break;
    default:
      return '';
  }

  return `${name} ${requiredMessage}`;
};

export const QtProductSchema = Joi.object({
  id: Joi.optional(),
  name: Joi.string()
    .min(1)
    .messages({
      'string.empty': getMessage('Tên', 'string.empty'),
      'string.min': getMessage('Tên', 'string.min'),
    }),
  count: Joi.number()
    .positive()
    .messages({
      'number.positive': getMessage('Số lượng', 'number.positive'),
    }),
  importPrice: Joi.number()
    .positive()
    .messages({
      'number.positive': getMessage('Giá nhập', 'number.positive'),
    }),
  price: Joi.number()
    .positive()
    .messages({
      'number.positive': getMessage('Giá lẻ', 'number.positive'),
    }),
  wholeSalePrice: Joi.number()
    .positive()
    .messages({
      'number.positive': getMessage('Giá sỉ', 'number.positive'),
    }),
});

export const SupplierSchema = Joi.object({
  id: Joi.optional(),
  name: Joi.string()
    .min(1)
    .messages({
      'string.empty': getMessage('Tên', 'string.empty'),
      'string.min': getMessage('Tên', 'string.min'),
    }),
  company: Joi.string().allow(''),
  address: Joi.string().allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      'string.email': customMessages.email,
    })
    .allow(''),
  phone: Joi.string()
    .regex(/^[0-9]{10,11}$/)
    .messages({
      'string.empty': getMessage('SĐT', 'string.empty'),
      'string.pattern.base': customMessages.phone,
    }),
});

export const ImportOrderSchema = Joi.object({
  supplierId: Joi.number().messages({
    'number.base': 'Nhà cung cấp không rỗng',
  }),
  details: Joi.array()
    .min(1)
    .items(
      Joi.object({
        productId: Joi.number(),
        displayPrice: Joi.number(),
        count: Joi.number(),
      }),
    )
    .messages({
      'array.min': 'Chọn ít nhất 1 sản phẩm',
    }),
});
