import clsx from 'clsx';
import React from 'react';

import styles from './MarketInfoDetailTable.module.scss';

function MarketInfoDetailTable({
  pages,
  ecommercePages,
  setEcommercePages,
  bodyStyles,
}) {
  const [filterVisible, setFilterVisible] = React.useState(false);

  const toggleFilter = React.useCallback((e) => {
    e.stopPropagation();
    setFilterVisible((visible) => !visible);
  }, []);

  const onCheckPage = React.useCallback(
    (index) => () => {
      const newSelectedEcommerces = [...ecommercePages];
      newSelectedEcommerces[index].check = !newSelectedEcommerces[index].check;

      setEcommercePages(newSelectedEcommerces);
    },
    [ecommercePages],
  );

  const checkedAll = React.useMemo(() => {
    return ecommercePages.every((ecommerce) => ecommerce.check);
  }, [ecommercePages]);

  const onCheckAll = React.useCallback(() => {
    setEcommercePages((selectedEcommerces) =>
      selectedEcommerces.map((ecommerce) => ({
        ...ecommerce,
        check: !checkedAll,
      })),
    );
  }, [checkedAll]);

  const stopPropagation = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  React.useEffect(() => {
    const closeFilter = () => {
      setFilterVisible(false);
    };

    document.addEventListener('click', closeFilter);
    return () => document.removeEventListener('click', closeFilter);
  }, []);

  return (
    <React.Fragment>
      <div className={clsx(styles.header, styles.row)}>
        <div className={styles.cell}>Ngày</div>
        <div
          className={clsx(styles.cell, styles.cellWithIcon)}
          onClick={stopPropagation}
        >
          Kênh TMĐT
          <i className="fa-solid fa-filter" onClick={toggleFilter}></i>
          <div
            className={clsx(styles.filter, {
              [styles.show]: filterVisible,
            })}
          >
            <ul className={styles.filterList}>
              <li className={styles.filterItem}>
                <input
                  type="checkbox"
                  id="ecommerce-all"
                  checked={checkedAll}
                  onChange={onCheckAll}
                />
                <label htmlFor="ecommerce-all">Chọn tất cả</label>
              </li>
              {ecommercePages.map((ecommercePage, index) => (
                <li className={styles.filterItem} key={ecommercePage.id}>
                  <input
                    type="checkbox"
                    id={`ecommerce-${ecommercePage.id}`}
                    checked={ecommercePage.check}
                    onChange={onCheckPage(index)}
                  />

                  <label htmlFor={`ecommerce-${ecommercePage.id}`}>
                    {ecommercePage.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.cell}>Số đơn</div>
        <div className={styles.cell}>Số tiền</div>
        <div className={styles.cell}>Số tin nhắn</div>
        <div className={styles.cell}>Tiền / tin nhắn</div>
      </div>

      <div className={clsx(styles.body)} style={bodyStyles}>
        {Object.keys(pages).map((date, dateIndex) =>
          pages[date].map((page, index) => (
            <div className={clsx(styles.row)}>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: index === 0,
                })}
              >
                {index === 0 ? date : ''}
              </div>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: dateIndex !== 0 || index !== 0,
                })}
              >
                {page.name}
              </div>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: dateIndex !== 0 || index !== 0,
                })}
              >
                {page.orders}
              </div>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: dateIndex !== 0 || index !== 0,
                })}
              >
                {page.paid &&
                  page.paid.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
              </div>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: dateIndex !== 0 || index !== 0,
                })}
              >
                {page.msgQuantity}
              </div>
              <div
                className={clsx(styles.cell, {
                  [styles.upperline]: dateIndex !== 0 || index !== 0,
                })}
              >
                {page.paidPerMsg &&
                  page.paidPerMsg.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
              </div>
            </div>
          )),
        )}
      </div>
    </React.Fragment>
  );
}

export default MarketInfoDetailTable;
