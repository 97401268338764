import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  ConfirmModal,
  ExpenseModal,
  ExpenseTable,
  NotifyModal,
} from '@components';
import styles from './Expense.module.scss';
import * as event from './event';
import { BaseLayout } from '@/components/templates';
import { NewExpenseForm } from '../NewExpenseForm';
import { ExpenseSummary } from '../FilterContainer/ExpenseSummary';
import { FilterExpense } from '../FilterContainer/FilterExpense';
import { UpdateDateModal } from '../UpdateDateModal';

function Expense(props) {
  const {
    state: { user },
  } = props;
  const isAdmin = useMemo(() => !!user?.isAdmin, [user]);
  const [filterExpenseIDs, setFilterExpenseIDs] = useState([]);
  const [filterExpenses, setFilterExpenses] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    selectedExpense: 0,
  });

  const [hideAddModal, setHideAddModal] = useState(true);

  // First fetch
  const [expenses, setExpenses] = useState([]);
  const [expenseBills, setExpenseBills] = useState([]);
  const [noExpense, setNoExpense] = useState(0);

  // Input
  const [order, setOrder] = useState('ASC');

  const [notifyModal, setNotifyModal] = useState({
    type: '',
    title: 'Thêm',
    content: null,
  });
  const [notifyModalShow, setNotifyModalShow] = useState(false);

  const [confirmModal, setConfirmModal] = useState({
    actionTitle: '',
    actionConfirm: '',
    actionConduct: '',
  });
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const [page, setPage] = useState(1);
  let [stopScrollEvent, setStopScrollEvent] = useState(true);
  const expenseTableRef = useRef();

  const [newData, setNewData] = useState('');

  const [selectedExpenseBillID, setSelectedExpenseBillID] = useState(0);

  const totalExpense = useMemo(
    () => expenseBills.reduce((total, bill) => total + bill.price, 0),
    [expenseBills],
  );

  const handleAddNewExpense = useCallback(
    (formData) =>
      event.handleSubmitForm(
        formData,
        setExpenseBills,
        setNoExpense,
        setNotifyModalShow,
        setNotifyModal,
      ),
    [],
  );

  const handleAddNewExpenseType = useCallback(() => setHideAddModal(false), []);

  const handleChangeFilterCondition = useCallback((field, value) => {
    setFilterCondition((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleUpdateDateSubmit = useCallback(
    (updateDate) => {
      event.handleUpdateSubmit(
        selectedExpenseBillID,
        updateDate,
        setSelectedExpenseBillID,
        setExpenseBills,
        setNotifyModal,
        setNotifyModalShow,
      );
    },
    [selectedExpenseBillID],
  );

  const onCloseUpdateDateModal = useCallback(
    () => setSelectedExpenseBillID(0),
    [],
  );

  // Get expenses list
  useEffect(() => {
    event.getExpenses(setExpenses);
  }, []);

  useLayoutEffect(() => {
    const cbScroll = (e) => {
      const maxDistanceFromBot = e.target.offsetHeight;
      if (
        e.target.scrollHeight - e.target.scrollTop <= maxDistanceFromBot &&
        !stopScrollEvent
      ) {
        setStopScrollEvent(true);
        setPage((page) => page + 1);
      }
    };
    expenseTableRef.current?.addEventListener('scroll', cbScroll);

    return () => {
      expenseTableRef.current?.removeEventListener('scroll', cbScroll);
    };
  }, [stopScrollEvent, expenseTableRef]);

  useEffect(() => {
    event.getExpenseBills(
      isAdmin,
      page,
      filterCondition,
      order,
      setExpenseBills,
      setNoExpense,
      setStopScrollEvent,
      setNewData,
      setFilterExpenseIDs,
    );
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      event.getExpenseBills(
        isAdmin,
        page,
        filterCondition,
        order,
        setExpenseBills,
        setNoExpense,
        setStopScrollEvent,
        setNewData,
        setFilterExpenseIDs,
      );
    } else setPage(1);
  }, [filterCondition, order]);

  useEffect(() => {
    if (filterExpenseIDs.length > 0 && expenses.length > 0) {
      setFilterExpenses(
        expenses.filter((item) =>
          filterExpenseIDs.some((expense) => expense.expenseID === item.id),
        ),
      );
    }
  }, [filterExpenseIDs, expenses]);

  return (
    <React.Fragment>
      <BaseLayout>
        <div className={styles.containerHeader}>
          <NewExpenseForm
            expenses={expenses}
            handleAddNewExpense={handleAddNewExpense}
            handleAddNewExpenseType={handleAddNewExpenseType}
            isAllowAddNewExpenseType={isAdmin}
          />

          <div className={styles.filterContainer}>
            <ExpenseSummary
              currentCount={expenseBills.length}
              expenseCount={noExpense}
              totalExpense={totalExpense}
            />

            {isAdmin && (
              <FilterExpense
                filterCondition={filterCondition}
                expenses={filterExpenses}
                handleFilterConditionChanged={handleChangeFilterCondition}
                hasNewData={newData}
                handleRefreshClick={() =>
                  event.handleRefreshClick(setNewData, setPage)
                }
              />
            )}
          </div>
        </div>

        <ExpenseTable
          ref={expenseTableRef}
          isAdmin={isAdmin}
          expenseBills={expenseBills}
          getExpenseBills={() =>
            event.getExpenseBills(
              isAdmin,
              page,
              filterCondition,
              order,
              setExpenseBills,
              setNoExpense,
              setStopScrollEvent,
              setNewData,
              expenses,
              setFilterExpenseIDs,
            )
          }
          setPage={setPage}
          showConfirmModal={(modal) =>
            event.showConfirmModal(modal, setConfirmModal, setConfirmModalShow)
          }
          showNotifyModal={(modal) =>
            event.showNotifyModal(modal, setNotifyModal, setNotifyModalShow)
          }
          setExpenseBills={setExpenseBills}
          setNoExpense={setNoExpense}
          newData={newData}
          order={order}
          setOrder={setOrder}
          setSelectedExpenseBillID={setSelectedExpenseBillID}
        />
      </BaseLayout>

      <UpdateDateModal
        isShow={!!selectedExpenseBillID}
        handleUpdateSubmit={handleUpdateDateSubmit}
        onClose={onCloseUpdateDateModal}
      />

      <ExpenseModal
        expenses={expenses}
        getExpenses={() => event.getExpenses(setExpenses)}
        hide={hideAddModal}
        closeModal={() => setHideAddModal(true)}
        setNotifyModal={setNotifyModal}
        setNotifyModalShow={setNotifyModalShow}
      />

      <NotifyModal
        modal={notifyModal}
        show={notifyModalShow}
        closeModal={() => setNotifyModalShow(false)}
      />

      <ConfirmModal
        modal={confirmModal}
        show={confirmModalShow}
        closeModal={() => setConfirmModalShow(false)}
      />
    </React.Fragment>
  );
}

export default connect(({ state }) => ({ state }))(Expense);
