import { Input } from '@/components';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { memo, useState } from 'react';
import style from './style.module.scss';

const CreateKeywords = ({
  provinces,
  toastRef,
  createProvinceKeyword,
  deleteProvinceKeyword,
  canDeleteProvinceKeyword,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [error, setError] = useState();
  const [selectedProvinceId, setSelectedProvinceId] = useState('0');

  const provincesList = useMemo(
    () => [...provinces.list],
    [provinces],
  );
  const selectedProvince = useMemo(
    () =>
      selectedProvinceId !== '0'
        ? provincesList.find(({ id }) => id === parseInt(selectedProvinceId))
        : null,
    [selectedProvinceId, provincesList],
  );
  const onToggleModal = useCallback(
    (e) => {
      e.stopPropagation();
      setIsModalOpen(!isModalOpen);
    },
    [isModalOpen],
  );
  const stopPropagation = useCallback((e) => e.stopPropagation(), []);
  const onChange = useCallback(
    (e) => {
      if (error) setError('');
      setKeyword(e.target.value);
    },
    [error],
  );
  const onSelectedProvinceId = useCallback(
    (e) => {
      if (error) setError('');
      setSelectedProvinceId(e.target.value);
    },
    [error],
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!selectedProvinceId || selectedProvinceId === '0') {
        setError('Chọn tỉnh/thành');
        return;
      }
      if (!keyword) {
        setError('Nhập từ khóa');
        return;
      }
      const onSuccess = () => {
        setKeyword('');
        toastRef.current.show({
          severity: 'success',
          summary: 'Thành công',
          detail: 'Thêm từ khóa thành công',
          life: 3000,
        });
      }
      const onError = () =>
        toastRef.current.show({
          severity: 'error',
          summary: 'Lỗi',
          detail: 'Không thể thêm từ khóa',
          life: 3000,
        });

      createProvinceKeyword({
        data: {
          provinceId: parseInt(selectedProvinceId),
          keyword,
        },
        onSuccess,
        onError,
      });
    },
    [selectedProvinceId, keyword, error],
  );

  const onDelete = useCallback(
    (provinceId) => () => {
      const onSuccess = () =>
        toastRef.current.show({
          severity: 'success',
          summary: 'Thành công',
          detail: 'Xóa từ khóa thành công',
          life: 3000,
        });
      const onError = () =>
        toastRef.current.show({
          severity: 'error',
          summary: 'Lỗi',
          detail: 'Không thể xóa từ khóa',
          life: 3000,
        });

      deleteProvinceKeyword({
        data: {
          id: parseInt(provinceId),
        },
        onSuccess,
        onError,
      });
    },
    [selectedProvinceId, keyword, error],
  );

  useEffect(() => {
    const closeModal = () => setIsModalOpen(false);
    document.addEventListener('click', closeModal);

    return () => document.removeEventListener('click', closeModal);
  }, []);

  return (
    <div className={style.keywordContainer}>
      <button
        type="button"
        className={clsx(style.toggleButton, {
          [style.topRadiusOnly]: isModalOpen,
        })}
        onClick={onToggleModal}
      >
        Thêm từ khoá
        <i className="pi pi-caret-down" />
      </button>

      <form
        className={clsx(style.keywordModal, {
          [style.hidden]: !isModalOpen,
        })}
        onClick={stopPropagation}
        onSubmit={onSubmit}
      >
        <select value={selectedProvinceId} onChange={onSelectedProvinceId}>
          <option value="">Chọn tỉnh</option>
          {provincesList.map((province) => (
            <option key={province.id} value={province.id}>
              {province.name}
            </option>
          ))}
        </select>

        <div className={style.keywordInputContainer}>
          <Input
            onChange={onChange}
            value={keyword}
            label={{ id: 'keyword', name: 'Từ khóa' }}
            className={style.keywordInput}
          />

          <button className={clsx(style.addKeywordBtn)} type="submit">
            <i className="pi pi-plus" />
          </button>
        </div>

        <p>{error}</p>

        <div className={style.currentKeywordsContainer}>
          {selectedProvince &&
            selectedProvince.keywords.map(({ keyword, id }) => (
              <div key={keyword} className={style.currentKeyword}>
                <span>{keyword}</span>
                {canDeleteProvinceKeyword && (
                  <i className="pi pi-times" onClick={onDelete(id)} />
                )}
              </div>
            ))}
        </div>
      </form>
    </div>
  );
};

export default memo(CreateKeywords);
