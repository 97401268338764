import * as validate from './validate';
import * as constant from './constant';

const handleSetSelectedStatisticTime = (
    e,
    statisticReference,
    setSelectedStatisticTime,
    index,
    setHideTimes,
    setHideModal,
) => {
    e.stopPropagation();
    if (statisticReference === constant.MONTH) {
        setSelectedStatisticTime(index + 1);
    } else {
        switch (index) {
            case 0:
                setSelectedStatisticTime(new Date().getFullYear());
                break;
            case 1:
                setSelectedStatisticTime(new Date().getFullYear() - 1);
                break;
            case 2:
                setSelectedStatisticTime(new Date().getFullYear() - 2);
                break;
            case 3:
                setHideModal(false);
                break;
        }
    }
    setHideTimes(true);
};

const handleCustomYearChange = (e, setSelectedStatisticTime, setError) => {
    setError('');
    const value = e.target.value;
    if (validate.validateYearInput(value, setError)) {
        setSelectedStatisticTime(value);
    }
};

export { handleSetSelectedStatisticTime, handleCustomYearChange };
