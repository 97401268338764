import { importStatusService } from '@services';

class ImportStatusController {
  getAll = async (cancelSignal, setStatuses) => {
    try {
      const result = await importStatusService.getAll(cancelSignal);

      if (result.success) {
        setStatuses(result.data);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };
}

export default ImportStatusController;
