import { fetcher } from '../utilities';

const url = '/api/expense-bill';

const getAll = (page) => {
    let query = '?';
    if (page) {
        query += `page=${page}`;
    }
    return fetcher(`${url}/list${query}`);
};

const create = (expenseBill) => {
    return fetcher(`${url}/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(expenseBill),
    });
};

const remove = (id) => {
    return fetcher(`${url}/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id }),
    });
};

const filter = (page, startDate, endDate, order, expense) => {
    let query = `page=${page}&startDate=${startDate}&endDate=${endDate}`;
    if (expense > 0) query += `&expense=${expense}`;
    if (order) query += `&dateOrd=${order}`;
    return fetcher(`${url}/admin/list?${query}`);
};

const update = (id, date) => {
    return fetcher(`${url}/admin/update`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: id,
            date: date,
        }),
    });
};

const exportExcel = (startDate, endDate) =>
    fetcher(`${url}/admin/export?startDate=${startDate}&endDate=${endDate}`);

export { getAll, create, remove, filter, update, exportExcel };
