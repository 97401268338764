import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import DisplayUsers from '../../user/partials';
import NoteGroups from '../noteGroups';

class NoteGroupRule extends BaseModel {
  static properties = {
    id: null,
    name: '',
    specialOption: null,
    noteGroups: _p(NoteGroups, new NoteGroups()),
    users: _p(DisplayUsers, new DisplayUsers()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  specialOption: null,
  noteGroups: NoteGroup[];
  users: DisplayUser[];
  */
}

export default NoteGroupRule;
