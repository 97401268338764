import { shipServiceService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class ShipController {
  getAll = async (cancelSignal, setShipServices) => {
    try {
      const result = await shipServiceService.getAll(cancelSignal);

      if (!result.error) {
        setShipServices(result);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  update = async (
    shipService,
    setToastMessage,
    fetchShipServices,
    closeEditButton,
  ) => {
    try {
      const result = await shipServiceService.update(shipService);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật thành công',
          },
        });
        fetchShipServices();
        closeEditButton();
      } else {
        throw new Error();
      }
    } catch (err) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật',
        },
      });
    }
  };

  create = async (
    name,
    setToastMessage,
    fetchShipServices,
    closeEditButton,
  ) => {
    try {
      const result = await shipServiceService.create(name);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật thành công',
          },
        });
        fetchShipServices();
        closeEditButton();
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Không có thay đổi gì để cập nhật',
          },
        });
      }
    } catch (err) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật',
        },
      });
    }
  };
}

export default ShipController;
