import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '..';
import QtProduct from './qt-product';

class QtProducts extends BaseModel {
  static properties = {
    list: _p(QtProduct, new QtProduct()),
    count: null,
    totalPage: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: QtProduct[];
  count: number | null;
  */
}

export default QtProducts;
