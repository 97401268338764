import { memo } from 'react';

import styles from './style.module.scss';
import clsx from 'clsx';
import { IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';

/**
 *
 * @param {{
 *   hasNewData: boolean;
 *   handleRefreshClick: (e: React.MouseEvent<HTMLDivElement>) => void;
 * }} param0
 * @returns
 */
const RefreshButton = ({ hasNewData, handleRefreshClick }) => {
  return (
    <div
      className={clsx(styles.btnRefreshWrapper, {
        [styles.newData]: hasNewData,
      })}
      newdata={hasNewData}
    >
      <button
        className={clsx(styles.btn, {
          [styles.disabled]: !hasNewData,
        })}
        onClick={handleRefreshClick}
        disabled={hasNewData ? '' : 'disabled'}
      >
        Refresh
      </button>

      <IconButton
        onClick={handleRefreshClick}
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
        }}
        disabled={!hasNewData}
      >
        <Refresh
          sx={{
            fontSize: '2.4rem',
            color: hasNewData ? 'black' : '#ccc',
          }}
        />
      </IconButton>
    </div>
  );
};

export default memo(RefreshButton);
