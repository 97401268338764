import _ from 'lodash';
import * as constant from './constant';

const matchPassword = (user) =>
    (!user.newPassword && !user.rePassword) ||
    user.newPassword === user.rePassword
        ? ''
        : constant.PWD_NOT_MATCH;

const username = (user) => (user.name ? '' : constant.EMPTY_USERNAME);

const name = (user) => (user.name ? '' : constant.EMPTY_DISPLAYNAME);

const password = (user) => (user.password ? '' : constant.EMPTY_PWD);

const newPassword = (user) =>
    !user.newPassword || user.newPassword.length > 7
        ? ''
        : constant.SHORT_PWD_ERR;

const checkMatchPassword = (user, setError) => {
    const err = matchPassword(user);
    setError((error) => ({ ...error, rePassword: err }));
};

const checkUsername = (user, setError) => {
    const err = username(user);
    if (err) {
        setError((error) => ({ ...error, username: err }));
    }
};

const checkName = (user, setError) => {
    const err = name(user);
    if (err) {
        setError((error) => ({ ...error, name: err }));
    }
};

const checkPassword = (user, setError) => {
    const err = password(user);
    if (err) {
        setError((error) => ({ ...error, password: err }));
    }
};

const checkNewPassword = (user, setError) => {
    const err = newPassword(user);
    if (err) {
        setError((error) => ({ ...error, newPassword: err }));
    }
};

const checkForm = (user, setError) => {
    const newError = {
        username: username(user),
        password: password(user),
        newPassword: newPassword(user),
        rePassword: matchPassword(user),
        name: name(user),
    };

    setError(newError);

    return !_.values(newError).some(Boolean);
};

export {
    checkMatchPassword,
    checkUsername,
    checkName,
    checkPassword,
    checkNewPassword,
    checkForm,
};
