import React from 'react';
import { Outlet } from 'react-router-dom';
import { fetcher } from '../../utilities';

const checkSignedIn = async (children, navigate, setIsAuthenticated) => {
    let isSignedIn = false;

    try {
        const respond = await fetcher('/api/auth/sign-in');
        const data = await respond.json();

        if (data.message === 'Auth') {
            setIsAuthenticated(children);
        } else {
            navigate('sign-out');
        }
    } catch (err) {
        console.log(err);
    }

    return isSignedIn;
};

const isAdmin = async (userContext, setIsAdmin) => {
    if (
        userContext &&
        userContext.role &&
        userContext.role.name &&
        userContext.role.name.toLowerCase() === 'admin'
    ) {
        setIsAdmin(<Outlet />);
    }
};

const isManager = async (userContext, setIsManager) => {
    if (
        userContext &&
        userContext.role &&
        userContext.role.name &&
        ['admin', 'quản lý'].includes(userContext.role.name.toLowerCase())
    ) {
        setIsManager(<Outlet />);
    }
};

const isStaff = async (userContext, setIsStaff) => {
    if (
        userContext &&
        userContext.role &&
        userContext.role.name &&
        ['nhân viên', 'quản lý', 'admin'].includes(
            userContext.role.name.toLowerCase(),
        )
    ) {
        setIsStaff(<Outlet />);
    }
};

const isMarketter = async (userContext, setIsMarketter) => {
    if (
        userContext &&
        userContext.role &&
        userContext.role.name &&
        ['marketter', 'quản lý', 'admin'].includes(
            userContext.role.name.toLowerCase(),
        )
    ) {
        setIsMarketter(<Outlet />);
    }
};

export { checkSignedIn, isManager, isAdmin, isStaff, isMarketter };
