import { useLayoutEffect, useState } from 'react';
import { default as styles } from './Home.module.scss';
import * as event from './event';

function Home() {
    const [imgLink, setImgLink] = useState('');

    useLayoutEffect(() => {
        const cancelController = event.fetchBackground(setImgLink);

        return () => {
            cancelController.abort();
        };
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <img
                    loading="lazy"
                    className={styles.bodyTrangChu}
                    src={imgLink}
                    alt="Hình ảnh trang chủ"
                />
            </div>
        </div>
    );
}

export default Home;
