export const bill = {
    name: '',
    address: '',
    price: 0,
    paid: 0,
    ecommerceName: '',
    ecommerceID: 0,
    productID: '',
    imgURL: '',
    phones: [],
    note: '',
    product: {},
    shipService: {
        id: 0,
        name: '',
    },
};

export const error = {
    name: '',
    phone: '',
    address: '',
    ship: '',
    product: '',
    ecommerceName: '',
    ecommerceID: '',
    img: '',
    formSubmit: {
        msg: '',
        error: '',
    },
};
