import unitOfWork from '@controllers';
import * as utils from '@utilities';

const fetchMarketInfo = (date, selectedUser, setData) => {
  const run = (signal) =>
    unitOfWork.market.fetchByDate(signal, date, selectedUser, setData);

  return utils.makeCancellablePromise(run);
};

export default { fetchMarketInfo };
