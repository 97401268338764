const options = {
    responsive: true,
    scales: {
        y: {
            ticks: {
                callback: (value) =>
                    value.toLocaleString('vi-VN'),
            },
        },
    },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
        },
        datalabels: {
            formatter: function () {
                return '';
            },
        },
    },
};

export { options };
