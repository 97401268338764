import React, { memo } from 'react';
import clsx from 'clsx';

import styles from './MarketInfoRow.module.scss';

function MarketInfoRow({
  page,
  index,
  eventHandler,
  inputDisabled,
  hiddenColumns,
}) {
  const pricePerMessage = React.useMemo(() => {
    let value = 0;

    if (page.paid && page.msgQuantity) {
      value = (
        Math.floor((page.paid / page.msgQuantity) * 100) / 100
      ).toLocaleString('vi-VN', { styles: 'currency' });
    }

    return value;
  }, [page.msgQuantity, page.paid]);

  return (
    <React.Fragment>
      <div
        className={clsx(
          styles['row'],
          styles[`num${5 - Object.values(hiddenColumns).length}Columns`],
        )}
      >
        <div className={styles.cell}>{page.name}</div>
        {!hiddenColumns['orders'] && (
          <div className={clsx(styles.cell)}>{page.orders || 0}</div>
        )}
        {!hiddenColumns['paid'] && (
          <input
            className={clsx(styles.cell, styles.input, {
              [styles.disabled]: inputDisabled.value,
              [styles.shouldStyle]: inputDisabled.style,
            })}
            value={
              (page.paid &&
                page.paid.toLocaleString('vi-VN', {
                  styles: 'currency',
                })) ||
              0
            }
            onChange={(e) =>
              eventHandler.onInputChange('paid', index, e.target.value)
            }
            disabled={inputDisabled.value}
          />
        )}
        {!hiddenColumns['msgQuantity'] && (
          <input
            className={clsx(styles.cell, styles.input, {
              [styles.disabled]: inputDisabled.value,
              [styles.shouldStyle]: inputDisabled.style,
            })}
            value={page.msgQuantity || 0}
            onChange={(e) =>
              eventHandler.onInputChange('msgQuantity', index, e.target.value)
            }
            disabled={inputDisabled.value}
          />
        )}

        {!hiddenColumns['pricePerMessage'] && (
          <div className={clsx(styles.cell)}>{pricePerMessage}</div>
        )}
      </div>
    </React.Fragment>
  );
}

export default memo(MarketInfoRow);
