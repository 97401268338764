export const LABELS = {
  1: 'Thêm tỉnh/thành',
  2: 'Xóa tỉnh/thành',
  3: 'Nhập kho',
};

export default {
  INSERT_PROVINCE_KEYWORD: 1,
  DELETE_PROVINCE_KEYWORD: 2,
  IMPORT_QT_PRODUCT: 3,
};
