import React from 'react';
import clsx from 'clsx';

import styles from './EditEcommerce.module.scss';
import { ToastMessage } from '@components';
import toastMessageType from '@constant/ToastMessage';

import event from './event';

function EditOnRight({ selectedEcommerce, closeEditButton, fetchEcommerces }) {
  const [ecommerce, setEcommerce] = React.useState({
    name: '',
  });
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: {
      title: 'Lỗi',
      duration: 3000,
      desc: '',
    },
  });
  const handleInputChange = React.useCallback((e) =>
    setEcommerce({
      ...ecommerce,
      name: e.target.value,
    }),
  );
  const saveNewData = React.useCallback(
    (e) => {
      e.preventDefault();

      if (!ecommerce.name) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: 'Tên không được để trống',
          },
        });
      } else {
        event.submitForm(
          ecommerce,
          setToastMessage,
          fetchEcommerces,
          closeEditButton,
        );
      }
    },
    [ecommerce],
  );
  const canSubmit = React.useMemo(() => {
    const trimName = ecommerce.name.trim();

    return !trimName || trimName === selectedEcommerce.name || trimName === '';
  }, [ecommerce.name, selectedEcommerce.name]);

  React.useLayoutEffect(() => {
    setEcommerce(selectedEcommerce);
  }, [selectedEcommerce]);

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <form
      className={clsx(styles.form)}
      onSubmit={saveNewData}
      aftercontent={ecommerce.id ? 'Chỉnh sửa' : 'Tạo mới'}
    >
      <input
        className={clsx(styles.formInput)}
        value={ecommerce.name}
        onChange={handleInputChange}
        placeholder="Nhập tên trang thương mại điện tử..."
      />

      <div className={clsx(styles.formAction)}>
        <button
          type="button"
          className={clsx(
            styles.formActionItem,
            styles.formActionItem_LowPriority,
          )}
          onClick={closeEditButton}
        >
          Hủy bỏ
        </button>

        <input
          className={clsx(styles.formActionItem, {
            [styles.disabled]: canSubmit,
          })}
          type="submit"
          value="Lưu"
          disabled={canSubmit}
        />
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </form>
  );
}

export default EditOnRight;
