import clsx from 'clsx';
import styles from './ConfirmDialog.module.scss';

function ConfirmDialog({ question, isOpen, isClosed, onAccepted, onRefused }) {
  return (
    <div
      className={clsx(styles.container, {
        [styles.open]: isOpen && !isClosed,
        [styles.close]: isClosed && !isOpen,
      })}
    >
      <span className={styles.content}>{question}</span>
      <i
        className={clsx('fa-solid fa-check', styles.icon, styles.iconAccept)}
        onClick={onAccepted}
      />
      <i
        className={clsx('fa-solid fa-xmark', styles.icon, styles.iconRefuse)}
        onClick={onRefused}
      />
    </div>
  );
}

export default ConfirmDialog;
