import { fetcher } from '../utilities';
import * as utils from '@utilities';

const url = '/api/market-info';

const fetchByDate = async (cancelSignal, selectedUser, date) => {
  let query = '';
  if (selectedUser) {
    query += `?marketterID=${selectedUser.id}`;
  }

  const result = await fetcher(`${url}/${date}${query}`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

const fetchByCustomDate = async (cancelSignal, month, year) => {
  const result = await fetcher(
    `${url}/report-market?month=${month}&year=${year}`,
    {
      signal: cancelSignal,
    },
  );
  const data = await result.json();

  return data;
};

const updatePages = async (cancelSignal, date, selectedUser, pages) => {
  let query = '';
  if (selectedUser) {
    query += `?marketterID=${selectedUser.id}`;
  }

  const result = await fetcher(`${url}/put${query}`, {
    signal: cancelSignal,
    body: JSON.stringify({
      createdAt: parseInt(date),
      details: pages.map((page) => ({
        ecommerceID: page.ecommerceID,
        msgQuantity: page.msgQuantity || 0,
        paid: page.paid || 0,
      })),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });
  const data = await result.json();

  return data;
};

const exportReport = async (cancelSignal, date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const result = await fetcher(`${url}/report?month=${month}&year=${year}`, {
    signal: cancelSignal,
  });
  const file = await result.blob();
  const fileName = utils.getFileNameFromHeader(result);

  return { file, fileName };
};

const reportDetail = async (cancelSignal, date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const result = await fetcher(
    `${url}/report-detail?month=${month}&year=${year}`,
    {
      signal: cancelSignal,
    },
  );
  const data = await result.json();

  return data;
};

export {
  fetchByDate,
  updatePages,
  exportReport,
  fetchByCustomDate,
  reportDetail,
};
