import { connect } from 'react-redux';

import actions from '@/application/actions';
import QtModal from '../QtModal';


import styles from './ExportOrderDetail.module.scss';
import { KeyValues, Table } from '@/components/organisms';
import Button from '@/components/Button';

const tableOptions = [
  {
    field: 'id',
    name: 'Mã sản phẩm',
    isPC: true,
  },
  {
    field: 'product.name',
    name: 'Tên sản phẩm',
  },
  {
    field: 'count',
    name: 'Số lượng',
  },
  {
    field: 'price.vnd',
    name: 'Đơn giá',
    isPC: true,
  },
  {
    field: 'totalPrice.vnd',
    name: 'Tổng giá',
  },
];

const orderInfoFields = [
  {
    field: 'id',
    title: 'Mã đơn',
  },
  {
    field: 'displayStatus',
    title: 'Tình Trạng',
    valueClassName: styles.orderInfoText,
  },
  {
    field: 'createdAt.localDate',
    title: 'Ngày tạo',
  },
  {
    field: 'totalPrice.vnd',
    title: 'Tổng giá tiền',
  },
  {
    field: 'displayPriceType',
    title: 'Mệnh giá',
    valueClassName: styles.orderInfoText,
  },
];

const ExportOrderDetailModal = (props) => {
  const {
    actions: {
      toggleExportOrderDetailModal,
      cancelExportOrder,
      confirmDanger,
    },
    state: {
      user,
      ui: { isExportOrderDetailModalOpen },
      stock: { exportOrder },
    },
  } = props;

  const onClose = () => toggleExportOrderDetailModal({ isOpen: false });
  const onCancel = () => () =>
    confirmDanger({
      message: <p>Đơn số #{exportOrder?.id} sẽ bị HỦY! Bạn chắc chắn chứ?</p>,
      onAccept: cancelExportOrder,
    });
  const canEdit = exportOrder.canEdit(user) && !exportOrder.isCancel;

  return (
    <QtModal
      contentContainer={styles.container}
      isOpen={isExportOrderDetailModalOpen}
      onClose={onClose}
    >
      <h3 className={styles.header}>Đơn số #{exportOrder.id}</h3>

      <div className={styles.order}>
        <div className={styles.orderInfo}>
          <KeyValues fields={orderInfoFields} data={exportOrder} />
          {canEdit && (
            <div className={styles.btn}>
              <Button
                className={styles.btnChangeStatus}
                content="Hủy đơn hàng"
                onClick={onCancel()}
                role="subMain"
              />
            </div>
          )}
        </div>

        <Table
          containerClassName={styles.orderTable}
          headers={tableOptions}
          data={exportOrder.details.list}
        />
      </div>
    </QtModal>
  );
};

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(ExportOrderDetailModal);
