import unitOfWork from '@controllers';

const submitForm = (product, setToastMessage, setData, closeEditButton) => {
    if (product.id) {
        unitOfWork.product.update(
            product,
            setToastMessage,
            setData,
            closeEditButton,
        );
    } else {
        unitOfWork.product.create(
            product.name,
            setToastMessage,
            setData,
            closeEditButton,
        );
    }
};

export default { submitForm };
