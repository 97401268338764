import {
  EXPORT_ORDER_STATUSES,
  EXPORT_ORDER_STATUS_NAMES,
  PRICE_TYPE_NAMES,
} from '@/config/constants';
import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import QtPrice from '@/domain/models/value-object/qtPrice';
import User from '@/domain/models/entities/user';
import QtDate from '@/domain/models/value-object/qtDate';
import ExportOrderDetails from './export-order-details';

class ExportOrder extends BaseModel {
  static properties = {
    id: null,
    createdAt: _pc(QtDate, null),
    priceTypeId: null,
    status: null,
    totalPrice: _pc(QtPrice, null),
    picId: null,
    details: _p(ExportOrderDetails, new ExportOrderDetails()),
    pic: _p(User, null),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  */

  get displayStatus() {
    return EXPORT_ORDER_STATUS_NAMES[this.status] || '';
  }

  get displayPriceType() {
    return PRICE_TYPE_NAMES[this.priceTypeId] || '';
  }

  get isComplete() {
    return this.status === EXPORT_ORDER_STATUSES.COMPLETE;
  }

  get isCancel() {
    return this.status === EXPORT_ORDER_STATUSES.CANCEL;
  }

  canEdit(user /* :User */) {
    return user.isManager || (user.id === this.picId && this.createdAt.isToday);
  }
}

export default ExportOrder;
