import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../../..';
import PICReport from './item';

class PICsReport extends BaseModel {
  static properties = {
    list: _p(PICReport, new PICReport()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default PICsReport;
