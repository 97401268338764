import facade from '@/domain/models/utils/facade';

export * as asyncActions from './async/order';

export const actions = {
  updateOrderFilter: (state, { payload }) => {
    const { filter } = state.order;
    const newFilter = facade.update(filter, payload);

    state.order = facade.update(state.order, { filter: newFilter });
  },
};
