import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './style.module.scss';
import NoteFolder from './NoteFolder';
import { Div } from '@/components/atoms';
import { connect } from 'react-redux';
import actions from '@/application/actions';

function Note(props) {
  const {
    state: {
      user: { noteGroups },
    },
    actions: { getNoteGroups, createNoteGroup },
  } = props;

  const [newNoteFolder, setNewNoteFolder] = useState(false);
  const [newTitle, setNewTitle] = useState('');

  const inputRef = useRef(null);

  const stopInput = useCallback(() => {
    setNewTitle('');
    setNewNoteFolder(false);
  }, []);

  // Get Note Groups
  useEffect(() => {
    getNoteGroups();
  }, []);

  // Effect for closing the new title input
  useEffect(() => {
    const cb = stopInput;
    document.addEventListener('click', cb);
    return () => document.removeEventListener('click', cb);
  }, []);

  useEffect(() => {
    newNoteFolder && inputRef.current.focus();
  }, [newNoteFolder]);

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.noteFolders}>
          {noteGroups.list.map((noteGroup, index) => (
            <NoteFolder
              noteGroupInit={noteGroup}
              key={`note_folder_${noteGroup.id}`}
              index={index}
              actions={props.actions}
            />
          ))}

          <Div
            className={clsx(styles.newNoteFolder, {
              [styles.inInputting]: newNoteFolder,
            })}
            stopPropagation
          >
            {!newNoteFolder && (
              <div
                className={styles.newNoteFolderTrigger}
                onClick={() => {
                  setNewNoteFolder(true);
                }}
              >
                <i className="fa-solid fa-add" />
                Thêm danh sách khác
              </div>
            )}

            {newNoteFolder && (
              <React.Fragment className={styles.newNoteFolderTrigger}>
                <input
                  type="text"
                  className={styles.inputNewNoteFolder}
                  value={newTitle}
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === 'Enter' && createNoteGroup({ name: newTitle })
                  }
                  placeholder="Nhập tiêu đề danh sách..."
                  ref={inputRef}
                />

                <div className={styles.btnBar}>
                  <button
                    className={styles.addBtn}
                    onClick={() => createNoteGroup({ name: newTitle })}
                  >
                    Thêm danh sách
                  </button>

                  <i
                    className={clsx('fas fa-xmark', styles.removeIcon)}
                    onClick={stopInput}
                  />
                </div>
              </React.Fragment>
            )}
          </Div>
        </ul>
      </div>

      <div style={{ height: '10px' }}></div>
    </>
  );
}

const mapStateToProps = ({ state }) => ({ state });
const mapDispatchToProps = actions;

export default connect(mapStateToProps, mapDispatchToProps)(memo(Note));
