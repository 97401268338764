const endpoint = {
  loginUseToken: '/v1/auth/login',
  login: '/v1/auth/login',
  users: '/v1/users',
  ecommerces: '/v1/ecommerces',
  shipServices: '/v1/ship-services',
  items: '/v1/items',
  item: '/v1/item',
  categories: '/v1/categories',
  note: '/v1/note',
  noteGroup: '/v1/note/group',
  noteRule: '/v1/note/rule',
  order: '/v1/order',
  orderDetail: '/v1/order/:id',
  provinces: '/v1/provinces',
  provinceKeyword: '/v1/province/keyword',
  productDetail: '/v1/product/:id',
  qtProducts: '/v1/qt-products',
  qtProductsDetail: '/v1/qt-products/:id',
  exportQtProducts: '/v1/qt-products/export',
  exportQtProductDetail: '/v1/qt-products/export/:id',
  cancelExportOrder: '/v1/qt-products/export/cancel',
  importQtProducts: '/v1/qt-products/import',
  importQtProductDetail: '/v1/qt-products/import/:id',
  reportQtProduct: '/v1/qt-products/report',
  cancelImportOrder: '/v1/qt-products/import/cancel',
  completeImportOrder: '/v1/qt-products/import/complete',
  suppliers: '/v1/suppliers',
  supplierDetail: '/v1/suppliers/:id',
};

export { endpoint };
