/**
 * Author: Nam Dinh
 * Created At: Wed May 04 2022
 * File name: Constant.js
 */

export default {
    SUCCESS: 'success',
    ERROR: 'error',
};
