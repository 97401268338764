import slugify from 'slugify';
import { normalizeUnicode } from '@/utilities';

export const formatProvinces = (provinces) =>
  provinces.map((province) => {
    const keywords = [
      ...province.keywords.map(({ keyword }) => keyword),
      province.name,
    ];

    return {
      ...province,
      slugs: keywords.map((keyword) =>
        slugify(keyword, { locale: 'vi', lower: true }),
      ),
      normalizedName: normalizeUnicode(province.name),
    };
  });
