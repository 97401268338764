import { endpoint } from '@/config/endpoint';
import facade from '@/domain/models/utils/facade';
import { request } from '@/utilities/request';

const getNoteGroups = async (user, query = {}) => {
  const option = {
    endpoint: endpoint.noteGroup,
    query,
    user,
  };
  const noteGroups = await request.get(option);

  return facade.update(user, { noteGroups });
};

const createNoteGroup = async (data, user) => {
  const option = {
    endpoint: endpoint.noteGroup,
    data,
    user,
  };
  await request.post(option);
};

const updateNoteGroup = async (data, user) => {
  const option = {
    endpoint: endpoint.noteGroup,
    data,
    user,
  };
  await request.put(option);
};

const deleteNoteGroup = async (data, user) => {
  const option = {
    endpoint: endpoint.noteGroup,
    data,
    user,
  };
  await request.del(option);
};

const getNotes = async (user, query = {}) => {
  const option = {
    endpoint: endpoint.note,
    query,
    user,
  };
  const noteGroups = await request.get(option);

  return facade.update(user, { noteGroups });
};

const createNote = async (data, user) => {
  const option = {
    endpoint: endpoint.note,
    data,
    user,
  };
  await request.post(option);
};

const updateNote = async (data, user) => {
  const option = {
    endpoint: endpoint.note,
    data,
    user,
  };
  await request.put(option);
};

const deleteNote = async (data, user) => {
  const option = {
    endpoint: endpoint.note,
    data,
    user,
  };
  await request.del(option);
};

export default {
  getNoteGroups,
  createNoteGroup,
  updateNoteGroup,
  deleteNoteGroup,
  getNotes,
  createNote,
  updateNote,
  deleteNote,
};
