import BaseModel from '../..';

class Note extends BaseModel {
  static properties = {
    id: null,
    note: '',
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  note: string;
  */
}

export default Note;
