import { endpoint } from '@/config/endpoint';
import User from '@/domain/models/entities/user';
import facade from '@/domain/models/utils/facade';
import { request } from '@/utilities/request';
import { formatUser } from '../user/format';

const login = async ({ username, password }) => {
  const options = {
    endpoint: endpoint.login,
    data: {
      username,
      password,
    },
  };
  const data = await request.post(options);

  return facade.create(User, formatUser(data));
};

const initializeFromToken = async (token) => {
  if (!token) {
    return facade.create(User, {});
  }
  const options = {
    endpoint: endpoint.loginUseToken,
    user: {
      token,
    },
  };
  const data = await request.get(options);
  const user = formatUser(data);

  return facade.create(User, {
    ...user,
    token,
  });
};

export default { initializeFromToken, login };
