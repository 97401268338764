import clsx from 'clsx';
import { useState } from 'react';
import { ExpenseListTable } from '..';
import * as event from './event';
import styles from './ExpenseModal.module.scss';

function ExpenseModal({
  expenses,
  getExpenses,
  hide,
  closeModal,
  setNotifyModal,
  setNotifyModalShow,
}) {
  const [expense, setExpense] = useState({
    id: 0,
    name: '',
  });
  const [error, setError] = useState('');

  return (
    <div
      className={clsx(styles.modalWrapper, {
        [styles.hide]: hide,
      })}
    >
      <div className={styles.overlay} onClick={closeModal}></div>

      <div className={styles.modal}>
        <i
          className={clsx(styles.closeIcon, 'fa-solid fa-xmark')}
          onClick={closeModal}
        ></i>

        <form
          className={styles.form}
          onClick={(e) => e.stopPropagation()}
          onSubmit={(e) =>
            event.handleSubmitForm(
              e,
              expense,
              setExpense,
              getExpenses,
              setNotifyModal,
              setNotifyModalShow,
              setError,
            )
          }
        >
          <div
            className={clsx(styles.formWrapper, {
              [styles.error]: error,
            })}
            error={error}
          >
            <label htmlFor="newExpenseName" className={styles.formLabel}>
              Khoản chi
            </label>
            <input
              type="text"
              id="newExpenseName"
              className={styles.formInput}
              value={expense.name}
              onChange={(e) =>
                event.handleExpenseChange(e, setExpense, setError)
              }
            />
          </div>

          <input
            className={styles.submitBtn}
            type="submit"
            value={expense.id === 0 ? 'Thêm' : 'Sửa'}
          />
        </form>

        <ExpenseListTable
          expenses={expenses}
          getExpenses={getExpenses}
          setNotifyModal={setNotifyModal}
          setNotifyModalShow={setNotifyModalShow}
          newExpense={expense}
          setNewExpense={setExpense}
        />
      </div>
    </div>
  );
}

export default ExpenseModal;
