import clsx from 'clsx';
import { memo } from 'react';

import styles from './style.module.scss';
import { SEARCH_TYPES } from '../../v1/ListBill';

/**
 *
 * @param {{
 *   searchTarget: { searchText: string; searchType: number };
 *   handleSearch: () => void;
 *   handleSearchTargetChanged: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
 * }} param0
 * @returns
 */
const SearchBar = ({
  searchTarget,
  handleSearch: _handleSearch,
  handleSearchTargetChanged,
}) => {
  const handleKeyupSearch = (e) => {
    if (e.key !== 'Enter') return;
    if (searchTarget.searchText) {
      _handleSearch();
    }
  };

  return (
    <div className={styles.searchWrapper}>
      <input
        type="text"
        name="searchText"
        className={clsx(styles.searchBar)}
        placeholder="Nhập nội dung tìm kiếm"
        value={searchTarget.searchText}
        onChange={handleSearchTargetChanged}
        onKeyUp={handleKeyupSearch}
      />
      <div className={styles.searchIcon} onClick={_handleSearch}>
        <i className="fas fa-search" />
      </div>
      <select
        className={styles.searchTypes}
        name="searchType"
        value={searchTarget.searchType}
        onChange={handleSearchTargetChanged}
      >
        {SEARCH_TYPES.map((type, index) => (
          <option value={index} key={index}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(SearchBar);
