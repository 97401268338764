import facade from '@/domain/models/utils/facade';
import { orderService, productService } from '@/domain/services';

const getOrderById = {
  onDispatch: async ({ id }, { getState }) => {
    const {
      state: { user },
    } = getState();

    const order = await orderService.getOrderById({
      user,
      id,
    });

    return { order };
  },
  fulfilled: (state, { payload }) => {
    state.domain = facade.update(state.domain, payload);
  },
};

const deleteProduct = {
  onDispatch: async ({ id, onSuccess }, { getState }) => {
    const {
      state: { user },
    } = getState();

    await productService.deleteProduct({
      user,
      id,
    });

    await onSuccess?.();
  },
  fulfilled: () => {},
};

export { getOrderById, deleteProduct };
