import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import ExportOrderReport from './export-order-report';
import ImportOrderReport from './import-order-report';

class Report extends BaseModel {
  static properties = {
    importOrders: _p(ImportOrderReport, new ImportOrderReport()),
    exportOrders: _p(ExportOrderReport, new ExportOrderReport()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default Report;

