import React, { useEffect, useRef, useState, memo, useMemo } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { Tooltip } from 'primereact/tooltip';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import unitOfWork from '@controllers';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import styles from './ListAccount.module.scss';
import { Calendar } from 'primereact/calendar';
import { connect } from 'react-redux';
import actions from '@/application/actions';
import _ from 'lodash';

function ListAccount(props) {
  const {
    state: {
      masterData: { noteGroupRules },
    },
  } = props;
  const toast = useRef(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [ecommerces, setEcommerces] = useState([]);
  const [selectedEcommerces, setSelectedEcommerces] = useState([]);

  const noteGroupRuleByIds = useMemo(
    () => _.keyBy(noteGroupRules.list, 'id'),
    [noteGroupRules.list],
  );

  const formikPwd = useFormik({
    initialValues: {
      password: '',
      repassword: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.password) {
        errors.password = 'Mật khẩu là bắt buộc.';
      }

      if (!data.repassword) {
        errors.repassword = 'Hãy nhập lại mật khẩu.';
      } else if (data.password !== data.repassword) {
        errors.repassword = 'Mật khẩu không khớp.';
      }

      return errors;
    },
    onSubmit: (data) => {
      unitOfWork.user.resetPassword(
        selectedUser.id,
        data.password,
        toast.current,
      );

      formikPwd.resetForm();
    },
  });

  const isFormPwdFieldValid = (name) =>
    !!(formikPwd.touched[name] && formikPwd.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormPwdFieldValid(name) && (
        <small className="p-error">{formikPwd.errors[name]}</small>
      )
    );
  };
  const onEcommerceChanged = (e) => {
    let newSelectedEcommerces = [...selectedEcommerces];

    if (e.checked) newSelectedEcommerces.push(e.value);
    else
      newSelectedEcommerces.splice(newSelectedEcommerces.indexOf(e.value), 1);

    setSelectedEcommerces(newSelectedEcommerces);
  };

  const handleEcommerceUpdate = React.useCallback(
    (e) => {
      e.preventDefault();
      unitOfWork.ecommerce.updateMarketting(
        selectedUser.id,
        selectedEcommerces,
        toast.current,
      );
    },
    [selectedEcommerces, selectedUser],
  );

  const formikInfo = useFormik({
    initialValues: {
      startAt: new Date(),
      noteGroupRuleId: null,
    },
    validate: (data) => {
      let errors = {};

      if (!data.startAt) {
        errors.startAt = 'Ngày bắt đầu là bắt buộc.';
      }

      return errors;
    },
    onSubmit: (data) => {
      unitOfWork.user.updateInfo(selectedUser.id, data, toast.current);
      unitOfWork.user.getAll(null, setUsers, toast.value);
    },
  });
  const isFormInfoFieldValid = (name) =>
    !!(formikPwd.touched[name] && formikPwd.errors[name]);
  const getFormInfoErrorMessage = (name) => {
    return (
      isFormInfoFieldValid(name) && (
        <small className="p-error">{formikPwd.errors[name]}</small>
      )
    );
  };

  const selectUser = React.useCallback((user) => () => {
    setSelectedUser(user);
    if (user) {
      formikInfo.setFormikState((prevState) => ({
        ...prevState,
        initialValues: {
          startAt: user.startAt ? new Date(user.startAt) : new Date(),
          noteGroupRuleId: user.noteGroupRuleId,
        },
        values: {
          startAt: user.startAt ? new Date(user.startAt) : new Date(),
          noteGroupRuleId: user.noteGroupRuleId,
        },
      }));
    }
  });

  const banUser = React.useCallback(
    (id) => () => {
      unitOfWork.user.ban(id, setUsers, toast.current);
    },
    [toast],
  );

  const unbanUser = React.useCallback(
    (id) => () => {
      unitOfWork.user.unban(id, setUsers, toast.current);
    },
    [toast],
  );

  const actionColumn = (rowData) => {
    return (
      <div className="items-center justify-center flex gap-[4px]">
        <i
          className="pi pi-user-edit text-2xl cursor-pointer"
          onClick={selectUser(rowData)}
        ></i>

        <Tooltip target=".lock-user" />
        {!rowData.deletedAt ? (
          <i
            className="lock-user pi text-2xl cursor-pointer pi-ban text-red-400"
            onClick={banUser(rowData.id)}
            data-pr-tooltip="Ban"
          ></i>
        ) : (
          <i
            className="lock-user pi text-2xl cursor-pointer pi-unlock text-green-400"
            onClick={unbanUser(rowData.id)}
            data-pr-tooltip="Unban"
          ></i>
        )}
      </div>
    );
  };
  const statusItemTemplate = (option) => {
    return (
      <span
        className={classNames(
          'px-[8px] py-[4px] uppercase rounded-[2px] font-medium text-[14px]',
          {
            'text-[#c63737] bg-[#ffcdd2]': option,
            'text-[#256029] bg-[#c8e6c9]': !option,
          },
        )}
      >
        {option ? 'Chặn' : 'Hoạt động'}
      </span>
    );
  };
  const statusColumn = (rowData) => {
    return statusItemTemplate(rowData.isBan);
  };

  const paginatorTemplate = {
    layout:
      'CurrentPageReport  FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ',
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
            position: 'absolute',
            left: 0,
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  useEffect(() => {
    const cancelController = new AbortController();

    unitOfWork.user.getAll(cancelController.signal, setUsers, toast.value);

    return () => {
      cancelController.abort();
    };
  }, []);

  useEffect(() => {
    if (selectedUser && selectedUser.role.type === 'marketter') {
      const cancelController = new AbortController();

      unitOfWork.ecommerce.getMarkettingPage(
        cancelController.signal,
        setEcommerces,
        selectedUser.id,
      );

      return () => {
        cancelController.abort();
      };
    }
  }, [selectedUser]);

  useEffect(() => {
    if (ecommerces.length > 0 && selectedUser) {
      setSelectedEcommerces(
        ecommerces
          .filter((ecommerce) => ecommerce.markettingBy === selectedUser.id)
          .map((ecommerce) => ecommerce.id),
      );
    }
  }, [ecommerces, selectedUser]);

  return (
    <div className="container py-4 h-full">
      <h3 className="px-3 text-3xl text-600 uppercase mb-6">
        Danh sách tài khoản
      </h3>

      <div
        className={classNames('fadeinright', {
          hidden: !selectedUser,
        })}
      >
        <h3
          className="text-2xl flex gap-3 align-items-center cursor-pointer"
          onClick={selectUser(null)}
        >
          <i className="pi pi-angle-left" />
          <span>
            <span className="font-normal mr-1 inline-block">
              {selectedUser?.username} - {selectedUser?.displayName}
            </span>
            ({selectedUser?.role.name})
          </span>
        </h3>

        <div>
          <div className="flex gap-[36px]">
            <form
              onSubmit={formikPwd.handleSubmit}
              className="mt-32 text-right flex-1"
            >
              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    value={formikPwd.values.password}
                    onChange={formikPwd.handleChange}
                    toggleMask
                    inputClassName="w-full"
                    className={classNames('w-full', {
                      'p-invalid': isFormPwdFieldValid('password'),
                    })}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      'p-error': isFormPwdFieldValid('password'),
                    })}
                  >
                    Mật khẩu
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('password')}
              </div>

              <div className="field mt-32">
                <span className="p-float-label">
                  <Password
                    id="repassword"
                    name="repassword"
                    value={formikPwd.values.repassword}
                    onChange={formikPwd.handleChange}
                    toggleMask
                    inputClassName="w-full"
                    className={classNames('w-full', {
                      'p-invalid': isFormPwdFieldValid('repassword'),
                    })}
                    feedback={false}
                  />
                  <label
                    htmlFor="repassword"
                    className={classNames({
                      'p-error': isFormPwdFieldValid('repassword'),
                    })}
                  >
                    Nhập lại mật khẩu
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('repassword')}
              </div>

              <Button
                type="submit"
                label="Đổi mật khẩu"
                className="mt-4 w-auto"
              />
            </form>

            <form
              onSubmit={formikInfo.handleSubmit}
              className="mt-32 text-right flex-1 flex flex-col justify-between items-end"
            >
              <div className="field w-full">
                <span className="p-float-label">
                  <Calendar
                    id="startAt"
                    value={formikInfo.values.startAt}
                    onChange={(e) =>
                      formikInfo.setFieldValue('startAt', e.value)
                    }
                    className={classNames('w-full', {
                      'p-invalid': isFormInfoFieldValid('startAt'),
                    })}
                    dateFormat="dd/mm/yy"
                    showIcon
                  />
                  <label
                    htmlFor="startAt"
                    className={classNames({
                      'p-error': isFormInfoFieldValid('startAt'),
                    })}
                  >
                    Ngày bắt đầu làm việc
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormInfoErrorMessage('startAt')}
              </div>

              <div className={classNames('field w-full', styles.group)}>
                <h3 className={classNames(styles.inputHeader)}>
                  Chọn nhóm ghi chú
                </h3>
                {noteGroupRules.list.map(({ name, id }) => (
                  <div
                    className={classNames(
                      'field-radiobutton',
                      styles.inputGroup,
                    )}
                    key={`note_group_rule_${id}`}
                  >
                    <RadioButton
                      className={styles.radioBtn}
                      id={`note-group-rule-${id}`}
                      name="noteGroupRuleId"
                      type="radio"
                      onChange={formikInfo.handleChange}
                      value={id}
                      checked={formikInfo.values.noteGroupRuleId === id}
                    />
                    <label htmlFor={`note-group-rule-${id}`}>{name}</label>
                  </div>
                ))}
              </div>

              <Button
                type="submit"
                label="Đổi thông tin"
                className="mt-4 w-fit"
              />
            </form>
          </div>

          {selectedUser?.role?.type === 'marketter' &&
            !selectedUser?.deletedAt && (
              <form onSubmit={handleEcommerceUpdate}>
                <h4 className="text-2xl text-600 mb-4 uppercase">
                  Chọn trang marketting
                </h4>

                <div className={styles.ecommerces}>
                  {ecommerces.map((ecommerce) => (
                    <div
                      className="mb-4 flex align-items-center text-2xl"
                      key={ecommerce.id}
                    >
                      <Checkbox
                        value={ecommerce.id}
                        checked={selectedEcommerces.includes(ecommerce.id)}
                        onChange={onEcommerceChanged}
                      ></Checkbox>
                      <label htmlFor="cb1" className="ml-2 text-900">
                        {ecommerce.name}
                      </label>
                    </div>
                  ))}
                </div>

                <Button
                  type="submit"
                  label="Cập nhật trang TMĐT"
                  className="mt-4 w-auto"
                />
              </form>
            )}
        </div>
      </div>

      <DataTable
        value={users}
        paginator
        sortField="isBan"
        sortOrder={1}
        paginatorTemplate={paginatorTemplate}
        rows={12}
        paginatorClassName="relative"
        className={classNames('fadeinleft max-h-[770px] overflow-auto', {
          hidden: selectedUser,
        })}
      >
        <Column
          alignHeader="left"
          className="text-center"
          field="username"
          header="Tên đăng nhập"
        ></Column>
        <Column
          alignHeader="left"
          className="text-center"
          field="displayName"
          header="Tên hiển thị"
        ></Column>
        <Column
          alignHeader="left"
          className="text-center"
          field="email"
          header="Email"
        ></Column>
        <Column
          alignHeader="left"
          className="text-center"
          field="isBan"
          body={statusColumn}
          header="Tình trạng"
        ></Column>
        <Column
          alignHeader="left"
          className="text-center"
          field="role.name"
          header="Vị trí"
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          header="Nhóm ghi chú"
          body={({ noteGroupRuleId }) => (
            <p className="text-center">
              {(noteGroupRuleId && noteGroupRuleByIds[noteGroupRuleId]?.name) ||
                ''}
            </p>
          )}
        ></Column>
        <Column
          alignHeader="center"
          className="text-center"
          header="Hành động"
          body={actionColumn}
        ></Column>
      </DataTable>

      <Toast ref={toast}></Toast>
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(memo(ListAccount));
