import clsx from 'clsx';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import * as event from './event';
import styles from './ExpenseTable.module.scss';

function ExpenseTable(
  {
    isAdmin,
    expenseBills,
    setExpenseBills,
    showConfirmModal,
    showNotifyModal,
    setNoExpense,
    setTotalExpense,
    order,
    setOrder,
    setSelectedExpenseBillID,
  },
  ref,
) {
  const tableBodyRef = useRef();

  const addEventListener = (event, cb) =>
    tableBodyRef.current.addEventListener(event, cb);
  const removeEventListener = (event, cb) =>
    tableBodyRef.current.removeEventListener(event, cb);

  useImperativeHandle(
    ref,
    () => ({
      addEventListener,
      removeEventListener,
    }),
    [tableBodyRef],
  );

  return (
    <React.Fragment>
      <header
        className={clsx(styles.header, {
          [styles.full]: isAdmin,
        })}
      >
        <div className={styles.heading}>STT</div>
        <div
          className={clsx(styles.heading, 'clickable')}
          onClick={() => event.handleSortClick(setOrder)}
        >
          Ngày chi
          <i
            className={clsx(styles.sortIcon, 'fa-solid', {
              'fa-sort-up': order === 'ASC',
              'fa-sort-down': order === 'DESC',
              'fa-sort': order === '',
            })}
          />
        </div>
        <div className={styles.heading}>Người chi</div>
        <div className={styles.heading}>Khoản chi</div>
        <div className={styles.heading}>Số tiền</div>
        <div className={styles.heading}>Ghi chú</div>
        {isAdmin && <div className={styles.heading}>Sửa</div>}
        <div className={styles.heading}>Xóa</div>
      </header>

      <div className={styles.content} ref={tableBodyRef}>
        {expenseBills.map((expenseBill) => (
          <div
            className={clsx(styles.row, {
              [styles.full]: isAdmin,
            })}
            key={expenseBill.id}
          >
            <div className={clsx(styles.cell, 'text-center')}>
              {expenseBill.id}
            </div>
            <div className={clsx(styles.cell, 'text-center')}>
              <span className={styles.dateTime}>
                {new Date(expenseBill.createdAt).toLocaleDateString('vi-VN')}
              </span>
              <span className={styles.dateTime}>
                {new Date(expenseBill.createdAt).toLocaleTimeString('vi-VN')}
              </span>
            </div>
            <div className={clsx(styles.cell, 'text-uppercase')}>
              {expenseBill.employee ? expenseBill.employee.displayName : ''}
            </div>
            <div className={clsx(styles.cell, 'text-uppercase')}>
              {expenseBill.expense ? expenseBill.expense.name : ''}
            </div>
            <div className={clsx(styles.cell, 'text-center')}>
              {expenseBill.price.toLocaleString('vi-VN', {
                style: 'currency',
                currency: 'VND',
              })}
            </div>
            <div className={styles.cell}>{expenseBill.note}</div>
            {isAdmin && (
              <div
                className={clsx(styles.cell, 'text-center', 'clickable')}
                onClick={() => setSelectedExpenseBillID(expenseBill.id)}
              >
                <i className={clsx(styles.icon, 'fas fa-edit')} />
              </div>
            )}
            <div
              className={clsx(styles.cell, 'text-center', 'clickable')}
              onClick={() =>
                event.handleRemoveClick(
                  expenseBill.id,
                  showConfirmModal,
                  showNotifyModal,
                  setExpenseBills,
                  setNoExpense,
                  setTotalExpense,
                )
              }
            >
              <i className={clsx(styles.icon, 'fa fa-trash')} />
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default forwardRef(ExpenseTable);
