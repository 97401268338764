import { useEffect } from 'react';

/**
 * @param {{
 *  target?: HTMLElement;
 *  event: string;
 *  callback: (e: Event) => void;
 *  deps: any[];
 * }} props
 */
export const useEventListener = ({ target, event, callback }, deps = []) => {
  useEffect(() => {
    const element = target || document;
    element?.addEventListener(event, callback);

    return () => {
      element?.removeEventListener(event, callback);
    };
  }, deps);
};
