import React, {
  useRef,
  useState,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import PERMISSIONS, { LABELS } from '@/config/permissions';
import actions from '@/application/actions';
import styles from './style.module.scss';

function PermissionRule(props) {
  const {
    state: {
      masterData: { users: usersState },
    },
    actions: { getAllUsers, updateUsers },
  } = props;
  const toast = useRef(null);
  const [users, setUsers] = useState([]);
  const usersById = useMemo(
    () => _.keyBy([...usersState.list], 'id'),
    [usersState],
  );

  const onPermissionChanged = useCallback(
    ({ id, permissionId }) =>
      () => {
        const newUsers = [...users];
        const idx = newUsers.findIndex(({ id: userId }) => id === userId);
        newUsers[idx].hasPermission[permissionId] =
          !newUsers[idx].hasPermission[permissionId];
        newUsers[idx].isChanged = Object.values(PERMISSIONS).some(
          (permissionKey) =>
            !!newUsers[idx].hasPermission[permissionKey] !==
            !!usersById[id].hasPermission[permissionKey],
        );
        setUsers(newUsers);
      },
    [users, usersById],
  );
  const permissionTemplate =
    (permissionId) =>
    ({ hasPermission, id }) =>
      (
        <label className={styles.permissionLabel}>
          <input
            type="checkbox"
            value={hasPermission[permissionId] || false}
            checked={hasPermission[permissionId] || false}
            onChange={onPermissionChanged({ permissionId, id })}
            className={styles.permissionCheckbox}
          />
        </label>
      );
  const onSavedClick = useCallback(() => {
    const changedUsers = users
      .filter((user) => user.isChanged)
      .map(({ id, hasPermission }) => ({
        id,
        permissions: Object.keys(hasPermission)
          .filter((permissionId) => hasPermission[permissionId])
          .map((permissionId) => ({
            permissionId: parseInt(permissionId),
          })),
      }));
    const onSuccess = () =>
      toast.current.show({
        severity: 'success',
        summary: 'Thành công',
        detail: 'Thay đổi quyền thành công',
        life: 3000,
      });
    const onError = (detail = 'Không thể thêm từ khóa') =>
      toast.current.show({
        severity: 'error',
        summary: 'Lỗi',
        detail,
        life: 3000,
      });
    if (!changedUsers.length) {
      onError('Chưa thực hiện thay đổi');
      return;
    }

    updateUsers({
      data: changedUsers,
      onSuccess,
      onError,
    });
  }, [users]);

  useEffect(() => getAllUsers(), []);
  useEffect(
    () => setUsers(JSON.parse(JSON.stringify(usersState.list))),
    [usersState],
  );

  return (
    <div className="container py-4 h-full">
      <h3 className="px-3 text-3xl text-600 uppercase mb-6 py-8 flex items-center">
        Danh sách nhóm quyền
        <Button
          type="button"
          label="Lưu"
          onClick={onSavedClick}
          className={styles.btn}
        />
      </h3>

      <DataTable
        value={users}
        responsiveLayout="scroll"
        className="fadeinleft max-h-[770px] overflow-auto"
      >
        <Column alignHeader="left" header="Tài khoản" field="username"></Column>
        <Column
          alignHeader="left"
          header="Tên người dùng"
          field="displayName"
        ></Column>

        {Object.values(PERMISSIONS).map((permissionId) => (
          <Column
            key={permissionId}
            alignHeader="center"
            align="center"
            header={LABELS[permissionId]}
            body={permissionTemplate(permissionId)}
          />
        ))}
      </DataTable>

      <Toast ref={toast} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(memo(PermissionRule));
