import _ from 'lodash';
import { expenseBillService } from '../../services';
import * as constantNotify from '../NotifyModal/constant';

const remove = (id, showNotifyModal, setExpenseBills, setNoExpense) => {
  expenseBillService
    .remove(id)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        showNotifyModal({
          type: constantNotify.ERROR,
          title: 'Xóa',
          content: data.error,
        });
      } else {
        setExpenseBills((expenseBills) =>
          expenseBills.filter((expenseBill) => {
            const isDiff = expenseBill.id !== id;
            return isDiff;
          }),
        );
        setNoExpense((no) => no - 1);
      }
    });
};

const handleRemoveClick = (
  id,
  showConfirmModal,
  showNotifyModal,
  setExpenseBills,
  setNoExpense,
) => {
  const modal = {
    actionTitle: 'Xóa',
    actionConfirm: `Bạn có chắc muốn xóa đơn số ${id} không?`,
    actionConduct: () =>
      remove(id, showNotifyModal, setExpenseBills, setNoExpense),
  };
  showConfirmModal(modal);
};

const handleSortClick = (setOrder) => {
  setOrder((order) => {
    const arr = ['ASC', 'DESC', ''];
    return arr[(_.indexOf(arr, order) + 1) % arr.length];
  });
};

export { handleRemoveClick, handleSortClick };
