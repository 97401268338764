import { memo, useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import styles from './style.module.scss';
import { Div } from '@/components/atoms';

/**
 *
 * @param {{
 *   isShow: boolean;
 *   onClose: () => void;
 *   handleUpdateSubmit: (updateDate: Date) => void
 * }} param0
 * @returns
 */
const UpdateDateModal = ({ isShow, onClose, handleUpdateSubmit }) => {
  const [updateDate, setUpdateDate] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateSubmit(updateDate);
  };

  const handleDateChanged = useCallback((date) => {
    setUpdateDate(date);
  }, []);

  return (
    <Div
      className={clsx(styles.modal, {
        [styles.hide]: !isShow,
      })}
      onClick={onClose}
      stopPropagation
    >
      <div className={styles.overlay}></div>

      <form
        className={styles.formUpdate}
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className={styles.dateWrapper}>
          <label htmlFor="endDatePicker" className={styles.datePickerLabel}>
            Ngày chi
          </label>

          <ReactDatePicker
            selected={updateDate}
            onChange={handleDateChanged}
            className={styles.datePicker}
            id="endDatePicker"
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
          />
        </div>

        <input className={styles.btn} type="submit" value="Cập nhật" />
      </form>
    </Div>
  );
};

export default memo(UpdateDateModal);
