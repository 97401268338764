import { PaginationBar } from '@components';
import {
  FromToDatePicker,
  ConfirmDialog,
  ToastMessage,
  ImportBillDetail,
} from '@components';
import unitOfWork from '@controllers';
import { useCancellablePromise, useThrottle } from '@hooks';
import clsx from 'clsx';
import React from 'react';
import styles from './ImportBillList.module.scss';

function ImportBillList() {
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: '',
    msg: {
      title: '',
      duration: 0,
      desc: '',
    },
  });
  const [importBills, setImportBills] = React.useState([]);
  const [selectedBill, setSelectedBill] = React.useState({
    visible: false,
    data: {},
  });
  const [count] = React.useState(0);

  const [statuses, setStatutes] = React.useState([]);
  const [filter, setFilter] = React.useState({
    page: 1,
    selectedStatus: 0,
    fromDate: new Date(),
    toDate: new Date(),
  });

  // delete dialog handling
  const [dialogInfo, setDialogInfo] = React.useState({
    isOpen: false,
    isClose: false,
    question: '',
    onDialogAccepted: () => {},
    onDialogRefused: () => {},
  });

  const perPage = 15;
  const totalPage = React.useMemo(() => {
    return Math.floor(count + perPage / perPage);
  }, [count]);

  const fetchImportBillsPage = useCancellablePromise(
    (signal) => () => {
      unitOfWork.importBill.getByPage(
        signal,
        filter.fromDate,
        filter.toDate,
        filter.page,
        filter.selectedStatus,
        perPage,
        setImportBills,
      );
    },
    [filter],
  );

  const fetchStatuses = useCancellablePromise(
    (signal) => () => {
      unitOfWork.importStatus.getAll(signal, setStatutes);
    },
    [],
  );

  const deSelectBill = React.useCallback(() => {
    setSelectedBill({
      visible: false,
      data: {},
    });
  });

  const handleSelectBill = React.useCallback((bill) => () => {
    setSelectedBill({
      visible: true,
      data: bill,
    });
  });

  const updatePage = useThrottle(
    (newPage) => setFilter((filter) => ({ ...filter, page: newPage })),
    500,
  );

  const onDoneDialog = React.useCallback(() => {
    setDialogInfo((dialogInfo) => ({
      isOpen: false,
      isClose: true,
      question: dialogInfo.question,
      onDialogAccepted: () => {},
      onDialogRefused: () => {},
    }));
  }, []);

  const removeBill = React.useCallback(
    (id) => () => {
      onDoneDialog();
      unitOfWork.importBill.delete(
        id,
        setToastMessage,
        fetchImportBillsPage.invoke,
      );
    },
    [fetchImportBillsPage],
  );

  const handleRemoveBillClick = React.useCallback(
    (id) => () => {
      setDialogInfo({
        isOpen: true,
        isClose: false,
        question: `Bạn có chắc là muốn xóa đơn có mã ${id} không?`,
        onDialogAccepted: removeBill(id),
        onDialogRefused: onDoneDialog,
      });
    },
    [],
  );

  const handleSelectedStatusChange = React.useCallback(
    (e) => {
      setFilter({
        ...filter,
        selectedStatus: e.target.value,
      });
    },
    [filter],
  );

  const handleDateChange = React.useCallback(
    (type) => (date) => {
      setFilter({
        ...filter,
        [type]: date,
        page: 1,
      });
    },
    [filter],
  );

  const handleBothDateChange = React.useCallback(
    (fromDate, toDate) => {
      setFilter({
        ...filter,
        fromDate,
        toDate,
        page: 1,
      });
    },
    [filter],
  );

  React.useEffect(() => {
    fetchImportBillsPage.invoke();
    fetchStatuses.invoke();

    return () => {
      fetchImportBillsPage.cancel();
      fetchStatuses.cancel();
    };
  }, [filter]);

  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>Danh sách đơn nhập</h3>

      <div className={styles.filter}>
        <div className={styles.date}>
          <FromToDatePicker
            startDate={filter.fromDate}
            endDate={filter.toDate}
            setStartDate={handleDateChange('fromDate')}
            setEndDate={handleDateChange('toDate')}
            setBothDate={handleBothDateChange}
          />
        </div>

        <select
          className={styles.filterSelect}
          value={filter.selectedStatus}
          onChange={handleSelectedStatusChange}
        >
          <option value={0}>Tình trạng đơn hàng</option>
          {statuses.map((status) => (
            <option value={status.id} key={status.id}>
              {status.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.table}>
        <div className={clsx(styles.tableHeader, styles.row)}>
          <div className={styles.tableHeaderItem}>Mã đơn</div>
          <div className={styles.tableHeaderItem}>Ngày tạo</div>
          <div className={styles.tableHeaderItem}>Tình trạng</div>
          <div className={styles.tableHeaderItem}>Tổng tiền</div>
          <div className={styles.tableHeaderItem}>Số lượng</div>
          <div className={styles.tableHeaderItem}>Nhà cung cấp</div>
          <div className={styles.tableHeaderItem}>Người tạo</div>
          <div className={styles.tableHeaderItem}>Hành động</div>
        </div>

        <div className={styles.tableBody}>
          {importBills.map((item) => (
            <div
              key={item.id}
              className={clsx(styles.row, styles.tableBodyItem)}
            >
              <div className={styles.tableBodyItemText}>{item.id}</div>

              <div className={styles.tableBodyItemText}>
                {item.createdAt &&
                  new Date(item.createdAt).toLocaleDateString('vi-VN')}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.status && item.status.name}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.totalPrice &&
                  item.totalPrice.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.totalQuantity}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.supplier.name}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.createUser.displayName}
              </div>

              <div className={styles.tableBodyItemText}>
                <i
                  className={clsx(styles.icon, 'fas fa-eye')}
                  onClick={handleSelectBill(item)}
                />
                <i
                  className={clsx(styles.icon, 'fas fa-trash')}
                  onClick={handleRemoveBillClick(item.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.pagination}>
        <PaginationBar
          totalPage={totalPage}
          noPages={9}
          columnWidth={40}
          currentPage={filter.page}
          setCurrentPage={updatePage}
        />
      </div>

      <ConfirmDialog
        isOpen={dialogInfo.isOpen}
        isClosed={dialogInfo.isClose}
        question={dialogInfo.question}
        onAccepted={dialogInfo.onDialogAccepted}
        onRefused={dialogInfo.onDialogRefused}
      />

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />

      {selectedBill.visible && (
        <ImportBillDetail
          invoice={selectedBill.data}
          close={deSelectBill}
          statuses={statuses}
          setImportBills={setImportBills}
          setToastMessage={setToastMessage}
        />
      )}
    </div>
  );
}

export default ImportBillList;
