import React from 'react';
import clsx from 'clsx';
import styles from './Select.module.scss';

function Select(
  {
    onChange,
    value,
    label,
    useLabel,
    error,
    children,
    select,
    className,
    customSelectStyle,
    labelCustom,
    ...props
  },
  ref,
) {
  return (
    <div
      className={clsx(styles.formContent, {
        [styles.error]: error,
        [className]: className,
      })}
      error={error}
    >
      {label && (
        <label
          htmlFor={label.id}
          className={clsx(styles.formLabel, labelCustom)}
        >
          {label.name}
        </label>
      )}
      {useLabel && <div className={styles.formLabel}></div>}

      {children}

      <select
        id={label && label.id}
        name={select.name}
        className={clsx(styles.formSelect, customSelectStyle)}
        value={value}
        onChange={onChange}
        ref={ref}
        {...props}
      >
        <option value="0">{select.firstItem}</option>
        {select.items.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default React.forwardRef(Select);
