import { createAsyncThunk } from '@reduxjs/toolkit';
import { TOAST_SEVERITY } from '@config/constants';
import { logger } from '@/utilities';

const _onRejected = (state, action) => {
  const { payload } = action;
  const { pathname } = window.location;
  logger?.error(action, process.env.NODE_ENV);

  if (payload?.status === 401) {
    localStorage.clear();

    const isSignIn = pathname.includes('sign-in');
    const redirect = isSignIn ? '/' : pathname;
    if (redirect) {
      state.ui.redirect = redirect;
      window.location.href = '/sign-in?redirect=' + redirect;
    }
  } else {
    state.ui.toastRef?.show({
      type: TOAST_SEVERITY.ERROR,
      message: payload ?? 'Có lỗi xảy ra, vui lòng thử lại sau',
      timeout: 2000,
    });
  }
};

const dispatch = (onDispatch) => async (payload, options) => {
  const { rejectWithValue } = options;
  try {
    const result = await onDispatch(payload, options);

    return result;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
};

export const mapAsyncActions = (prefix, reducerAsyncActions) =>
  Object.keys(reducerAsyncActions).reduce(
    (prev, action) => {
      const { pending, fulfilled, onDispatch, rejected } =
        reducerAsyncActions[action];
      const asyncAction = createAsyncThunk(
        prefix + '/' + action,
        dispatch(onDispatch),
      );
      prev.asyncActions[action] = asyncAction;
      if (!!pending) {
        prev.extraReducers[asyncAction.pending] = pending;
      }
      if (!!fulfilled) {
        prev.extraReducers[asyncAction.fulfilled] = fulfilled;
      }
      prev.extraReducers[asyncAction.rejected] = (state, options) => {
        _onRejected(state, options);
        rejected?.(state, options);
      };

      return prev;
    },
    { asyncActions: {}, extraReducers: {} },
  );
