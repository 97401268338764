import unitOfWork from '@controllers';
import React, { memo } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

import styles from './CreateNewAccount.module.scss';
import { connect } from 'react-redux';
import actions from '@/application/actions';

function SettingUser(props) {
  const {
    state: {
      masterData: { noteGroupRules },
    },
  } = props;

  const [selectedRole, setSelectedRole] = React.useState(null);
  const [roles, setRoles] = React.useState([]);
  const [selectedEcommerces, setSelectedEcommerces] = React.useState([]);
  const [ecommerces, setEcommerces] = React.useState([]);
  const toast = React.useRef(null);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      repassword: '',
      displayName: '',
      email: '',
      startAt: new Date(),
      noteGroupRuleId: null,
    },
    validate: (data) => {
      let errors = {};

      if (!data.username) {
        errors.username = 'Tên đăng nhập là bắt buộc.';
      }

      if (
        data.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = 'Email không hợp lệ';
      }

      if (!data.password) {
        errors.password = 'Mật khẩu là bắt buộc.';
      }

      if (!data.repassword) {
        errors.repassword = 'Hãy nhập lại mật khẩu.';
      } else if (data.password !== data.repassword) {
        errors.repassword = 'Mật khẩu không khớp.';
      }

      if (!data.startAt) {
        errors.startAt = 'Hãy nhập ngày bắt đầu làm việc của nhân viên.';
      }

      return errors;
    },
    onSubmit: (data) => {
      if (
        selectedRole.type === 'marketter' &&
        selectedEcommerces.length === 0
      ) {
        toast.current.show({
          severity: 'error',
          summary: 'Thông báo lỗi',
          detail: 'Chọn ít nhất 1 trang quảng cáo',
          life: 3000,
        });
      } else {
        if (selectedRole.type === 'marketter') {
          data.ecommerces = selectedEcommerces;
        }
        data.roleID = selectedRole.id;
        unitOfWork.user.create(data, toast.current);

        setSelectedEcommerces([]);
        formik.resetForm();
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onEcommerceChanged = (e) => {
    let newSelectedEcommerces = [...selectedEcommerces];

    if (e.checked) newSelectedEcommerces.push(e.value);
    else
      newSelectedEcommerces.splice(newSelectedEcommerces.indexOf(e.value), 1);

    setSelectedEcommerces(newSelectedEcommerces);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    unitOfWork.role.getAll(controller.signal, setRoles, toast.current);
    unitOfWork.ecommerce.getMarkettingPage(controller.signal, setEcommerces);

    return () => controller.abort();
  }, []);

  React.useEffect(() => {
    if (roles.length > 0) {
      setSelectedRole(roles[0].id);
    }
  }, [roles]);

  return (
    <div className="h-full flex flex-col w-full">
      <h3 className="my-4 text-600 text-3xl ml-1">Tạo tài khoản</h3>

      <Dropdown
        optionLabel="name"
        placeholder="Select a role"
        options={roles}
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.value)}
      />

      <div
        className={classNames(
          'card w-100 p-0 mt-[32px]',
          styles.groupContainer,
        )}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="p-fluid text-right h-full"
        >
          <div className="flex text-left">
            <div className="flex-1 duration-500 transition-all">
              <div className="field">
                <span className="p-float-label">
                  <InputText
                    id="username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      'p-invalid': isFormFieldValid('username'),
                    })}
                  />
                  <label
                    htmlFor="username"
                    className={classNames({
                      'p-error': isFormFieldValid('usersname'),
                    })}
                  >
                    Tên đăng nhâp
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('username')}
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    toggleMask
                    className={classNames({
                      'p-invalid': isFormFieldValid('password'),
                    })}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      'p-error': isFormFieldValid('password'),
                    })}
                  >
                    Mật khẩu
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('password')}
              </div>

              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="repassword"
                    name="repassword"
                    value={formik.values.repassword}
                    onChange={formik.handleChange}
                    toggleMask
                    className={classNames({
                      'p-invalid': isFormFieldValid('repassword'),
                    })}
                    feedback={false}
                  />
                  <label
                    htmlFor="repassword"
                    className={classNames({
                      'p-error': isFormFieldValid('repassword'),
                    })}
                  >
                    Nhập lại mật khẩu
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('repassword')}
              </div>

              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <InputText
                    id="displayName"
                    name="displayName"
                    value={formik.values.displayName}
                    onChange={formik.handleChange}
                    className={classNames({
                      'p-invalid': isFormFieldValid('displayName'),
                    })}
                  />
                  <label
                    htmlFor="displayName"
                    className={classNames({
                      'p-error': isFormFieldValid('displayName'),
                    })}
                  >
                    Tên hiển thị
                  </label>
                </span>
              </div>

              <div className="field mb-0">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-id-card" />
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={classNames({
                      'p-invalid': isFormFieldValid('email'),
                    })}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({
                      'p-error': isFormFieldValid('email'),
                    })}
                  >
                    Email
                  </label>
                </span>
                {getFormErrorMessage('email')}
              </div>

              <div className="field mb-0">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-id-card" />

                  <Calendar
                    id="startAt"
                    value={formik.values.startAt}
                    onChange={(e) => formik.setFieldValue('startAt', e.value)}
                    className={classNames({
                      'p-invalid': isFormFieldValid('startAt'),
                    })}
                    dateFormat="dd/mm/yy"
                    showIcon
                  />
                  <label
                    htmlFor="startAt"
                    className={classNames({
                      'p-error': isFormFieldValid('startAt'),
                    })}
                  >
                    Ngày bắt đầu làm việc
                    <span className="text-red-400">*</span>
                  </label>
                </span>
                {getFormErrorMessage('startAt')}
              </div>

              <div className={classNames('field mb-0', styles.group)}>
                <h3 className={classNames(styles.inputHeader)}>
                  Chọn nhóm ghi chú
                </h3>
                {noteGroupRules.list.map(({ name, id }) => (
                  <div
                    className={classNames(
                      'field-radiobutton',
                      styles.inputGroup,
                    )}
                    key={`note_group_rule_${id}`}
                  >
                    <RadioButton
                      className={styles.radioBtn}
                      id={`note-group-rule-${id}`}
                      name="noteGroupRuleId"
                      type="radio"
                      onChange={formik.handleChange}
                      value={id}
                      checked={formik.values.noteGroupRuleId === id}
                    />
                    <label htmlFor={`note-group-rule-${id}`}>{name}</label>
                  </div>
                ))}
              </div>
            </div>

            <div
              className={classNames(
                'w-[40%] duration-500 transition-all overflow-auto border border-1',
                {
                  'max-w-0 max-h-0':
                    !selectedRole || selectedRole.type !== 'marketter',
                  'max-w-full border-1 ml-[16px] p-[4px] max-h-[314px]':
                    selectedRole && selectedRole.type === 'marketter',
                },
              )}
            >
              <h4 className="text-xl font-semibold mb-4 uppercase">
                Chọn trang marketting
              </h4>
              <div className={styles.ecommerces}>
                {ecommerces.map((ecommerce) => (
                  <div className="mb-4" key={ecommerce.id}>
                    <Checkbox
                      value={ecommerce.id}
                      checked={selectedEcommerces.includes(ecommerce.id)}
                      onChange={onEcommerceChanged}
                    ></Checkbox>
                    <label htmlFor="cb1" className="ml-2 text-900">
                      {ecommerce.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-fit ml-auto">
            <Button type="submit" label="Tạo tài khoản" className="mt-32 " />
          </div>
        </form>
      </div>

      <Toast ref={toast} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(memo(SettingUser));
