import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';

function NoteItem(props) {
  const {
    pNote,
    selectedItem,
    setSelectedItem,
    actions: { updateNote, getNoteGroups, deleteNoteGroup },
  } = props;
  const noteInput = useRef(null);
  const itemActionsRef = useRef(null);

  const [note, setNote] = useState(pNote);
  const [isNoteChanged, setIsNoteChanged] = useState(false);

  const handleNoteChange = (e) => {
    setNote((note) => ({ ...note, note: e.target.value }));
    setIsNoteChanged(true);
  };

  const onSave = useCallback(() => {
    if (!note.note) {
      getNoteGroups(note.id);
      return;
    }
    isNoteChanged &&
      updateNote({ note, callback: () => setIsNoteChanged(false) });
  }, [note]);

  const onDelete = useCallback(() => {
    deleteNoteGroup({ id: note.id });
  }, [note.id]);

  useEffect(() => {
    noteInput.current.style.height = 0;
    noteInput.current.style.height = `${noteInput.current.scrollHeight}px`;
  }, [note]);

  useEffect(() => {
    setNote(pNote);
  }, [pNote])

  return (
    <div
      className={clsx(styles.noteWrapper)}
      onContextMenu={(e) => {
        e.preventDefault();
        itemActionsRef.current.style.top = `${
          noteInput.current.getBoundingClientRect().top - 150
        }px`;
        setSelectedItem(note.id);
      }}
      key={note.id}
    >
      <textarea
        className={clsx(styles.note, {
          [styles.isSelected]: selectedItem === note.id,
        })}
        value={note.note}
        onChange={handleNoteChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
            e.preventDefault();
            onSave();
          }
        }}
        onBlur={onSave}
        ref={noteInput}
        disabled={selectedItem === note.id ? '' : 'disabled'}
      />

      <ul
        className={clsx(styles.itemActions, {
          [styles.show]: selectedItem === note.id,
        })}
        ref={itemActionsRef}
      >
        <li className={styles.itemAction} onClick={onDelete}>
          <i className="fas fa-trash" />
          Xóa
        </li>
      </ul>

      <div
        className={clsx(styles.overlay, {
          [styles.show]: selectedItem === note.id,
        })}
        onClick={() => setSelectedItem(0)}
      ></div>
    </div>
  );
}

export default memo(NoteItem);
