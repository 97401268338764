import { userService } from '@services';

class UserController {
  getAll = async (cancelSignal, setUsers, toast, query = {}) => {
    try {
      const result = await userService.getAll(cancelSignal, query);

      if (result.success) {
        setUsers(
          result.data.map((user) => {
            return {
              ...user,
              isBan: !!user.deletedAt,
            };
          }),
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo lỗi',
        detail: 'Không thể lấy danh sách người dùng',
        sticky: true,
      });
    }
  };

  create = async (data, toast) => {
    try {
      const result = await userService.create(data);

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Thông báo',
          detail: 'Tạo tài khoản thành công',
          life: 3000,
        });
      } else {
        toast.show({
          severity: 'error',
          summary: 'Thông báo',
          detail: result.msg || 'Có lỗi xảy ra khi tạo tài khoản',
          life: 3000,
        });
      }
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo lỗi',
        detail: 'Có lỗi xảy ra khi tạo tài khoản',
        life: 3000,
      });
    }
  };

  ban = async (id, setUsers, toast) => {
    try {
      const result = await userService.ban(id);

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Thông báo',
          detail: 'Đã khóa tài khoản',
          life: 3000,
        });
        setUsers((users) => {
          const newUsers = [...users];
          const index = newUsers.findIndex((user) => user.id === id);
          newUsers[index].deletedAt = result.deletedAt;
          newUsers[index].isBan = true;

          return newUsers;
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo lỗi',
        detail: 'Không thể khóa tài khoản',
        life: 3000,
      });
    }
  };

  unban = async (id, setUsers, toast) => {
    try {
      const result = await userService.unban(id);

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Thông báo',
          detail: 'Đã mở khóa tài khoản',
          life: 3000,
        });
        setUsers((users) => {
          const newUsers = [...users];
          const index = newUsers.findIndex((user) => user.id === id);
          newUsers[index].deletedAt = null;
          newUsers[index].isBan = false;

          return newUsers;
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo lỗi',
        detail: 'Không thể mở khóa tài khoản',
        life: 3000,
      });
    }
  };

  resetPassword = async (userID, password, toast) => {
    try {
      const result = await userService.resetPassword(userID, password);

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Thông báo',
          detail: 'Đã đặt lại mật khẩu',
          life: 3000,
        });
      } else {
        toast.show({
          severity: 'error',
          summary: 'Thông báo',
          detail: 'Đã đặt lại mật khẩu',
          life: 3000,
        });
      }
    } catch (_) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo',
        detail: 'Có lỗi xảy ra',
        life: 3000,
      });
    }
  };

  updateInfo = async (userId, info, toast) => {
    try {
      const result = await userService.updateInfo(userId, info);

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Cập nhật thành công',
          detail: '',
          lifecycle: 3000,
        });
      } else {
        toast.show({
          severity: 'error',
          summary: result.msg || 'Có lỗi khi cập nhật',
          detail: '',
          lifecycle: 3000,
        });
      }
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Có lỗi khi cập nhật',
        detail: '',
        lifecycle: 3000,
      });
    }
  };
}

export default UserController;
