import { Input } from '@/components/atoms';
import clsx from 'clsx';
import { memo } from 'react';

import styles from './styles.module.scss';

export const InputField = memo((props) => {
  const {
    containerClassName,
    label,
    labelClassName,
    className,
    ...inputProps
  } = props;

  return (
    <div
      className={clsx(styles.container, {
        [containerClassName]: containerClassName,
      })}
    >
      <label
        className={clsx(styles.label, {
          [labelClassName]: labelClassName,
        })}
      >
        {label}
      </label>
      <Input
        {...inputProps}
        className={clsx(styles.input, { [className]: className })}
      />
    </div>
  );
});
