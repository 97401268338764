import _ from 'lodash';
import { billService } from '@services';

class BillController {
  #getOrder = (order) => {
    let query = '';
    const orderChoose = _.pickBy(order, (value) => value);
    const orderGroup = _.invertBy(orderChoose);

    _.keys(orderGroup).forEach((key) => {
      query +=
        `&` + orderGroup[key].map((value) => `${value}Ord=${key}`).join('&');
    });

    return query;
  };

  getAll = async (
    cancelSignal,
    startDate,
    endDate,
    selectedShipService,
    selectedStatus,
    selectedProduct,
    orders,
  ) => {
    const result = await billService.getAll(
      cancelSignal,
      startDate,
      endDate,
      selectedShipService,
      selectedStatus,
      selectedProduct,
      this.#getOrder(orders),
    );

    return result;
  };

  getBillPage = async (
    cancelSignal,
    page,
    startDate,
    endDate,
    selectedShipService,
    selectedStatus,
    selectedProduct,
    orders,
    bills,
    setBills,
    setTotalBillCount,
    setStopScrollEvent,
  ) => {
    try {
      let query = `start=${startDate.getTime()}&end=${endDate.getTime()}`;

      if (parseInt(selectedShipService)) {
        query += `&ship=${selectedShipService}`;
      }

      if (parseInt(selectedStatus)) {
        query += `&status=${selectedStatus}`;
      }

      if (parseInt(selectedProduct)) {
        query += `&product=${selectedProduct}`;
      }

      query += this.#getOrder(orders);

      query += `&page=${page}`;

      const result = await billService.queryBills(cancelSignal, query);

      if (!result.error) {
        let newBills = result.rows;

        if (page > 1) {
          newBills = _.uniqBy([...bills, ...newBills], (value) => value.id);
        }

        setBills(newBills);
        setTotalBillCount(result.count);

        if (newBills.length < result.count) {
          setStopScrollEvent(false);
        }
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  search = async (cancelSignal, searchText, searchType, orders, setBills) => {
    const query = `search=${searchText}&type=${searchType}${this.#getOrder(
      orders,
    )}`;
    const result = await billService.queryBills(cancelSignal, query);

    if (!result.error) {
      setBills(result);
    }
  };
}

export default BillController;
