import * as validate from './validate';
import * as constantNotify from '../NotifyModal/constant';
import { expenseService } from '../../services';

const removeError = (setError) => setError('');

const handleExpenseChange = (e, setExpense, setError) => {
    removeError(setError);
    setExpense((expense) => ({ ...expense, name: e.target.value }));
};

const submitForm = (
    expense,
    setExpense,
    getExpenses,
    setNotifyModal,
    setNotifyModalShow,
) => {
    if (expense.id === 0) {
        expenseService
            .create(expense.name)
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setNotifyModal({
                        type: constantNotify.ERROR,
                        title: 'Thêm',
                        content: data.error,
                    });
                    setNotifyModalShow(true);
                } else {
                    getExpenses();
                    setExpense({ id: 0, name: '' });
                }
            });
    } else {
        expenseService
            .update(expense)
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setNotifyModal({
                        type: constantNotify.ERROR,
                        title: 'Sửa',
                        content: data.error,
                    });
                    setNotifyModalShow(true);
                } else {
                    getExpenses();
                    setExpense({ id: 0, name: '' });
                }
            });
    }
};

const handleSubmitForm = (
    e,
    expense,
    setExpense,
    getExpenses,
    setNotifyModal,
    setNotifyModalShow,
    setError,
) => {
    e.preventDefault();
    if (validate.validateForm(expense.name, setError)) {
        submitForm(
            expense,
            setExpense,
            getExpenses,
            setNotifyModal,
            setNotifyModalShow,
        );
    }
};

export { handleExpenseChange, handleSubmitForm };
