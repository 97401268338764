import { ecommerceService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class EcommerceController {
  getAll = async (cancelSignal, setEcommerces) => {
    try {
      const result = await ecommerceService.getAll(cancelSignal);

      if (!result.error) {
        setEcommerces(result);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  update = async (
    ecommerce,
    setToastMessage,
    fetchEcommerces,
    closeEditButton,
  ) => {
    try {
      const result = await ecommerceService.update(ecommerce);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật thành công',
          },
        });
        fetchEcommerces();
        closeEditButton();
      } else {
        throw new Error();
      }
    } catch (err) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật',
        },
      });
    }
  };

  create = async (name, setToastMessage, fetchEcommerces, closeEditButton) => {
    try {
      const result = await ecommerceService.create(name);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật thành công',
          },
        });
        fetchEcommerces();
        closeEditButton();
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Không có thay đổi gì để cập nhật',
          },
        });
      }
    } catch (err) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật',
        },
      });
    }
  };

  getMarkettingPage = async (
    cancelController,
    setMarkettingPage,
    markettingBy,
  ) => {
    try {
      const result = await ecommerceService.getMarkettingPage(
        cancelController,
        markettingBy,
      );

      if (result.success) {
        setMarkettingPage(result.data);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  updateMarketting = async (markettingBy, ecommerces, toast) => {
    try {
      const result = await ecommerceService.updateMarketting(
        markettingBy,
        ecommerces,
      );

      if (result.success) {
        toast.show({
          severity: 'success',
          summary: 'Thông báo thành công',
          detail: 'Cập nhật trang quảng cáo cho tài khoản thành công',
          life: 3000,
        });
      }
    } catch (err) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo thất bại',
        detail: 'Cập nhật trang quảng cáo cho tài khoản thất bại',
        life: 3000,
      });
    }
  };
}

export default EcommerceController;
