import { expenseBillService, expenseService } from '@services';
import * as constantNotify from '@components/NotifyModal/constant';
import _ from 'lodash';

const getExpenses = (setExpenses) => {
  expenseService
    .getAll()
    .then((res) => res.json())
    .then((data) => {
      if (!data.error) setExpenses(data);
    })
    .catch((error) => console.log(error));
};

const getExpenseBills = (
  isAdmin,
  page,
  filterCondition,
  order,
  setExpenseBills,
  setNoExpense,
  setStopScrollEvent,
  setNewData,
  setFilterExpenseIDs,
) => {
  const func = isAdmin
    ? expenseBillService.filter(
        page,
        filterCondition.startDate.getTime(),
        filterCondition.endDate.getTime(),
        order,
        filterCondition.selectedExpense,
      )
    : expenseBillService.getAll(page, order);
  func
    .then((res) => res.json())
    .then((data) => {
      if (!data.error) {
        if (page > 1) {
          setExpenseBills((expenseBills) => {
            const newExpenseBills = _.uniqBy(
              [...expenseBills, ...data.rows],
              'id',
            );
            return newExpenseBills;
          });
        } else {
          setExpenseBills(data.rows);
          setFilterExpenseIDs(data.expense || []);
        }

        setNoExpense((old) => {
          if (old !== 0 && old !== data.count && page > 1) {
            setNewData('Có dữ liệu mới!');
          }
          return data.count;
        });
        if (data.rows.length > 0) {
          setStopScrollEvent(false);
        }
      }
    })
    .catch((error) => console.log(error));
};

const handleRefreshClick = (setNewData, setPage) => {
  setNewData('');
  setPage(1);
};

const handleSubmitForm = (
  formData,
  setExpenseBills,
  setNoExpense,
  setNotifyModalShow,
  setNotifyModal,
) =>
  expenseBillService
    .create(formData)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        setNotifyModal({
          type: constantNotify.ERROR,
          title: 'Thêm',
          content: data.error,
        });
        setNotifyModalShow(true);
        return false;
      } else {
        setExpenseBills((expenseBills) => [data, ...expenseBills]);
        setNoExpense((no) => no + 1);
        return true;
      }
    })
    .catch(() => false);

const showConfirmModal = (modal, setConfirmModal, setConfirmModalShow) => {
  setConfirmModal(modal);
  setConfirmModalShow(true);
};

const showNotifyModal = (modal, setNotifyModal, setNotifyModalShow) => {
  setNotifyModal(modal);
  setNotifyModalShow(true);
};

const handleUpdateDateChange = (
  date,
  setUpdateDate,
  setNotifyModal,
  setNotifyModalShow,
) => {
  if (date <= new Date()) {
    setUpdateDate(date);
  } else {
    showNotifyModal(
      {
        type: constantNotify.ERROR,
        title: 'Chọn ngày',
        content: 'Chọn ngày nhỏ hơn ngày hiện tại',
      },
      setNotifyModal,
      setNotifyModalShow,
    );
  }
};

const handleUpdateSubmit = (
  selectedExpenseBillID,
  updatedDate,
  setSelectedExpenseBillID,
  setExpenseBills,
  setNotifyModal,
  setNotifyModalShow,
) => {
  expenseBillService
    .update(selectedExpenseBillID, updatedDate)
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        showNotifyModal(
          {
            type: constantNotify.ERROR,
            title: 'Cập nhật',
            content: data.error,
          },
          setNotifyModal,
          setNotifyModalShow,
        );
      } else {
        setExpenseBills((expenseBills) => {
          _.find(
            expenseBills,
            (expenseBill) => expenseBill.id === selectedExpenseBillID,
          ).createdAt = updatedDate;
          return expenseBills;
        });
        setSelectedExpenseBillID(0);
      }
    });
};

const handleSelectedExpenseChange = (e, setSelectedExpense) => {
  setSelectedExpense(e.target.value);
};

export {
  handleSelectedExpenseChange,
  handleRefreshClick,
  handleSubmitForm,
  getExpenses,
  getExpenseBills,
  showConfirmModal,
  showNotifyModal,
  handleUpdateDateChange,
  handleUpdateSubmit,
};
