import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as constants from '../constants';
import { Toast } from 'primereact/toast';
import { TabBar } from '@components';
import { FilterBar } from '../components';
import MarketDetail from './MarketDetail';
import MarketByPage from './MarketByPage';
import { selectIsTotalTab, setTab } from '../slice';

function Market() {
  const location = useLocation();
  const selectedTab = `/market` + location.hash;
  const toast = React.useRef(null);
  const isTotalTab = useSelector(selectIsTotalTab);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setTab(selectedTab));
  }, [selectedTab]);

  const showToast = (severity, summary, detail, life) => {
    toast.current.show({ severity, summary, detail, life });
  };

  return (
    <div className="base-container">
      <TabBar tabItems={constants.tabItems} currentLink={selectedTab} />

      <FilterBar showToast={showToast} isTotal={isTotalTab}></FilterBar>
      {isTotalTab ? (
        <MarketByPage></MarketByPage>
      ) : (
        <MarketDetail></MarketDetail>
      )}

      <Toast ref={toast} />
    </div>
  );
}

export default Market;
