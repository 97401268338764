import _ from 'lodash';

import { SupplierSchema } from '@/config/schemas';
import facade from '@/domain/models/utils/facade';
import { _pc } from '@/domain/models/utils/model-proto';

import BaseModel from '../..';
import QtPhone from '@/domain/models/value-object/qtPhone';

class Supplier extends BaseModel {
  static properties = {
    id: null,
    company: null,
    name: null,
    email: null,
    address: null,
    phone: _pc(QtPhone, new QtPhone()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string | null;
  multiPrice: QtPrice;
  stockPrice: QtPrice;
  count: number | null;
  */
  setItem(item) {
    if (item.phone) {
      item.phone = QtPhone.getRawValue(item.phone, this.phone.value);
    }
    return facade.update(this, item);
  }

  validate() {
    const { error } = SupplierSchema.validate(
      JSON.parse(JSON.stringify(this)),
      {
        abortEarly: false,
      },
    );

    return error?.details;
  }

  diff(supplier) {
    const diffKeys = this.diffKeys(supplier, ['id']);

    return diffKeys.length ? _.pick(supplier, [...diffKeys, 'id']) : null;
  }

  static new(defaultValue) {
    return facade.create(Supplier, defaultValue);
  }

  static clone(supplier) {
    return facade.create(Supplier, supplier);
  }
}

export default Supplier;
