import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../../..';
import CategoryReport from './item';

class CategoriesReport extends BaseModel {
  static properties = {
    list: _p(CategoryReport, new CategoryReport()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default CategoriesReport;
