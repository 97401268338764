import React from 'react';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { PCHeader } from './PCHeader';
import { MobileHeader } from './MobileHeader';

const routes = [
  {
    to: '/market',
    actualTo: '/market#detail',
    text: 'QUẢNG CÁO',
  },
];
const routeUser = [
  {
    to: '/my-account',
    text: 'Thông tin tài khoản',
  },
  {
    to: '/sign-out',
    text: 'Đăng xuất',
  },
];

const routeSP = [
  {
    to: '/my-account',
    text: 'THÔNG TIN TÀI KHOẢN',
  },
  {
    to: '/market',
    actualTo: '/market#detail',
    text: 'QUẢNG CÁO',
  },
  {
    to: '/sign-out',
    text: 'ĐĂNG XUẤT',
  },
];

function Navigation({ user }) {
  return (
    <div className="grid wide">
      <PCHeader routes={routes} user={user} routeUser={routeUser}/>

      {/* Phone interface */}
      <MobileHeader routes={routeSP}/>
    </div>
  );
}

export default Navigation;
