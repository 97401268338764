const nameCatalog = ['Thu', 'Chi'];

const types = [
    [
        { type: 'product', name: 'Sản phẩm' },
        { type: 'shipService', name: 'ĐVVC' },
        { type: 'employee', name: 'Nhân viên' },
        { type: 'ecommerce', name: 'Kênh TMĐT' },
    ],
    [
        { type: 'expense', name: 'Khoản chi' },
        { type: 'employee', name: 'Nhân viên' },
    ],
];

var options = {
    count: {
        plugins: {
            datalabels: {
                formatter: function (value, context) {
                    const index = context.dataIndex;
                    // return null;
                    if (!value) {
                        return '';
                    }
                    return `${context.chart.data.labels[
                        index
                    ].toUpperCase()} (${value})`;
                },
                align: 'center',
                anchor: 'center',
                clip: true,
                font: {
                    size: '16',
                    weight: 'bold',
                },
                color: '#fff',
            },
        },
    },
    totalPrice: {
        plugins: {
            datalabels: {
                formatter: function (value, context) {
                    const index = context.dataIndex;
                    if (!value) {
                        return '';
                    }
                    return `${context.chart.data.labels[
                        index
                    ].toUpperCase()} (${value.toLocaleString('vi-VN', {
                        style: `currency`,
                        currency: `VND`,
                    })})`;
                },
                align: 'center',
                anchor: 'center',
                clip: true,
                font: {
                    size: '16',
                    weight: 'bold',
                },
                color: '#fff',
            },
        },
    },
};

const colors = [
    '#80ADD7',
    '#0ABDA0',
    '#D4DCA9',
    '#BF9D7A',
    '#C0334D',
    '#D6618F',
    '#F3D4A0',
    '#F1931B',
    '#8F715B',
    '#270101',
    '#720017',
    '#D8D583',
    '#D9AC2A',
    '#763F02',
    '#04060F',
    '#03353E',
    '#0294A5',
    '#A79C93',
    '#C1403D',
];

export { types, options, nameCatalog, colors };
