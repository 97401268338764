import { exportStatusService } from '@services';

class ExportStatusController {
  getAll = async (cancelSignal, setStatuses) => {
    try {
      const result = await exportStatusService.getAll(cancelSignal);

      if (result.success) {
        setStatuses(result.data);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };
}

export default ExportStatusController;
