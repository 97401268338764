import clsx from 'clsx';
import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { CustomDatePicker } from '@components';
import { NativeSelectContainer } from '@/components/organisms';
import { ORDER_STATUS, ORDER_STATUS_NAMES } from '@config/constants';
import styles from './style.module.scss';
import { useEventListener } from '@/hooks/ui';

const STATUS_LABELS = Object.values(ORDER_STATUS).map((key) => ({
  id: key,
  name: ORDER_STATUS_NAMES[key],
}));

/**
 *
 * @param {{
 *   filterCondition: import('../../v1/ListBill').IFilterCondition;
 *   onChange: (name, value) => void;
 *   masterData: {
 *    shipServices: { id: number, name: string }[];
 *    products: { id: number, name: string }[];
 *   };
 *   paginationInfo: {
 *    currentBillCount: number;
 *    totalBillCount: number;
 *   };
 * }} props
 * @returns {JSX.Element}
 */
const _FilterHeader = ({
  filterCondition,
  onChange,
  masterData,
  paginationInfo,
}) => {
  const [isShow, setIsShow] = useState(false);

  const masterOptions = useMemo(
    () => ({
      status: [{ id: 0, name: 'Tình trạng' }, ...STATUS_LABELS],
      shipService: [
        { id: 0, name: 'Đơn vị vận chuyển' },
        ...masterData.shipServices,
      ],
      product: [{ id: 0, name: 'Sản phẩm' }, ...masterData.products],
    }),
    [masterData],
  );

  const toggleIsShow = useCallback((e) => {
    e.stopPropagation();
    setIsShow((prev) => !prev);
  }, []);

  useEventListener({
    event: 'click',
    callback: () => setIsShow(false),
  });

  return (
    <Stack
      flexDirection="row"
      flexWrap="wrap"
      position="relative"
      padding="16px"
      display="flex"
      gap="20px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Button
        variant="outlined"
        startIcon={<FilterListIcon />}
        sx={{
          textTransform: 'none', // Prevents text from being uppercase
          fontWeight: 'bold', // Optional styling
          fontSize: '1.6rem',
          display: {
            md: 'none',
          },
        }}
        onClick={toggleIsShow}
      >
        Bộ lọc
      </Button>

      <div className={styles.billCountSP}>
        Số lượng đơn:
        <h3 className={styles.billCountContent}>
          {paginationInfo.currentBillCount} / {paginationInfo.totalBillCount}
        </h3>
      </div>

      <Stack
        alignItems="flex-end"
        className={clsx(styles.filterContainer, {
          [styles.show]: isShow,
        })}
        sx={{
          minHeight: '34px',
          position: 'relative',
          marginLeft: '30px',
          gap: '20px',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <NativeSelectContainer
          name="status"
          options={masterOptions.status}
          value={filterCondition.status}
          onChange={(e) => onChange(e.target.name, +e.target.value)}
          selectClassName={styles.selectClassName}
          containerClassName={styles.containerClassName}
        />

        <NativeSelectContainer
          name="shipService"
          options={masterOptions.shipService}
          value={filterCondition.shipService}
          onChange={(e) => onChange(e.target.name, +e.target.value)}
          selectClassName={styles.selectClassName}
          containerClassName={styles.containerClassName}
        />

        <NativeSelectContainer
          options={masterOptions.product}
          value={filterCondition.productId}
          hiddenLabel
          onChange={(e) => onChange(e.target.name, +e.target.value)}
          selectClassName={styles.selectClassName}
          containerClassName={styles.containerClassName}
        />

        <CustomDatePicker
          startDate={filterCondition.startDate}
          endDate={filterCondition.endDate}
          setStartDate={(val) => onChange('startDate', val)}
          setEndDate={(val) => onChange('endDate', val)}
        />

        <div className={styles.billCount}>
          Số lượng đơn:
          <h3 className={styles.billCountContent}>
            {paginationInfo.currentBillCount} / {paginationInfo.totalBillCount}
          </h3>
        </div>
      </Stack>
    </Stack>
  );
};

export const FilterHeader = memo(_FilterHeader);
