import _ from 'lodash';
import { useCallback } from 'react';

export const useRemoveItem = (setFormValue) =>
  useCallback(
    (field, key, filteredValue) =>
      setFormValue((_prev) => ({
        ..._prev,
        [field]: _prev[field].filter(
          (item) => _.get(item, key) !== filteredValue,
        ),
      })),
    [],
  );
