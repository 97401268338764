import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import ExportOrder from '../export-order';

class ExportOrders extends BaseModel {
  static properties = {
    list: _p(ExportOrder, new ExportOrder()),
    count: null,
    totalPage: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: ExportOrder[];
  count: number | null;
  */
}

export default ExportOrders;
