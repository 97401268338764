import React, { useContext, useEffect, useState } from 'react';
import { ProtectedRoute } from '@components';
import * as event from './event';
import { UserContext } from '@contexts/App';

function MarketterRoute() {
    const [isMarketter, setIsMarketter] = useState(<React.Fragment />);
    const userContext = useContext(UserContext);

    useEffect(() => {
        event.isMarketter(userContext, setIsMarketter);
    }, [userContext]);

    const MarketterOutlet = () => isMarketter;

    return (
        <React.Fragment>
            <ProtectedRoute>
                <MarketterOutlet />
            </ProtectedRoute>
        </React.Fragment>
    );
}

export default MarketterRoute;
