import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import SupplierListFilter from './supplier-list-filter';
import QtProductListFilter from './qt-product-list-filter';
import ExportOrderListFilter from './export-order-list-filter';
import ImportOrderListFilter from './import-order-list-filter';
import StatisticFilter from './statistic-filter';

export default class StockFilter extends BaseModel {
  static properties = {
    qtProducts: _p(QtProductListFilter, new QtProductListFilter()),
    suppliers: _p(SupplierListFilter, new SupplierListFilter()),
    exportOrders: _p(ExportOrderListFilter, new ExportOrderListFilter()),
    importOrders: _p(ImportOrderListFilter, new ImportOrderListFilter()),
    statistic: _p(StatisticFilter, new StatisticFilter()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  exportOrders: ExportOrderListFilter;
  */
}
