import { TOAST_SEVERITY } from '@/config/constants';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { memo, useEffect, useMemo } from 'react';
import styles from './style.module.scss';

const DEFAULT_FORM = {
  id: null,
  name: '',
  noteGroups: [],
};

const EditOnRight = (props) => {
  const {
    formData = {},
    onBack,
    noteGroups,
    toast,
    actions: { updateNoteRule, createNoteRule },
  } = props;

  const form = useFormik({
    initialValues: { ...DEFAULT_FORM },
    onSubmit: (data) => {
      const onSuccess = () => {
        toast?.current.show({
          severity: TOAST_SEVERITY.SUCCESS,
          summary: 'Thành công',
          detail: 'Lưu nhóm quyền thành công',
        });
        onBack();
      };
      const onError = (detail) => {
        toast?.current.show({
          severity: TOAST_SEVERITY.SUCCESS,
          summary: 'Thất bại',
          detail,
        });
      };

      data.id
        ? updateNoteRule({ ...data, onSuccess, onError })
        : createNoteRule({ ...data, onSuccess, onError });
    },
  });

  const checkAll = useMemo(
    () => form.values.noteGroups.length === noteGroups.list.length,
    [form.values.noteGroups, noteGroups.list],
  );

  const noteGroupsId = useMemo(
    () => noteGroups.list.map(({ id }) => id),
    [noteGroups.list],
  );

  const onCheckAll = (e) => {
    form.setFieldValue('noteGroups', e.target.checked ? [...noteGroupsId] : []);
  };

  useEffect(() => {
    form.setFormikState((prevForm) => ({
      ...prevForm,
      values: formData.isOpen && formData.data ? formData.data : DEFAULT_FORM,
    }));
  }, [formData]);

  return (
    <div
      className={classNames('fadeinright', {
        hidden: !formData.isOpen,
      })}
    >
      <div>
        <div className="flex">
          <form onSubmit={form.handleSubmit} className="text-right flex-1">
            <div className={classNames(styles.inputGroup)}>
              <label htmlFor="name">Tên</label>
              <InputText
                id="name"
                name="name"
                value={form.values.name}
                className={classNames(styles.input)}
                onChange={form.handleChange}
              />
            </div>

            <div className={classNames('field w-full mt-32', styles.group)}>
              <h3
                className={classNames(
                  'flex items-center gap-10',
                  styles.inputHeader,
                )}
              >
                Chọn nhóm ghi chú
                <div className="ml-4 flex items-center gap-2 font-normal">
                  <Checkbox
                    className={styles.checkBoxBtn}
                    id={`note-group-rule-checkAll`}
                    name="noteGroupsAll"
                    type="checkbox"
                    onChange={onCheckAll}
                    value={'all'}
                    checked={checkAll}
                  />
                  <label htmlFor={`note-group-rule-checkAll`}>
                    Chọn tất cả
                  </label>
                </div>
              </h3>
              {noteGroups.list.map(({ name, id }) => (
                <div
                  className={classNames(
                    'field-radiobutton',
                    styles.inputCheckBoxGroup,
                  )}
                  key={`note_group_rule_${id}`}
                >
                  <Checkbox
                    className={styles.checkBoxBtn}
                    id={`note-group-rule-${id}`}
                    name="noteGroups"
                    type="checkbox"
                    onChange={form.handleChange}
                    value={id}
                    checked={form.values.noteGroups.includes(id)}
                  />
                  <label htmlFor={`note-group-rule-${id}`}>{name}</label>
                </div>
              ))}
            </div>

            <Button type="submit" label="Lưu" className="mt-4 w-auto" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(EditOnRight);
