import { CustomMonthPicker } from '@/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByMonth, selectTotalOrder } from '../slice';

function FilterBar({ showToast, classNames = '' }) {
  const dispatch = useDispatch();

  const [filterDate, setFilterDate] = React.useState(new Date());
  const totalOrder = useSelector(selectTotalOrder);

  const checkValidMonthChange = React.useCallback((selectedMonth) => {
    if (selectedMonth > new Date()) {
      showToast('error', 'Chọn ngày nhỏ hơn ngày hiện tại', '', 3000);

      return false;
    }

    return true;
  }, []);

  React.useEffect(() => {
    if (filterDate) {
      const month = filterDate.getMonth() + 1;
      const year = filterDate.getFullYear();

      dispatch(fetchByMonth({ month, year }));
    }
  }, [filterDate]);

  return (
    <div className={`${classNames} flex p-[20px] `}>
      <CustomMonthPicker
        label="Ngày thống kê"
        month={filterDate}
        setMonth={setFilterDate}
        checkValid={checkValidMonthChange}
      />

      <div className="ml-auto flex items-center gap-x-[4px]">
        <h3 className="text-[14px] uppercase">Tổng số đơn:</h3>
        <span className="font-bold text-[16px]">{totalOrder}</span>
      </div>
    </div>
  );
}

export default FilterBar;
