import { importBillService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class ImportBillController {
  create = async (supplierID, statusID, details, setToastMessage) => {
    try {
      if (supplierID === 0) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: 'Vui lòng chọn nhà cung cấp',
          },
        });
        return;
      }
      const importedBill = {
        supplierID,
        statusID,
        details,
      };
      const result = await importBillService.create(importedBill);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Tạo đơn thành công',
          },
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Thất bại',
          duration: 3000,
          desc: 'Tạo đơn thất bại',
        },
      });
    }
  };

  getByPage = async (
    cancelSignal,
    from,
    to,
    page,
    status,
    perPage,
    setImportBills,
  ) => {
    try {
      const result = await importBillService.getByPage(
        cancelSignal,
        from.getTime(),
        to.getTime(),
        page,
        status,
        perPage,
      );

      if (result.success) {
        const data = result.data.map((importBill) => {
          const total = importBill.importBillDetails.reduce(
            (prev, cur) => ({
              totalPrice: prev.totalPrice + cur.unitPrice * cur.quantity,
              totalQuantity: prev.totalQuantity + cur.quantity,
            }),
            { totalPrice: 0, totalQuantity: 0 },
          );

          return { ...importBill, ...total };
        });

        setImportBills(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  delete = async (id, setToastMessage, fetchImportBillsPage) => {
    try {
      const result = await importBillService.deleteBill(id);

      if (result.success) {
        fetchImportBillsPage();

        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 0,
            desc: 'Xóa đơn thành công',
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể xóa đơn',
        },
      });
    }
  };

  updateStatus = async (id, status, setToastMessage, setImportBills) => {
    try {
      const result = await importBillService.updateStatus(id, status.id);

      if (result.success) {
        setImportBills((importBills) => {
          const newImportBills = importBills.map((importBill) => {
            if (importBill.id === id) {
              return {
                ...importBill,
                status,
              };
            }
            return importBill;
          });

          return newImportBills;
        });

        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật trạng thái thành công',
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật trạng thái',
        },
      });
    }
  };
}

export default ImportBillController;
