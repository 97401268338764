import React, { useContext, useEffect, useState } from 'react';
import { ProtectedRoute } from '@components';
import * as event from './event';
import { UserContext } from '@contexts/App';

function ManagerRoute() {
    const [isManager, setIsManager] = useState(<React.Fragment />);
    const userContext = useContext(UserContext);

    useEffect(() => {
        event.isManager(userContext, setIsManager);
    }, [userContext]);

    const ManagerOutlet = () => isManager;

    return (
        <React.Fragment>
            <ProtectedRoute>
                <ManagerOutlet />
            </ProtectedRoute>
        </React.Fragment>
    );
}

export default ManagerRoute;
