const handleProductChange = (e, index, setBills) => {
  setBills((bills) => {
    const temp = [...bills];
    temp[index].product = e.target.value;
    return temp;
  });
};

const handleCountChange = (e, index, setBills) => {
  if (e.target.value.search(/[^0-9]/g) === -1) {
    setBills((bills) => {
      const temp = [...bills];
      temp[index].count = e.target.value && parseInt(e.target.value);

      if (temp[index].price && temp[index].count)
        temp[index].total = temp[index].price * temp[index].count;

      return temp;
    });
  }
};

const handlePriceChange = (e, index, setBills) => {
  const value = e.target.value.replace(/\./g, '');

  if (value.search(/[^0-9]/g) === -1) {
    setBills((bills) => {
      const temp = [...bills];
      temp[index].price = value && parseInt(value);
      if (temp[index].price && temp[index].count)
        temp[index].total = temp[index].price * temp[index].count;

      return temp;
    });
  }
};

const handleFeeDeliveryChange = (e, setDiscount) => {
  const value = e.target.value.replace(/\./g, '');

  if (value.search(/[^0-9]/g) === -1) {
    setDiscount(value ? parseInt(value) : 0);
  }
};

export {
  handleProductChange,
  handleCountChange,
  handlePriceChange,
  handleFeeDeliveryChange,
};
