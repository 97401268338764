import React from 'react';
import clsx from 'clsx';

import styles from './ExpenseListTable.module.scss';
import * as event from './event';

function ExpenseListTable({
  newExpense,
  setNewExpense,
  expenses,
  getExpenses,
  setNotifyModal,
  setNotifyModalShow,
}) {
  return (
    <React.Fragment>
      <div className={styles.header}>
        <div className={styles.heading}>Khoản chi</div>
        <div className={styles.heading}>Sửa</div>
        <div className={styles.heading}>Xoá</div>
      </div>

      <div className={styles.content}>
        {expenses.map((expense) => (
          <div
            className={clsx(styles.row, {
              [styles.beingUpdated]: newExpense.id === expense.id,
            })}
            key={expense.id}
          >
            <div className={styles.cell}>{expense.name}</div>

            <div
              className={clsx(styles.cell, 'text-center', 'clickable')}
              onClick={() => event.handleUpdateClick(setNewExpense, expense)}
            >
              <i className={clsx('fas', 'fa-edit')} />
            </div>

            <div
              className={clsx(styles.cell, 'text-center', 'clickable')}
              onClick={() =>
                event.handleRemoveClick(
                  expense.id,
                  getExpenses,
                  setNotifyModal,
                  setNotifyModalShow,
                )
              }
            >
              <i className={clsx('fas', 'fa-trash')} />
            </div>
          </div>
        ))}
        {/* Illustration: Start */}

        {/* Illustration: End*/}
      </div>
    </React.Fragment>
  );
}

export default ExpenseListTable;
