import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import Province from '../province';

class Provinces extends BaseModel {
  static properties = {
    list: _p(Province, new Province()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: Province;
  */
  findProvince(slugAddress /*: string */) /*: Province */ {
    return this.list.find((province) => province.isInAddress(slugAddress));
  }
}

export default Provinces;
