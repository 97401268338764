/******************************************
 *  Author : Author
 *  Created On : Mon May 16 2022
 *  File : event.js
 *******************************************/

import unitOfWork from '@controllers';

const isSignedIn = (setUser) => {
    const controller = new AbortController();

    unitOfWork.auth.isSignedIn(controller.signal, setUser);

    return controller;
};

export { isSignedIn };
