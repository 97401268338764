import { statusService } from '@services';

class StatusController {
    getAll = async (cancelSignal, setStatuses) => {
        try {
            const result = await statusService.getAll(cancelSignal);

            if (!result.error) {
                setStatuses(result);
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Aborted!');
            }
        }
    };
}

export default StatusController;
