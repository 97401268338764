import { fetcher } from '../utilities';

const urlOrigin = '/api/auth';

const signIn = (username, password) => {
    return fetcher(`${urlOrigin}/sign-in`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username,
            password: password,
        }),
    });
};

const isSignedIn = async (cancelSignal, token) => {
    const response = await fetch(`${urlOrigin}/sign-in`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        signal: cancelSignal,
    });

    const data = await response.json();

    return data;
};

export { signIn, isSignedIn };
