import { useContext, useEffect, useState } from 'react';

import { UserContext } from '@contexts/App';
import {
    AccountForm,
    NotifyModal,
    ConfirmModal,
} from '@components';
import * as notifyType from '@components/NotifyModal/constant';
import styles from './UserAccount.module.scss';
import * as event from './event';
import { shopConfig } from '@/config/shop-config';

function UserAccount({ updateUser }) {
    const [user, setUser] = useState({
        ...useContext(UserContext),
        password: '',
        newPassword: '',
        rePassword: '',
    });

    // CONFIRM MODAL
    const [modal, setModal] = useState({
        actionTitle: null,
        actionConfirm: null,
        actionConduct: null,
    });
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Respond message
    const [respondMsg, setRespondMsg] = useState({
        success: '',
        error: '',
    });
    // NOTIFY MODAL
    const [resultModal, setResultModal] = useState({
        type: '',
        title: 'Cập nhật',
        content: null,
    });
    const [showResultModal, setShowResultModal] = useState(false);

    // Effect
    useEffect(() => {
        setResultModal({
            title: 'Cập nhật',
            type: respondMsg.error ? notifyType.ERROR : notifyType.SUCCESS,
            content: respondMsg.error || respondMsg.success,
        });
    }, [respondMsg]);

    // Event handler
    const showModal = (actionTitle, actionConfim, actionConduct) => {
        setShowConfirmModal(true);
        setModal({
            actionTitle: actionTitle,
            actionConfirm: actionConfim,
            actionConduct: actionConduct,
        });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.formWrapper}>
                    <header className={styles.formHeader}>
                        <img className={styles.logo} src={shopConfig.images.logo} alt="Logo" />
                        <h3 className={styles.heading}>Tài khoản của bạn</h3>
                    </header>

                    <AccountForm
                        submitForm={() =>
                            event.handleSubmitForm(
                                user,
                                setRespondMsg,
                                updateUser,
                                showModal,
                                setShowResultModal,
                            )
                        }
                        user={user}
                        setUser={setUser}
                    />
                </div>
            </div>

            <ConfirmModal
                modal={modal}
                show={showConfirmModal}
                closeModal={() => setShowConfirmModal(false)}
            />

            <NotifyModal
                modal={resultModal}
                show={showResultModal}
                closeModal={() => setShowResultModal(false)}
            />
        </div>
    );
}

export default UserAccount;
