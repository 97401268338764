import { TOAST_SEVERITY } from '@/config/constants';
import clsx from 'clsx';
import { confirmDialog } from 'primereact/confirmdialog';

const updateLoading = (state, action) => {
  const { isOpen = false, color = '#000' } = action.payload;

  state.ui.loading = {
    isOpen,
    color,
  };
};

const makeToast = (state, { payload }) => {
  state.ui.toast = payload;
};

const initializeUI = (state, actions) => {
  const { toastRef } = actions.payload;
  const originShow = toastRef.show;
  toastRef.show = ({ type, message, callback, ...options }) => {
    let summary = '';

    switch (type) {
      case TOAST_SEVERITY.ERROR:
        summary = 'Lỗi';
        break;
      case TOAST_SEVERITY.SUCCESS:
        summary = 'Thành công';
        break;
      case TOAST_SEVERITY.INFO:
        summary = 'Xác nhận';
        break;
      case TOAST_SEVERITY.WARN:
        summary = 'Cảnh báo';
        break;
      default:
        summary = '';
    }

    originShow({
      severity: type,
      summary,
      detail: message,
      ...options,
    });
    callback?.();
  };

  state.ui.toastRef = toastRef;
};

const toggleExportOrderDetailModal = (state, { payload }) => {
  state.ui.isExportOrderDetailModalOpen = payload.isOpen;
};

const toggleImportOrderDetailModal = (state, { payload }) => {
  state.ui.importOrderDetailModal = payload;
};

const toggleQtProductModal = (state, { payload }) => {
  state.ui.qtProductModal = payload;
};

const toggleSupplierModal = (state, { payload }) => {
  state.ui.supplierModal = payload;
};

const toggleError = (state, { payload }) => {
  const { isError, callback } = payload;
  state.ui.isError = isError;
  callback?.();
};

const confirmDanger = (state, { payload }) => {
  const {
    message,
    header = 'Xác nhận',
    onAccept,
    onReject,
    className,
  } = payload;
  confirmDialog({
    icon: 'pi pi-info-circle',
    acceptClassName: clsx('p-button-danger', { [className]: className }),
    message,
    header,
    accept: onAccept,
    reject: onReject,
  });
};

const confirmWarning = (state, { payload }) => {
  const {
    message,
    header = 'Xác nhận',
    onAccept,
    onReject,
    className,
  } = payload;
  confirmDialog({
    icon: 'pi pi-info-circle',
    acceptClassName: clsx('p-button-warning', { [className]: className }),
    message,
    header,
    accept: onAccept,
    reject: onReject,
  });
};

const confirmInfo = (state, { payload }) => {
  const {
    message,
    header = 'Xác nhận',
    onAccept,
    onReject,
    className,
  } = payload;
  confirmDialog({
    icon: 'pi pi-info-circle',
    acceptClassName: clsx('p-button-info', { [className]: className }),
    message,
    header,
    accept: onAccept,
    reject: onReject,
  });
};

export const actions = {
  updateLoading,
  makeToast,
  initializeUI,
  toggleImportOrderDetailModal,
  toggleExportOrderDetailModal,
  toggleQtProductModal,
  toggleSupplierModal,
  toggleError,
  confirmDanger,
  confirmWarning,
  confirmInfo,
};
