export { actions as reducers } from '../reducer/ui';

export const initialState = {
  loading: {
    isOpen: false,
    color: '#000',
  },
  toast: {
    isOpen: false,
    type: '',
    title: '',
    content: '',
  },
  redirect: '',
  isInitial: false,
  toastRef: null,
  isExportOrderDetailModalOpen: false,
  isError: false,
  qtProductModal: {
    isOpen: false,
    isCreate: false,
  },
  supplierModal: {
    isOpen: false,
    isCreate: false,
  },
  importOrderDetailModal: {
    isOpen: false,
    isCreate: false,
  },
};
