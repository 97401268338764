import { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const Input = memo(
  ({ name, placeholder, className, value, onChange, disabled }) => {
    const optionalProps = {};
    if (value || value === null) optionalProps.value = value ?? '';

    return (
      <input
        className={clsx(styles.input, { [className]: className })}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        {...optionalProps}
      />
    );
  },
);
