export const COMMON = {
  EXPORT_ORDER_LIMIT: 12,
};

export const SHIP_SERVICE_IDS = {
  SHOPEE: 6,
  BY_CUSTOMER: 5,
};

export const ECOMMERCE_IDS = {
  SHOPEE: 7,
  BY_CUSTOMER: 6,
};

export const PRICE_TYPES = {
  RETAIL: 1,
  WHOLE_SALE: 2,
  IMPORT: 3,
};

export const PRICE_TYPE_KEYS = {
  1: 'price',
  2: 'wholeSalePrice',
  3: 'importPrice',
};

export const PRICE_TYPE_NAMES = {
  1: 'giá bán lẻ',
  2: 'giá bán sỉ',
  3: 'giá nhập',
};

export const ORDER_STATUS = {
  WAITING: 1,
  COMPLETE: 2,
};

export const ORDER_STATUS_NAMES = {
  [ORDER_STATUS.WAITING]: 'Chờ gia công',
  [ORDER_STATUS.COMPLETE]: 'Thành công',
};

export const EXPORT_ORDER_STATUSES = {
  COMPLETE: 1,
  CANCEL: 99,
};
export const EXPORT_ORDER_STATUS_NAMES = {
  1: 'đã lấy hàng',
  99: 'đã hủy',
};

export const IMPORT_ORDER_STATUSES = {
  COMPLETE: 1,
  CANCEL: 99,
};
export const IMPORT_ORDER_STATUS_NAMES = {
  1: 'đã lấy hàng',
  99: 'đã hủy',
};

export const TOAST_SEVERITY = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
};

export const ROLE_ID = {
  MANAGER: 1,
  STAFF: 2,
  MARKETTER: 3,
  ADMIN: 4,
};

export const UI = {
  SORT_ORDER: {
    NO_SORT: 1,
    ASC: 2,
    DESC: 3,
  },
};
