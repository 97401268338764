import { bindActionCreators } from '@reduxjs/toolkit';
import { actions } from '../slice';

/**
 * @typedef Actions
 * @type {object}
 * @property {function} updateOrderFilter
 */

// eslint-disable-next-line import/no-anonymous-default-export
/**
 * @returns {{actions: Actions}}
 */
export default (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});
