import Auth from './auth.controller';
import User from './user.controller';
import Role from './role.controller';
import Product from './product.controller';
import ShipService from './ship-service.controller';
import Ecommerce from './ecommerce.controller';
import Site from './site.controller';
import Status from './status.controller';
import Bill from './bill.controller';
import Note from './note.controller';
import Statistic from './statistic.controller';
import Market from './market.controller';
import StoreProduct from './store-product.controller';
import Supplier from './supplier.controller';
import ImportStatus from './import-status.controller';
import ImportBill from './import-bill.controller';
import ExportBill from './export-bill.controller';
import ExportStatus from './export-status.controller';
import PriceType from './price-type.controller';

class UnitOfWork {
  auth = new Auth();
  user = new User();
  role = new Role();
  product = new Product();
  shipService = new ShipService();
  ecommerce = new Ecommerce();
  status = new Status();
  bill = new Bill();
  note = new Note();
  statistic = new Statistic();
  site = new Site();
  market = new Market();
  storeProduct = new StoreProduct();
  supplier = new Supplier();
  importStatus = new ImportStatus();
  importBill = new ImportBill();
  exportBill = new ExportBill();
  exportStatus = new ExportStatus();
  priceType = new PriceType();
}

export default new UnitOfWork();
