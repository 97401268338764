import clsx from 'clsx';
import styles from './SideBar.module.scss';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { useEffect, useRef, useState } from 'react';

import * as event from './event';
import * as eventBill from '@screens/staff/Bill/event';
import * as defaultBillValue from '@screens/staff/Bill/defaultValue';
import { removeError, validateBill } from '@screens/staff/Bill/validate';

function SideBar({
  show = false,
  bill = null,
  closeSideBar,
  shipServices = [],
  handleSideBarUpdateClick,
  handleChangeStatusClick,
  ecommerces,
  products,
}) {
  const [billTemp, setBillTemp] = useState();
  const [error, setError] = useState(defaultBillValue.error);
  const [img, setImg] = useState(' ');
  const [imgChange, setImgChange] = useState(false);
  const [phone, setPhone] = useState();

  const previewImg = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    document.querySelectorAll(`.${styles.error}`).forEach((node) => {
      node.querySelector('input').onfocus = () => {
        removeError(setError, node.id);
      };
    });
  }, [error]);

  // Paste handling
  useEffect(() => {
    wrapperRef.current.onpaste = (e) => {
      eventBill.handleImagePaste(e, previewImg, setImg, setError);
      setImgChange(true);
    };
  }, []);

  useEffect(() => {
    if (bill) {
      setImgChange(false);
      if (previewImg?.current) previewImg.current.src = bill.imgURL;
      setBillTemp({
        ...bill,
        phones: bill.phones.map((phone) => phone.phone),
      });
    }
  }, [bill]);

  const handleUpdateClick = () => {
    if (validateBill(billTemp, setError, img)) {
      handleSideBarUpdateClick(billTemp, imgChange ? img : '', setBillTemp);
    }
  };

  return (
    <div
      className={clsx(styles.sideBarContainer, {
        [styles.hidden]: !show,
      })}
      ref={wrapperRef}
    >
      <i
        className={clsx('fa-solid fa-xmark', styles.closeBtn)}
        onClick={closeSideBar}
      ></i>
      {billTemp && (
        <div className={styles.container}>
          {/* ID */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Mã đơn:</label>
            <h3 className={styles.content}>{billTemp.id}</h3>
          </div>

          {/* Updated Date */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Ngày cập nhật:</label>
            <h3 className={styles.content}>
              {`${new Date(billTemp.updatedAt).toLocaleDateString(
                'vi-VN',
              )}                             
                            - ${new Date(billTemp.updatedAt).toLocaleTimeString(
                              'vi-VN',
                            )}`}
            </h3>
          </div>

          {/* Created Date */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Người tạo:</label>
            <h3 className={styles.content}>
              {`${billTemp.employee.displayName} (${billTemp.employee.username})`}
            </h3>
          </div>

          {/* Customer Name */}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.name,
            })}
            error={error.name}
            id="name"
          >
            <label className={styles.labelContent}>Tên:</label>
            <input
              value={billTemp.name}
              className={styles.inputContent}
              onChange={(e) => eventBill.handleNameChange(e, setBillTemp)}
            />
          </div>

          {/* Customer Phone */}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.phone,
            })}
            error={error.phone}
            id="phone"
          >
            <label className={styles.labelContent}>SĐT:</label>
            <input
              value={phone}
              className={styles.inputContent}
              onChange={(e) =>
                eventBill.handlePhoneInputChange(
                  e,
                  setPhone,
                  setBillTemp,
                  setError,
                )
              }
            />
          </div>

          <div className={styles.phoneList}>
            {billTemp.phones.map((phone, index) => (
              <div className={styles.phoneWrapper} key={index}>
                <div className={styles.phoneBox}>{phone}</div>
                <i
                  className={clsx('fas', 'fa-multiply', styles.phoneRemove)}
                  onClick={(e) =>
                    eventBill.handlePhoneRemoveClick(e, setBillTemp)
                  }
                  id={index}
                />
              </div>
            ))}
          </div>

          {/* Address */}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.address,
            })}
            error={error.address}
            id="address"
          >
            <label className={styles.labelContent}>Địa chỉ:</label>
            <input
              value={billTemp.address}
              className={styles.inputContent}
              onChange={(e) => eventBill.handleAddressChange(e, setBillTemp)}
            />
          </div>

          {/* Price */}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.price,
            })}
            error={error.price}
            id="price"
          >
            <label className={styles.labelContent}>Tiền gốc:</label>
            <input
              value={billTemp.price.toLocaleString('vi-VN')}
              onChange={(e) =>
                eventBill.handlePriceChange(
                  e,
                  setBillTemp,
                  billTemp.paid,
                  setError,
                )
              }
              className={styles.inputContent}
            />
          </div>

          {/* Paid*/}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.paid,
            })}
            error={error.paid}
            id="paid"
          >
            <label className={styles.labelContent}>Khách đã trả:</label>
            <input
              value={billTemp.paid.toLocaleString('vi-VN')}
              onChange={(e) =>
                eventBill.handlePaidChange(
                  e,
                  setBillTemp,
                  billTemp.price,
                  setError,
                )
              }
              className={styles.inputContent}
            />
          </div>

          {/* Product */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Sản phẩm:</label>
            <select
              value={billTemp.product.id}
              onChange={(e) =>
                eventBill.handleProductChange(e, setBillTemp, products)
              }
              className={styles.selectContent}
            >
              {products.map((product) => (
                <option value={product.id} key={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>

          {/* Ship Service */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Đơn vị vận chuyển:</label>
            <select
              value={billTemp.shipServiceID}
              onChange={(e) => event.handleShipServiceChange(e, setBillTemp)}
              className={styles.selectContent}
            >
              {shipServices.map((shipService) => (
                <option value={shipService.id} key={shipService.id}>
                  {shipService.name}
                </option>
              ))}
            </select>
          </div>

          {/* Ecommerce Name */}
          <div
            className={clsx(styles.wrapperContent, {
              [styles.error]: error.ecommerceName,
            })}
            error={error.ecommerceName}
            id="ecommerceName"
          >
            <label className={styles.labelContent}>Tên trang TMĐT:</label>
            <input
              value={billTemp.ecommerceName}
              onChange={(e) =>
                eventBill.handleEcommerceNameChange(e, setBillTemp)
              }
              className={styles.inputContent}
            />
          </div>

          {/* Trang TMDT */}
          <div className={styles.wrapperContent}>
            <label className={styles.labelContent}>Trang TMĐT:</label>
            <select
              value={billTemp.ecommerceID}
              onChange={(e) => event.handleEcommerceChange(e, setBillTemp)}
              className={styles.selectContent}
            >
              {ecommerces.map((ecommerce) => (
                <option value={ecommerce.id} key={ecommerce.id}>
                  {ecommerce.name}
                </option>
              ))}
            </select>
          </div>

          {/* Ecommerce Name */}
          <div className={clsx(styles.wrapperContent)} id="note">
            <label className={styles.labelContent}>Ghi chú</label>
            <input
              value={billTemp.note}
              onChange={(e) => eventBill.handleNoteChange(e, setBillTemp)}
              className={styles.inputContent}
            />
          </div>

          <div className={styles.wrapperContent}>
            <img
              src={billTemp.imgURL}
              alt="Hình ảnh"
              className={styles.img}
              ref={previewImg}
            />
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <button className={styles.btn} onClick={handleUpdateClick}>
          Thay đổi
        </button>

        <button
          className={clsx(styles.btn)}
          onClick={() => handleChangeStatusClick(billTemp, setBillTemp)}
        >
          {billTemp && billTemp.statusID === 1 ? 'Thành công' : 'Chờ gia công'}
        </button>
      </div>
    </div>
  );
}

export default SideBar;
