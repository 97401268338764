import BaseModel from '../..';

class Role extends BaseModel {
  static properties = {
    id: null,
    type: null,
    name: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  type: string | null;
  name: string | null;
  */
}

export default Role;
