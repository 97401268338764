import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import Notes from '../notes';

class NoteGroup extends BaseModel {
  static properties = {
    id: null,
    name: '',
    notes: _p(Notes, new Notes()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  notes: Note[];
  */
}

export default NoteGroup;
