import * as constant from './constant';

const expense = (expense) =>
  expense && expense > 0 ? '' : constant.EMPTY_EXPENSE;

const price = (value) =>
  value.search(/[^0-9]/g) > -1
    ? constant.ONLY_NUMBER
    : value.length > 11
    ? constant.OUT_OF_RANGE
    : value === '0'
    ? constant.LARGER_THAN_0
    : '';

const validateExpense = (value) => {
  const err = expense(value);
  if (err) {
    return err;
  }
  return null;
};

const validatePrice = (value) => {
  const err = price(value);
  if (err) {
    if (err === constant.LARGER_THAN_0) return null;
    return err;
  }
  return null;
};

export { validateExpense, validatePrice };
