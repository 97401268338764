import { fetcher } from '../utilities';

const url = '/api/expense';

const getAll = () => {
    return fetcher(`${url}/list`);
};

const create = (expense) => {
    return fetcher(`${url}/admin/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: expense }),
    });
};

const remove = (id) => {
    return fetcher(`${url}/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id }),
    });
};

const update = (expense) => {
    return fetcher(`${url}/update`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(expense),
    });
};

export { getAll, create, remove, update };
