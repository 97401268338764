import clsx from 'clsx';
import { memo, useRef, useState } from 'react';
import { useEventListener } from '@/hooks/ui';

import styles from './style.module.scss';

/**
 * @typedef CheckedInfo
 * @property {boolean} isCheckedAll
 * @property {boolean} isBillSelected
 * @property {string[]} checkedIds
 * @property {string} checkedCount
 */

/**
 *
 * @param {{
 *   checkedInfo: CheckedInfo;
 *   handlePrint: () => void;
 *   handleExportExcel: () => void;
 *   handleRetailPrint: () => void;
 *   handleDeleteBills: () => void;
 *   handleShipServiceInfoChange: (file: File) => void;
 * }} param0
 * @returns
 */
const ActionList = ({
  checkedInfo,
  handlePrint,
  handleExportExcel,
  handleRetailPrint,
  handleDeleteBills,
  handleShipServiceInfoChange,
}) => {
  const [actionHide, setActionHide] = useState(true);
  const shipServiceInfoFile = useRef(null);

  const toggleActionHide = (e) => {
    e.stopPropagation();
    setActionHide((prev) => !prev);
  };
  const hideActionList = () => {
    setActionHide(true);
  };

  const onPrintClick = () => {
    hideActionList();
    handlePrint();
  };

  const onDeleteClick = () => {
    hideActionList();
    handleDeleteBills();
  };

  const onShipServiceInfoChanged = async (e) => {
    const [file] = e.target.files;
    if (!file) return;

    hideActionList();
    await handleShipServiceInfoChange(file);
    shipServiceInfoFile.current.value = '';
  };

  useEventListener({
    event: 'click',
    callback: () => {
      setActionHide(true);
    },
  });

  return (
    <div className={styles.action}>
      <div className={styles.toggleButtonBar} onClick={toggleActionHide}>
        <i className={clsx(styles.iconMenu, 'fas fa-list')} />
        Hành động
      </div>

      <div
        className={clsx(styles.actionBtnWrapper, {
          [styles.actionBtnHide]: actionHide,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={clsx(styles.actionBtn, {
            [styles.inActive]: !checkedInfo.isBillSelected,
          })}
          onClick={onPrintClick}
          disabled={checkedInfo.isBillSelected ? '' : 'disabled'}
        >
          In Đơn
        </button>

        <button
          className={clsx(styles.actionBtn, {
            [styles.inActive]: !checkedInfo.isBillSelected,
          })}
          onClick={handleExportExcel}
          disabled={!checkedInfo.isBillSelected}
        >
          Xuất Excel
        </button>

        <button
          className={clsx(styles.actionBtn, {
            [styles.inActive]: !checkedInfo.isBillSelected,
          })}
          onClick={handleRetailPrint}
          disabled={!checkedInfo.isBillSelected}
        >
          In đơn bán lẻ
        </button>

        <label htmlFor="uploadExcel" className={clsx(styles.actionBtn)}>
          Cập nhật ĐVVC
        </label>
        <input
          id="uploadExcel"
          type="file"
          style={{
            display: 'none',
          }}
          onChange={onShipServiceInfoChanged}
          ref={shipServiceInfoFile}
        />

        <button
          className={clsx(styles.actionBtn, {
            [styles.inActive]: !checkedInfo.isBillSelected,
          })}
          onClick={onDeleteClick}
          disabled={!checkedInfo.isBillSelected}
        >
          Xóa đơn
        </button>
      </div>
    </div>
  );
};

export default memo(ActionList);
