import React from 'react';
import DatePicker from 'react-datepicker';

import styles from './CustomMonthPicker.module.scss';

function CustomMonthPicker({ label, month, setMonth, checkValid, className }) {
  const handleSelectedChange = React.useCallback((date) => {
    if (checkValid(date)) {
      setMonth(date);
    }
  }, []);

  return (
    <div className={`${styles.picker} ${className}`}>
      <label id="report-month-picker" className={styles.pickerLabel}>
        {label}
      </label>

      <DatePicker
        id="report-month-picker"
        className={styles.pickerMonth}
        showMonthYearPicker
        selected={month}
        dateFormat="MM/yyyy"
        onChange={handleSelectedChange}
      />
    </div>
  );
}

export default CustomMonthPicker;
