import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { throttle, pickBy, orderBy } from 'lodash';

import * as event from './event';

import { BillTable, NotifyModal, ConfirmModal, SideBar } from '@components';
import { useEventListener } from '@hooks/ui';
import styles from './ListBill.module.scss';
import * as notifyConstant from '@components/NotifyModal/constant';
import createActions from '@/application/actions';
import { FilterHeader } from '../FilterHeader/v0/Header';
import { ActionList, CheckedAll, SearchBar } from '../ActionBar';
import { BaseLayout } from '@/components/templates';

/**
 * @typedef IFilterCondition
 * @property {number} status
 */

export const SEARCH_TYPES = ['Mã đơn', 'Tên khách', 'SĐT', 'Trang TMĐT'];
export const SEARCH_TYPE_KEYS = {
  BILL_ID: 0,
  CUSTOMER_NAME: 1,
  CUSTOMER_PHONE: 2,
  ECOMMERCE: 3,
};

function ListBill(props) {
  const {
    state: {
      masterData: { ecommerces, shipServices, categories },
    },
    actions: { updateBill, updateBillStatus, updateOrderFilter },
  } = props;
  const dispatch = useDispatch();

  // First fetch
  const [checked, setChecked] = useState({});
  const [mousePressed, setMousePressed] = useState(false);

  const [order, setOrder] = useState({
    id: '',
    createdAt: 'DESC',
    name: '',
    price: '',
  });

  const [modalShow, setModalShow] = useState(false);
  const [modal, setModal] = useState({
    actionTitle: null,
    actionConfirm: null,
    actionConduct: null,
  });
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorModal, setErrorModal] = useState({
    type: notifyConstant.ERROR,
    title: '',
    content: '',
  });

  // Event handler state
  const billTableRef = useRef();

  const [page, setPage] = useState(1);
  const [stopScrollEvent, setStopScrollEvent] = useState(true);
  const [bills, setBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState({
    bill: null,
    sideBarShown: false,
  });
  const [totalBillCount, setTotalBillCount] = useState(0);

  const [searchTarget, setSearchTarget] = useState({
    searchText: '',
    searchType: SEARCH_TYPE_KEYS.CUSTOMER_PHONE,
  });

  const [filterCondition, setFilterCondition] = useState({
    status: 0,
    productId: 0,
    shipService: 0,
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });

  const [error, setError] = useState({
    unSelectedBill: '',
  });

  const [shipServiceInfo, setShipServiceInfo] = useState('');
  const shipServiceInfoFile = useRef(null);

  const checkedInfo = useMemo(() => {
    const keysToCheck = Object.keys(checked);
    const checkedValues = keysToCheck.filter((key) => checked[key]);

    return {
      isCheckedAll: keysToCheck.length === checkedValues.length,
      isBillSelected: checkedValues.length > 0,
      checkedIds: checkedValues,
      checkedCount: checkedValues.length,
    };
  }, [checked]);

  const masterData = useMemo(
    () => ({
      products: categories.list,
      shipServices: shipServices.list,
    }),
    [categories, shipServices],
  );
  const paginationInfo = useMemo(
    () => ({
      currentBillCount: bills.length,
      totalBillCount,
    }),
    [bills, totalBillCount],
  );

  const fetchBills = () =>
    event.fetchBills(
      page,
      filterCondition.startDate,
      filterCondition.endDate,
      filterCondition.shipService,
      filterCondition.status,
      filterCondition.productId,
      order,
      bills,
      setBills,
      setTotalBillCount,
      setStopScrollEvent,
    );

  const onUpdateBill = (bill) => {
    updateBill({
      bill,
      onSuccess: () => {
        fetchBills();
        event.closeSideBar(setSelectedBill);
        event.showErrorModal(
          'Cập nhật đơn',
          'Cập nhật đơn thành công',
          setErrorModalShow,
          setErrorModal,
          2,
        );
      },
      onError: (msg) => {
        event.closeSideBar(setSelectedBill);
        event.showErrorModal(
          'Cập nhật đơn',
          msg || 'Lỗi hệ thống',
          setErrorModalShow,
          setErrorModal,
        );
      },
    });
  };

  const onChangeStatus = (bill) => {
    updateBillStatus({
      bill,
      onSuccess: () => {
        fetchBills();
        event.showErrorModal(
          'Cập nhật đơn',
          'Cập nhật đơn thành công',
          setErrorModalShow,
          setErrorModal,
          2,
        );
      },
      onError: (msg) =>
        event.showErrorModal(
          'Cập nhật đơn',
          msg || 'Lỗi hệ thống',
          setErrorModalShow,
          setErrorModal,
        ),
    });
  };

  const handleFilterConditionChange = (name, value) => {
    switch (name) {
      case 'shipService':
        dispatch(
          updateOrderFilter({
            shipServiceID: +value,
          }),
        );
        break;
      default:
        break;
    }
    setFilterCondition((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  };

  const handleSearchTargetChanged = (e) => {
    setSearchTarget((prevSearchTarget) => {
      const { name, value } = e.target;
      if (prevSearchTarget[name] && !value) {
        fetchBills();
      }
      return {
        ...prevSearchTarget,
        [name]: value,
      };
    });
  };
  const handleSearch = () => {
    event.searchBills(
      setBills,
      searchTarget.searchText,
      searchTarget.searchType,
      order,
    );
  };

  const handlePrint = useCallback(() => {
    const actionTitle = 'In';
    const actionConfirm =
      `Bạn có chắc muốn in ${checkedInfo.checkedIds.length} đơn này không?` +
      `Chú ý: Sau khi in đơn sẽ trở thành THÀNH CÔNG`;
    const actionConduct = () =>
      event.printBill(checkedInfo.checkedIds, bills, setError, fetchBills);
    event.showModal(
      { actionTitle, actionConfirm, actionConduct },
      setModal,
      setModalShow,
    );
  }, [checkedInfo.checkedIds, bills]);

  const handleExportExcelClick = useCallback(() => {
    event.exportExcel(checkedInfo.checkedIds);
  }, [checkedInfo.checkedIds]);

  const handleRetailPrint = useCallback(() => {
    event.handleRetailPrint(checkedInfo.checkedIds, setError);
  }, [checkedInfo.checkedIds]);

  const handleShipServiceInfoChange = useCallback((file) => {
    const actionTitle = 'Cập nhật';
    const actionConfirm =
      `Mã đơn sẽ được cập nhật dựa trên tệp ${file.name}. ` +
      `Bạn có chắc chắn không?`;
    const actionConduct = () => {
      event.updateShipService(file, setErrorModalShow, setErrorModal);
    };
    const actionCancel = () => {};

    event.showModal(
      {
        actionTitle,
        actionConfirm,
        actionConduct,
        actionCancel,
      },
      setModal,
      setModalShow,
    );
  }, []);

  const handleDeleteBills = useCallback(() => {
    const actionTitle = 'Xóa';
    const actionConfirm = `Bạn có chắc muốn xoá ${checkedInfo.checkedIds.length} đơn không?`;
    const actionConduct = () => {
      event.deleteBill(
        checkedInfo.checkedIds,
        setError,
        fetchBills,
        setErrorModalShow,
        setErrorModal,
      );
    };
    event.showModal(
      { actionTitle, actionConfirm, actionConduct },
      setModal,
      setModalShow,
    );
  });

  useEventListener({
    event: 'mouseup',
    callback: () => {
      setMousePressed(false);
    },
  });

  useEffect(() => {
    // Initial checked list after fetch bills
    setChecked((previousChecked) =>
      bills.reduce((prev, { id: billId }) => {
        prev[billId] = previousChecked[billId] || false;
        return prev;
      }, {}),
    );
  }, [bills]);

  useEffect(() => {
    if (checkedInfo.isBillSelected) {
      setError((err) => ({
        ...err,
        unSelectedBill: '',
      }));
    }
  }, [checkedInfo.isBillSelected]);

  /**
   * Event listener for key up
   * Ctrl + Alt + P: Print bill
   * Ctrl + Alt + E: Export excel
   */
  useEventListener(
    {
      event: 'keyup',
      callback: (e) => {
        if (e.ctrlKey && e.altKey) {
          switch (e.key) {
            case 'p':
              handlePrint(
                checked,
                bills,
                setError,
                setModal,
                setModalShow,
                fetchBills,
              );
              break;
            case 'e':
              event.exportExcel(checked);
              break;
            default:
              break;
          }
        }
      },
    },
    [checked, bills],
  );

  useEventListener(
    {
      target: billTableRef.current,
      event: 'scroll',
      callback: throttle((e) => {
        const maxDistanceFromBot = e.target.offsetHeight + 500;
        if (
          e.target.scrollHeight - e.target.scrollTop <= maxDistanceFromBot &&
          !stopScrollEvent
        ) {
          setStopScrollEvent(true);
          setPage((page) => page + 1);
        }
      }, 500),
    },
    [stopScrollEvent],
  );

  useEffect(() => {
    setPage((page) => {
      if (page === 1) {
        fetchBills();
      }

      return 1;
    });
  }, [filterCondition]);

  useEffect(() => {
    if (page > 1) {
      const cancelController = fetchBills();

      return () => {
        if (cancelController) {
          cancelController.abort();
        }
      };
    }
  }, [page]);

  useEffect(() => {
    setBills((bills) => {
      const orderSort = pickBy(order, (value) => value);
      const argsOrder = [[], []];
      Object.keys(orderSort).forEach((key) => {
        argsOrder[0].push(key);
        argsOrder[1].push(orderSort[key].toLowerCase());
      });
      return orderBy(bills, argsOrder[0], argsOrder[1]);
    });
  }, [order]);

  useEffect(() => {
    if (shipServiceInfo) {
      const actionTitle = 'Cập nhật';
      const actionConfirm =
        `Mã đơn sẽ được cập nhật dựa trên tệp ${shipServiceInfo.name}. ` +
        `Bạn có chắc chắn không?`;
      const actionConduct = () =>
        event.updateShipService(
          shipServiceInfo,
          setShipServiceInfo,
          setErrorModalShow,
          setErrorModal,
          shipServiceInfoFile,
        );
      const actionCancel = () => {
        setShipServiceInfo('');
        shipServiceInfoFile.current.value = '';
      };
      event.showModal(
        {
          actionTitle,
          actionConfirm,
          actionConduct,
          actionCancel,
        },
        setModal,
        setModalShow,
      );
    }
  }, [shipServiceInfo]);

  return (
    <BaseLayout
      className={clsx(styles.wrapper, {
        [styles.sideBarShown]: selectedBill.sideBarShown,
      })}
    >
      <div className={styles.mainContent}>
        <div className={clsx(styles.tableWrapper)}>
          <FilterHeader
            filterCondition={filterCondition}
            onChange={handleFilterConditionChange}
            masterData={masterData}
            paginationInfo={paginationInfo}
          />

          <div className={clsx(styles.actionBar)}>
            <CheckedAll
              checkedInfo={checkedInfo}
              handleCheckedAll={() =>
                event.handleCheckedAll(
                  checkedInfo.isCheckedAll,
                  setChecked,
                  paginationInfo.currentBillCount,
                  totalBillCount,
                  filterCondition.startDate,
                  filterCondition.endDate,
                  filterCondition.shipService,
                  filterCondition.status,
                  filterCondition.productId,
                  order,
                  setBills,
                )
              }
            />

            <SearchBar
              searchTarget={searchTarget}
              handleSearch={handleSearch}
              handleSearchTargetChanged={handleSearchTargetChanged}
            />

            {/* Action Button: Start */}
            <ActionList
              checkedInfo={checkedInfo}
              handlePrint={handlePrint}
              handleExportExcel={handleExportExcelClick}
              handleRetailPrint={handleRetailPrint}
              handleDeleteBills={handleDeleteBills}
              handleShipServiceInfoChange={handleShipServiceInfoChange}
            />
            {/* Action Button: End*/}
          </div>

          <BillTable
            bills={bills}
            checked={checked}
            handleEditClick={(bill) =>
              event.handleEditClick(bill, setSelectedBill)
            }
            handleSelectMouseDown={(sid) =>
              event.handleSelectMouseDown(sid, setChecked)
            }
            handleSelectMouseEnter={(e) =>
              event.handleSelectMouseEnter(e, mousePressed, setChecked)
            }
            handleSortOrderChange={(col) =>
              event.handleSortOrderChange(col, setOrder)
            }
            mousePressed={mousePressed}
            setMousePressed={setMousePressed}
            error={error}
            order={order}
            ref={billTableRef}
          />
        </div>
      </div>

      <ConfirmModal
        modal={modal}
        show={modalShow}
        closeModal={() => setModalShow(false)}
      />

      <NotifyModal
        modal={errorModal}
        show={errorModalShow}
        closeModal={() => setErrorModalShow(false)}
      />

      <SideBar
        show={selectedBill.sideBarShown}
        bill={selectedBill.bill}
        closeSideBar={() => event.closeSideBar(setSelectedBill)}
        shipServices={shipServices.list}
        showModal={event.showModal}
        handleSideBarUpdateClick={(billTemp, img) =>
          event.handleSideBarUpdateClick(
            { setModal, setModalShow },
            { actionConduct: () => onUpdateBill({ ...billTemp, img }) },
          )
        }
        handleChangeStatusClick={(bill, setBillTemp) =>
          event.handleChangeStatusClick(
            bill,
            setModal,
            setModalShow,
            fetchBills,
            setBillTemp,
            { actionConduct: () => onChangeStatus(bill) },
          )
        }
        ecommerces={ecommerces.list}
        products={categories.list}
      />
    </BaseLayout>
  );
}

const mapStateToProps = ({ state }) => ({ state });
const mapDispatchToProps = createActions;

export default connect(mapStateToProps, mapDispatchToProps)(ListBill);
