import * as validate from './validate';

const removeError = (type, setError) => {
    setError((error) => ({
        ...error,
        [type]: '',
    }));
};

const setUserField = (field, value, setUser) =>
    setUser((user) => ({ ...user, [field]: value }));

const handleEmailChange = (e, setUser) => {
    setUserField('email', e.target.value, setUser);
};

const handleUsernameChange = (e, setUser, setError) => {
    removeError('username', setError);
    setUserField('username', e.target.value, setUser);
};

const handleUsernameBlur = (user, setError) => {
    validate.checkUsername(user, setError);
};

const handleNameChange = (e, setUser, setError) => {
    removeError('name', setError);
    setUserField('name', e.target.value, setUser);
};

const handleNameBlur = (user, setError) => {
    validate.checkName(user, setError);
};

const handlePasswordChange = (e, setUser, setError) => {
    removeError('password', setError);
    setUserField('password', e.target.value, setUser);
};

const handlePasswordBlur = (user, setError) => {
    validate.checkPassword(user, setError);
};

const handleNewPasswordChange = (e, setUser, setError) => {
    removeError('newPassword', setError);
    setUserField('newPassword', e.target.value, setUser);
};

const handleNewPasswordBlur = (user, setError) => {
    validate.checkNewPassword(user, setError);
    if (user.rePassword) {
        validate.checkMatchPassword(user, setError);
    }
};

const handleRepasswordChange = (e, setUser, setError) => {
    removeError('rePassword', setError);
    setUserField('rePassword', e.target.value, setUser);
};

const handleRepasswordBlur = (user, setError) => {
    validate.checkMatchPassword(user, setError);
};

const handleFormSubmit = (e, user, setError, submitForm) => {
    e.preventDefault();
    if (validate.checkForm(user, setError)) {
        submitForm();
    }
};

export {
    handleEmailChange,
    handleUsernameChange,
    handleUsernameBlur,
    handleNameChange,
    handleNameBlur,
    handlePasswordChange,
    handlePasswordBlur,
    handleNewPasswordChange,
    handleNewPasswordBlur,
    handleRepasswordChange,
    handleRepasswordBlur,
    handleFormSubmit,
};
