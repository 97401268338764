import { memo } from 'react';
import styles from './style.module.scss';

/**
 *
 * @param {{ currentCount: number; expenseCount: number; totalExpense: number; }} param0
 * @returns {JSX.Element}
 */
const ExpenseSummary = ({ currentCount, expenseCount, totalExpense }) => {
  return (
    <div className={styles.statistic}>
      <div className={styles.statisticContent}>
        <span className={styles.statisticLabel}>Số khoản chi:</span>
        <span className={styles.statisticValue}>{`${currentCount} / ${expenseCount}`}</span>
      </div>

      <div className={styles.statisticContent}>
        <span className={styles.statisticLabel}>Tổng chi:</span>
        <span className={styles.statisticValue}>
          {totalExpense.toLocaleString('vi-VN', {
            style: 'currency',
            currency: 'VND',
          })}
        </span>
      </div>
    </div>
  );
};

export default memo(ExpenseSummary);
