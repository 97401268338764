import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '..';
import StockFilter from './stock-filter';
import ExportOrders from './export-orders';
import ExportOrder from './export-order';
import ImportOrders from './import-orders';
import ImportOrder from './import-order';
import QtProducts from './qt-products';
import QtProduct from './qt-product';
import Suppliers from './suppliers';
import Supplier from './supplier';
import Report from './report';

class Stock extends BaseModel {
  static properties = {
    qtProducts: _p(QtProducts, new QtProducts()),
    qtProduct: _p(QtProduct, new QtProduct()),
    exportOrders: _p(ExportOrders, new ExportOrders()),
    exportOrder: _p(ExportOrder, new ExportOrder()),
    importOrders: _p(ImportOrders, new ImportOrders()),
    importOrder: _p(ImportOrder, new ImportOrder()),
    suppliers: _p(Suppliers, new Suppliers()),
    supplier: _p(Supplier, new Supplier()),
    filter: _p(StockFilter, new StockFilter()),
    report: _p(Report, new Report()),
  };
}

export default Stock;
