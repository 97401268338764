import { useMemo } from 'react';

function useCancellablePromise(callback, dependencies) {
    return useMemo(() => {
        const cancelController = new AbortController();
        const signal = cancelController.signal;

        return {
            cancel: () => cancelController.abort(),
            invoke: callback(signal),
        };
    }, dependencies);
}

export default useCancellablePromise;
