import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  Modal,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getEndDate, getStartDate } from './defaultValue';
import { START_APP_YEAR } from './const';

const DaySelectorModal = ({ target, open, onSave, onClose }) => {
  const [selectedYear, setSelectedYear] = useState(START_APP_YEAR);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { years, months } = useMemo(
    () => ({
      years: Array.from({ length: moment().year() - START_APP_YEAR }).map(
        (_, index) => START_APP_YEAR + index,
      ),
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
        (month) => `Tháng ${month}`,
      ),
    }),
    [],
  );

  const handleSubmit = () => {
    if (target === 'year') {
      const startDate = new Date(selectedYear, 0, 1);
      const endDate = new Date(selectedYear, 11, 31);
      onSave(getStartDate(startDate), getEndDate(endDate));
    }

    if (target === 'month') {
      const startDate = new Date(selectedYear, selectedMonth - 1, 1);
      const endDate = new Date(selectedYear, selectedMonth, 0);
      onSave(getStartDate(startDate), getEndDate(endDate));
    }

    if (target === 'day') {
      onSave(getStartDate(selectedDate), getEndDate(selectedDate));
    }

    if (target === 'special') {
      onSave(getStartDate(fromDate), getEndDate(toDate));
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {target === 'year' && (
          <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              label="Year"
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {target === 'month' && (
          <Box display="flex" gap={2}>
            <FormControl
              fullWidth
              sx={{
                '& legend': {
                  fontSize: '1.2rem',
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: '1.6rem',
                }}
              >
                Month
              </InputLabel>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                label="Month"
                sx={{
                  fontSize: '1.6rem',
                }}
              >
                {months.map((month, index) => (
                  <MenuItem
                    key={index}
                    value={index + 1}
                    sx={{
                      fontSize: '1.6rem',
                    }}
                  >
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              sx={{
                '& legend': {
                  fontSize: '1.2rem',
                },
              }}
            >
              <InputLabel
                sx={{
                  fontSize: '1.6rem',
                }}
              >
                Year
              </InputLabel>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                label="Year"
                sx={{
                  fontSize: '1.6rem',
                }}
              >
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    value={year}
                    sx={{
                      fontSize: '1.6rem',
                    }}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {target === 'day' && (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            inline
          />
        )}

        {target === 'special' && (
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              fontSize: '1.6rem',
            }}
          >
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              customInput={
                <TextField
                  label="From"
                  fullWidth
                  sx={{
                    fontSize: '1.6rem',
                    '& .MuiInputLabel-root': {
                      fontSize: '1.6rem',
                    },
                    '& .MuiInputBase-root': {
                      fontSize: '1.6rem',
                    },
                  }}
                />
              }
              dateFormat="dd/MM/yyyy"
              maxDate={toDate}
            />
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              customInput={
                <TextField
                  label="To"
                  fullWidth
                  sx={{
                    fontSize: '1.6rem',
                    '& .MuiInputLabel-root': {
                      fontSize: '1.6rem',
                    },
                    '& .MuiInputBase-root': {
                      fontSize: '1.6rem',
                    },
                  }}
                />
              }
              dateFormat="dd/MM/yyyy"
              minDate={fromDate}
              maxDate={new Date()}
            />
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              fontSize: '1.6rem',
              background: '#f5f5f5',
              color: '#000',
              marginRight: '1rem',
              '&:hover': {
                background: '#e0e0e0',
              },
            }}
          >
            Hủy
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{
              fontSize: '1.6rem',
            }}
          >
            Tìm kiếm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DaySelectorModal;
