import clsx from 'clsx';
import { memo } from 'react';

import styles from './style.module.scss';

/**
 * @typedef CheckedInfo
 * @property {boolean} isCheckedAll
 * @property {boolean} isBillSelected
 * @property {string[]} checkedIds
 * @property {string} checkedCount
 */

/**
 *
 * @param {{
 *   checkedInfo: CheckedInfo;
 *   handleCheckedAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
 * }} param0
 * @returns
 */
const CheckedAll = ({ checkedInfo, handleCheckedAll }) => {
  return (
    <div className={clsx(styles.selectAllBtn)}>
      <input
        type="checkbox"
        id="checkAll"
        checked={checkedInfo.isCheckedAll}
        onChange={handleCheckedAll}
      />
      <label htmlFor="checkAll">Chọn tất cả</label>

      <div className={styles.billCheckedCount}>
        Đã chọn:
        <h3 className={styles.billCheckedCountContent}>
          {checkedInfo.checkedCount}
        </h3>
      </div>
    </div>
  );
};

export default memo(CheckedAll);
