import BaseModel from '..';

class BaseListFilter extends BaseModel {
  static properties = {
    page: 1,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  page: number;
  */

  get value() {
    return this;
  }
}

export default BaseListFilter;
