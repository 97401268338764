import React, { useEffect, useState } from 'react';
import { default as styles } from './HomeImage.module.scss';
import * as event from './event';
import { homeImageService } from '@services';
import { useCancellablePromise } from '@hooks';
import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

function HomeImage() {
    const [days, setDays] = useState([]);
    const [changeLists, setChangeLists] = useState([]);
    const { setToastMessage } = useOutletContext();

    const getDays = useCancellablePromise(
        (signal) => () => event.getDays(signal, setDays),
        [],
    );

    useEffect(() => {
        getDays.invoke();

        return () => getDays.cancel();
    }, []);

    const save = useCallback(
        () =>
            event.handleSaveClick(changeLists, setChangeLists, setToastMessage),
        [changeLists],
    );

    return (
        <div className={styles.container}>
            {days.map((day) => (
                <div
                    className={styles.dayWrapper}
                    onPaste={(e) =>
                        event.handleImagePaste(e, day, setDays, setChangeLists)
                    }
                    onDoubleClick={() =>
                        event.handleRemoveImage(
                            day,
                            changeLists,
                            setDays,
                            setChangeLists,
                        )
                    }
                    key={day.day}
                >
                    <input className={styles.dayOverlay} />

                    <img className={styles.imgDay} src={day.link} alt="" />

                    <h3 className={styles.dayHeading}>
                        {homeImageService.getDay(day.day)}
                    </h3>
                </div>
            ))}

            <div className={styles.btnWrapper}>
                <button
                    className={styles.btn}
                    disabled={changeLists.length ? '' : 'disabled'}
                    onClick={save}
                >
                    Lưu thay đổi
                </button>
            </div>
        </div>
    );
}

export default HomeImage;
