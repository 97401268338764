import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import ImportOrderDetail from '../import-order-detail';

export default class ImportOrderDetails extends BaseModel {
  static properties = {
    list: _p(ImportOrderDetail, new ImportOrderDetail()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: ImportOrder[];
  count: number | null;
  */
}
