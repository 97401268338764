import { _p, _pc } from '@/domain/models/utils/model-proto';
import QtPrice from '@/domain/models/value-object/qtPrice';
import BaseModel from '../../..';
import CategoriesReport from '../categories-report';
import PICsReport from '../pics-report';
import QtProductsReport from '../qt-products-report';

class ExportOrderReport extends BaseModel {
  static properties = {
    totalPrice: _pc(QtPrice, null),
    categories: _p(CategoriesReport, new CategoriesReport()),
    products: _p(QtProductsReport, new QtProductsReport()),
    pics: _p(PICsReport, new PICsReport()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default ExportOrderReport;
