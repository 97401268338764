import { defaultValue } from '@contexts/App';
import { authService } from '@services';

class Auth {
    isSignedIn = async (cancelSignal, setUser) => {
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');

        if (token && user) {
            try {
                const result = await authService.isSignedIn(
                    cancelSignal,
                    token,
                );

                if (!result || result.message !== 'Auth') {
                    setUser(defaultValue.user);
                }
            } catch (e) {
                setUser(defaultValue.user);
            }
        }
    };
}

export default Auth;
