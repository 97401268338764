import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from './FromToDatePicker.module.scss';
import { getEndDate, getStartDate } from './defaultValue';

function FromToDatePicker({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setBothDate,
}) {
  // Event handler state
  const [hideOption, setHideOption] = useState(true);
  const [hideModal, setHideModal] = useState(true);
  const [error, setError] = useState({
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    const callback = () => {
      setHideOption(true);
    };
    document.addEventListener('click', callback);

    return () => {
      document.removeEventListener('click', callback);
    };
  }, []);

  // Event handler
  const [options] = useState([
    'Tùy chỉnh',
    'Ngày hôm nay',
    'Ngày hôm qua',
    'Tuần này',
    'Tháng này',
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
    'Năm nay',
    'Năm ngoái',
  ]);

  // Event Handler: Start
  let date = new Date();
  const handleOptionClick = (index) => {
    let start, end;

    switch (index) {
      case 0:
        setHideModal(false);
        break;
      case 1:
        setBothDate(getStartDate(new Date()), getEndDate(new Date()));
        break;
      case 2:
        date.setDate(date.getDate() - 1);
        setBothDate(getStartDate(new Date(date)), getEndDate(new Date(date)));
        break;
      case 3:
        const currentDay = date.getDay() === 0 ? 7 : date.getDay();
        start = date.getDate() - (currentDay - 1);
        end = start + 6;
        setBothDate(
          getStartDate(new Date(date.getFullYear(), date.getMonth(), start)),
          getEndDate(new Date(date.getFullYear(), date.getMonth(), end)),
        );
        break;
      case 4:
        setBothDate(
          getStartDate(new Date(date.getFullYear(), date.getMonth(), 1)),
          getEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
        );
        break;
      case 17:
        setBothDate(
          getStartDate(new Date(date.getFullYear(), 0, 1)),
          getEndDate(new Date(date.getFullYear(), 11, 31)),
        );
        break;
      case 18:
        setBothDate(
          getStartDate(new Date(date.getFullYear() - 1, 0, 1)),
          getEndDate(new Date(date.getFullYear() - 1, 11, 31)),
        );
        break;
      default:
        if (index >= 5 && index <= 16) {
          setBothDate(
            getStartDate(new Date(date.getFullYear(), index - 5, 1)),
            getEndDate(new Date(date.getFullYear(), index - 4, 0)),
          );
        }
        break;
    }
    setHideOption(true);
  };

  const removeError = (type) => {
    setError((error) => ({
      ...error,
      [type]: '',
    }));
  };

  const handleStartDateChange = (date) => {
    if (date && date <= endDate) {
      removeError('startDate');
      setStartDate(date);
    } else {
      setError((error) => ({
        ...error,
        startDate: 'Chọn ngày nhỏ hơn hoặc bằng ngày kết thúc',
      }));
    }
  };

  const handleEndDateChange = (date) => {
    if (date >= startDate) {
      removeError('endDate');
      setEndDate(date);
    } else {
      setError((error) => ({
        ...error,
        endDate: 'Chọn ngày lớn hơn hoặc bằng ngày bắt đầu',
      }));
    }
  };
  // Event Handler: End

  return (
    <React.Fragment>
      <div
        className={styles.filterDate}
        onClick={(e) => {
          e.stopPropagation();
          setHideOption(false);
        }}
      >
        {`${startDate.toLocaleDateString(
          'vi-VN',
        )} - ${endDate.toLocaleDateString('vi-VN')}`}

        <ul
          className={clsx(styles.options, {
            [styles.hide]: hideOption,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={styles.option}
              onClick={() => handleOptionClick(index)}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>

      <div
        className={clsx(styles.modal, {
          [styles.hide]: hideModal,
        })}
        id={styles.modal}
        onClick={() => setHideModal(true)}
      >
        <div className={styles.overlay}></div>

        <div className={styles.modalBody} onClick={(e) => e.stopPropagation()}>
          <div className={styles.dateWrapper}>
            <label htmlFor="startDatePicker" className={styles.datePickerLabel}>
              Ngày bắt đầu
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              className={styles.datePicker}
              id="startDatePicker"
              dateFormat="dd/MM/yyyy"
            />
            {error.startDate && (
              <label className={styles.error} htmlFor="startDatePicker">
                {error.startDate}
              </label>
            )}
          </div>

          <div className={styles.dateWrapper}>
            <label htmlFor="endDatePicker" className={styles.datePickerLabel}>
              Ngày kết thúc
            </label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              className={styles.datePicker}
              id="endDatePicker"
              dateFormat="dd/MM/yyyy"
            />

            {error.endDate && (
              <label className={styles.error} htmlFor="endDatePicker">
                {error.endDate}
              </label>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default memo(FromToDatePicker);
