import facade from '@/domain/models/utils/facade';
import User from '@/domain/models/entities/user';
import { mapAsyncActions } from './utils/async-actions-helper';
import { asyncActions as userAsyncActions } from '../reducer/user';

export const initialState = facade.create(User, {});
export { actions as reducers } from '../reducer/user';
export const { asyncActions, extraReducers } = mapAsyncActions(
  'user',
  userAsyncActions,
);
