import React from 'react';
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import styles from './Market.module.scss';
import * as constant from '../constant';
import { TabBar } from '@components';
import eventHandler from './commonEvent';

function Market() {
    return (
        <div className={clsx(styles.container)}>
            <TabBar tabItems={constant.tabItems} />

            <Outlet context={eventHandler} />
        </div>
    );
}

export default Market;
