import { fetcher } from '../utilities';
import ORDER_CONST from '../config/order';

const urlOrigin = '/api/bill';

const getBills = (ids) => {
  return fetcher(`${urlOrigin}/list?id=${ids}`);
};

const getAll = async (
  cancelSignal,
  startDate,
  endDate,
  selectedShipService,
  selectedStatus,
  selectedProduct,
  order,
) => {
  let query = `start=${startDate.getTime()}&end=${endDate.getTime()}`;

  if (parseInt(selectedShipService)) {
    query += `&ship=${selectedShipService}`;
  }

  if (parseInt(selectedStatus)) {
    query += `&status=${selectedStatus}`;
  }

  if (parseInt(selectedProduct)) {
    query += `&product=${selectedProduct}`;
  }

  query += order;

  const result = await fetcher(`/api/bill/list?${query}`, {
    signal: cancelSignal,
  });

  return await result.json();
};

const queryBills = async (cancelSignal, query) => {
  const result = await fetcher(`${urlOrigin}/list?${query}`, {
    signal: cancelSignal,
  });

  return await result.json();
};

const create = (bill, img) => {
  const formData = new FormData();
  formData.append('image', img);
  formData.append('bill', JSON.stringify(bill));

  return fetcher(`${urlOrigin}/create`, {
    method: 'POST',
    body: formData,
  });
};

const updateStatus = (ids, status) => {
  return fetcher(`${urlOrigin}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id: ids, status: status }),
  });
};

const updateBill = (bill) => {
  const newBill = ORDER_CONST.EDITABLE_FIELDS.reduce(
    (prev, field) => {
      prev[field] = bill[field];

      return prev;
    },
    { id: bill.id },
  );

  return fetcher(`/api/v1/order`, {
    method: 'PUT',
    body: JSON.stringify(newBill),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getHint = (phone) => {
  return fetcher(`${urlOrigin}/hint?phone=${phone}`);
};

const updateShipService = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return fetcher(`${urlOrigin}/upload-excel`, {
    method: 'PUT',
    body: formData,
  });
};

export {
  getBills,
  queryBills,
  getAll,
  create,
  updateStatus,
  updateBill,
  getHint,
  updateShipService,
};
