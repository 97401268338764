import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import NoteItem from '../NoteItem/NoteItem';
import * as event from './event';
import styles from './NoteFolder.module.scss';

function NoteFolder({ noteGroupInit, pReload }) {
    const [newNoteBox, setNewNoteBox] = useState(false);
    const [newNote, setNewNote] = useState('');

    const [selectedItem, setSelectedItem] = useState(0);
    const [noteGroupHeading, setNoteGroupHeading] = useState(noteGroupInit);

    const [isNoteGroupChanged, setIsNoteGroupChanged] = useState(false);

    const inputNewNoteRef = useRef(null);
    const noteListRef = useRef(null);

    // Effect for closing the new title input
    useEffect(() => {
        const cb = () =>
            event.handleClickOutSideInput(
                newNote,
                noteGroupHeading.id,
                setNoteGroupHeading,
                setNewNote,
                setNewNoteBox,
            );

        document.addEventListener('click', cb);

        return () => document.removeEventListener('click', cb);
    }, [newNote, noteGroupHeading]);

    // Focus input when appear
    const prevNoteLength = useRef({ length: 0 }).current;
    useEffect(() => {
        if (inputNewNoteRef.current) inputNewNoteRef.current.focus();

        if (prevNoteLength.length < noteGroupHeading.notes.length) {
            noteListRef.current.scrollTop = noteListRef.current.scrollHeight;
        }

        return () => {
            prevNoteLength.length = noteGroupHeading.notes.length;
        };
    }, [newNoteBox, noteGroupHeading.notes]);

    // Resize textarea when text changes
    useEffect(() => {
        if (inputNewNoteRef.current) {
            inputNewNoteRef.current.style.height = 0;
            inputNewNoteRef.current.style.height = `${inputNewNoteRef.current.scrollHeight}px`;
        }
    }, [newNote]);

    return (
        <div className={styles.noteFolder} onClick={(e) => e.stopPropagation()}>
            <header className={styles.noteHeading}>
                <input
                    type="text"
                    className={styles.inputHeading}
                    value={noteGroupHeading.name}
                    onChange={(e) => {
                        setIsNoteGroupChanged(true);
                        setNoteGroupHeading((noteGroupHeading) => ({
                            ...noteGroupHeading,
                            name: e.target.value,
                        }));
                    }}
                    onBlur={() => {
                        event.saveNoteGroup(
                            noteGroupHeading,
                            setNoteGroupHeading,
                            isNoteGroupChanged,
                            setIsNoteGroupChanged,
                        );
                    }}
                />
                <i
                    className="fa-solid fa-trash"
                    onDoubleClick={() =>
                        event.handleRemoveDblClick(noteGroupHeading.id, pReload)
                    }
                />
            </header>

            <div
                className={clsx(styles.notes, {
                    [styles.inputting]: newNoteBox,
                })}
                ref={noteListRef}
            >
                {noteGroupHeading.notes.map((note) => (
                    <NoteItem
                        pNote={note}
                        key={note.id}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        pReload={() =>
                            event.reload(
                                noteGroupHeading.id,
                                setNoteGroupHeading,
                            )
                        }
                    />
                ))}

                {newNoteBox && (
                    <div className={styles.newNoteWrapper}>
                        <textarea
                            type="text"
                            className={styles.inputNewNote}
                            value={newNote}
                            onChange={(e) => {
                                setNewNote(e.target.value);
                            }}
                            placeholder="Nhập nội dung ghi chú..."
                            onKeyDown={(e) => {
                                if (
                                    e.key === 'Enter' &&
                                    !e.ctrlKey &&
                                    !e.shiftKey
                                ) {
                                    e.preventDefault();
                                    event.saveNewNote(
                                        newNote,
                                        noteGroupHeading.id,
                                        setNoteGroupHeading,
                                        setNewNote,
                                    );
                                }
                            }}
                            ref={inputNewNoteRef}
                        />

                        <div className={styles.btnBar}>
                            <button
                                className={styles.addBtn}
                                onClick={() =>
                                    event.saveNewNote(
                                        newNote,
                                        noteGroupHeading.id,
                                        setNoteGroupHeading,
                                        setNewNote,
                                    )
                                }
                            >
                                Thêm danh sách
                            </button>

                            <i
                                className={clsx(
                                    'fas fa-xmark',
                                    styles.removeIcon,
                                )}
                                onClick={() =>
                                    event.stopInputtingNewNote(
                                        setNewNote,
                                        setNewNoteBox,
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </div>

            {!newNoteBox && (
                <div
                    className={styles.newNoteTrigger}
                    onClick={() => {
                        setNewNoteBox(true);
                    }}
                >
                    <i className="fa-solid fa-add" />
                    Thêm ghi chú mới
                </div>
            )}
        </div>
    );
}

export default NoteFolder;
