import React, { useMemo, useState } from 'react';
import {
  MenuItem,
  Menu,
  Button,
  Box,
  ListSubheader,
  Stack,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { GROUPS_KEY, START_APP_YEAR } from './const';
import DaySelectorModal from './CustomDatePickerModal';
import { Close } from '@mui/icons-material';

const KEYS = {
  modify: 'modify',
  target: 'target',
  custom: 'custom',
};

const DateSelectBox = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateSelectorModalState, setDateSelectorModalState] = useState({
    open: false,
    target: '',
  });

  const groupedOptions = useMemo(() => {
    const years = [
      {
        key: GROUPS_KEY.year,
        label: 'Năm',
        options: [
          {
            label: 'Năm nay',
            key: KEYS.modify,
            value: 0,
          },
          {
            label: 'Năm ngoái',
            key: KEYS.modify,
            value: -1,
          },
          ...Array.from({ length: moment().year() - START_APP_YEAR - 2 }).map(
            (_, index) => {
              const year = START_APP_YEAR + index;

              return {
                label: `Năm ${year}`,
                key: KEYS.target,
                value: year,
              };
            },
          ),
        ],
      },
    ];
    const months = [
      {
        key: GROUPS_KEY.month,
        label: 'Tháng',
        options: [
          {
            label: 'Tùy chỉnh',
            key: KEYS.custom,
          },
          {
            label: 'Tháng hiện tại',
            key: KEYS.modify,
            value: 0,
          },
          {
            label: 'Tháng trước',
            key: KEYS.modify,
            value: -1,
          },
          ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            .map((targetMonth) => {
              const year = moment().year();
              const month = moment().month() + 1;
              const targetYear = targetMonth > month ? year - 1 : year;

              return {
                label: `Tháng ${targetMonth}/${targetYear}`,
                key: KEYS.target,
                value: {
                  year: targetYear,
                  month: targetMonth,
                },
              };
            })
            .sort(
              (
                { value: { month: month1, year: year1 } },
                { value: { month: month2, year: year2 } },
              ) => {
                if (year1 === year2) {
                  return month1 - month2;
                }

                return year1 - year2;
              },
            ),
        ],
      },
    ];
    const days = [
      {
        key: GROUPS_KEY.day,
        label: 'Ngày',
        options: [
          {
            label: 'Tùy chỉnh',
            key: KEYS.custom,
          },
          {
            label: 'Hôm nay',
            key: KEYS.modify,
            value: 0,
          },
          {
            label: 'Hôm qua',
            key: KEYS.modify,
            value: -1,
          },
        ],
      },
    ];

    const specialDays = [
      {
        key: GROUPS_KEY.special,
        label: 'Từ ngày ~ đến ngày',
        options: [
          {
            label: 'Tùy chỉnh',
            key: KEYS.custom,
          },
        ],
      },
    ];

    return [...days, ...months, ...years, ...specialDays];
  }, []);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option, group) => {
    let targetDate;
    switch (option.key) {
      case KEYS.modify:
        targetDate = moment().add(option.value, group.key);
        break;
      case KEYS.target:
        if (group.key === GROUPS_KEY.month) {
          const { year, month } = option.value;
          targetDate = moment()
            .year(year)
            .month(month - 1);
        } else if (group.key === GROUPS_KEY.year) {
          targetDate = moment().year(option.value);
        }
        break;
      case KEYS.custom:
        handleOpenDateSelectorModal(group.key);
        break;
      default:
        break;
    }

    if (option.key !== KEYS.custom) {
      setStartDate(targetDate.startOf(group.key).toDate());
      setEndDate(targetDate.endOf(group.key).toDate());
      handleClose();
    }
  };

  const handleSave = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    handleCloseDateSelectorModal();
    handleClose();
  };

  const handleOpenDateSelectorModal = (target) => {
    setDateSelectorModalState({
      open: true,
      target,
    });
  };
  const handleCloseDateSelectorModal = () => {
    setDateSelectorModalState({
      open: false,
      target: '',
    });
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          fontWeight: 'normal',
          fontSize: '1.6rem',
          height: '34px',
          color: '#000',
          background: '#ffF',
          minWidth: '200px',
          '& span': {
            display: 'none',
          },
          '&:hover': {
            background: '#ffF',
          },
        }}
      >
        {startDate && endDate
          ? `${startDate.toLocaleDateString(
              'vi-VN',
            )} - ${endDate.toLocaleDateString('vi-VN')}`
          : 'Chọn ngày'}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            width: '56rem',
          },
        }}
      >
        {groupedOptions.map((group, groupIndex) => (
          <Stack
            key={groupIndex}
            sx={{
              padding: '1rem',
              fontSize: '1.6rem',
            }}
          >
            <ListSubheader
              sx={{
                fontSize: '1.6rem',
                lineHeight: '2.4rem',
                marginBottom: '1rem',
                textTransform: 'uppercase',
                color: '#7f8cff',
                fontWeight: 'bold',
              }}
            >
              {group.label}
            </ListSubheader>
            <Stack
              flexDirection="row"
              gap={2}
              flexWrap="wrap"
              alignItems="center"
              paddingX="16px"
            >
              {group.options.map((option, index) => (
                <MenuItem
                  key={`${group.key}_${option.key}_${index}`}
                  onClick={() => handleSelect(option, group)}
                  sx={{
                    fontSize: '1.6rem',
                    border: '1px solid #7f8cff',
                    color: '#7f8cff',
                    height: '100%',
                    width: '150px',
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Stack>
        ))}

        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            padding: '16px',
          }}
        >
          <Close
            sx={{
              fontSize: '2rem',
              color: '#666',
            }}
          />
        </IconButton>
      </Menu>

      <DaySelectorModal
        open={dateSelectorModalState.open}
        target={dateSelectorModalState.target}
        onClose={handleCloseDateSelectorModal}
        onSave={handleSave}
      />
    </Box>
  );
};

export default DateSelectBox;
