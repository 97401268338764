import { ORDER_STATUS, ORDER_STATUS_NAMES } from '@/config/constants';
import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import User from '@/domain/models/entities/user';
import Ecommerce from '@/domain/models/entities/master/ecommerce';
import ShipService from '@/domain/models/entities/master/shipService';
import QtPrice from '@/domain/models/value-object/qtPrice';
import QtDate from '@/domain/models/value-object/qtDate';
import Phones from '@/domain/models/entities/user/phones';
import Category from '@/domain/models/entities/master/category';

class OrderEntity extends BaseModel {
  static properties = {
    id: null,
    name: null,
    address: null,
    price: _pc(QtPrice, null),
    imgURL: null,
    paid: _pc(QtPrice, null),
    ecommerceName: null,
    note: null,
    shipServiceBillID: null,
    statusID: null,
    createdAt: _pc(QtDate, null),
    updatedAt: _pc(QtDate, null),
    deletedAt: _pc(QtDate, null),
    ecommerceID: null,
    shipServiceID: null,
    productID: null,
    employeeID: null,
    employee: _p(User, null),
    product: _p(Category, null),
    ecommerce: _p(Ecommerce, null),
    shipService: _p(ShipService, null),
    phones: _p(Phones, new Phones()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  */

  get displayStatus() {
    return ORDER_STATUS_NAMES[this.status] || '';
  }

  get isComplete() {
    return this.statusID === ORDER_STATUS.COMPLETE;
  }

  get isWaiting() {
    return this.statusID === ORDER_STATUS.WAITING;
  }
}

export default OrderEntity;
