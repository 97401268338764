import { fetcher } from '@utilities';

const urlOrigin = '/api/home-image';

const get = async (cancelSignal) => {
    const result = await fetcher(urlOrigin, {
        signal: cancelSignal,
    });

    return result.json();
};

const getAllHomeImages = async (cancelSignal) => {
    const result = await fetcher(`${urlOrigin}/list`, {
        signal: cancelSignal,
    });
    const data = await result.json();

    return data;
};

export { get, getAllHomeImages };
