import BaseModel from '@/domain/models/entities/list-filter';

class QtProductListFilter extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    name: '',
    categoryId: '',
    limit: 15,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  page: number | null;
  */

  get value() {
    const result = { ...this };
    if (!result.name) delete result.name;
    if (!result.categoryId || result.categoryId === '0')
      delete result.categoryId;
    return result;
  }
}

export default QtProductListFilter;
