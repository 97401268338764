import { memo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { SelectField, InputField } from '@/components/molecules';
import { FORM } from '@/config/ui';
import Button from '@/components/Button';
import styles from './styles.module.scss';

const FormItem = ({ field, value }) => {
  const { type, key, label, onChange, name, readOnly } = field;

  switch (type) {
    case FORM.TYPE.INPUT:
      return (
        <InputField
          label={label}
          value={_.get(value, key)}
          onChange={onChange}
          name={name}
          disabled={readOnly}
        />
      );
    case FORM.TYPE.SELECT:
      return (
        <SelectField
          label={label}
          value={_.get(value, key)}
          onChange={onChange}
          name={name}
          disabled={readOnly}
        />
      );
  }
};

export const Form = memo(
  ({
    onSubmit,
    fields,
    value,
    className,
    submitDisabled,
    submitText = 'Cập nhật',
  }) => {
    const onFormSubmit = (e) => {
      e.preventDefault();
      onSubmit();
    };

    return (
      <form
        className={clsx(styles.form, { [className]: className })}
        onSubmit={onFormSubmit}
      >
        {fields.map((field) => (
          <FormItem key={field.key} field={field} value={value} />
        ))}

        <Button content={submitText} disabled={submitDisabled} />
      </form>
    );
  },
);
