import { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

/**
 * @param {{
 *   name: string;
 *   value: string;
 *   options: { value: string; label: string }[];
 *   disabled?: boolean;
 *   className?: string;
 *   customLabelKey?: string;
 *   customValueKey?: string;
 *   onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
 *   onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
 * }} props
 * @returns {JSX.Element}
 */
const _Select = ({
  name,
  value,
  options,
  disabled,
  className,
  customLabelKey,
  customValueKey,
  onChange,
  onBlur,
}) => (
  <select
    className={clsx(styles.container, {
      [className]: className,
    })}
    value={value}
    name={name}
    onChange={onChange}
    onBlur={onBlur}
    disabled={disabled}
  >
    {options.map(
      ({
        [customValueKey || 'value']: value,
        [customLabelKey || 'label']: label,
      }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ),
    )}
  </select>
);

export const Select = memo(_Select);
