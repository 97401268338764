import { memo, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Button, Stack } from '@mui/material';
import { useEventListener } from '@/hooks/ui';

import { NativeSelectContainer } from '@/components/organisms';

import styles from './style.module.scss';
import * as validate from './validate';
import { AddCircleOutline } from '@mui/icons-material';

/**
 * @typedef Key
 * @type {'expense' | 'price' | 'note'}
 */

const createInitialFormData = () => ({
  expense: 0,
  price: 0,
  note: '',
});

/**
 *
 * @param {{
 *  isAllowAddNewExpenseType: boolean;
 *  expenses: { id: number, name: string }[];
 *  handleAddNewExpense: (data: {
 *    expense: number;
 *    price: number;
 *    note: string;
 *  }) => Promise<boolean>;
 *  handleAddNewExpenseType: () => void;
 * }} param0
 * @returns
 */
const NewExpenseForm = ({
  isAllowAddNewExpenseType,
  expenses,
  handleAddNewExpense,
  handleAddNewExpenseType,
}) => {
  const [formData, setFormData] = useState(createInitialFormData());
  const [formError, setFormError] = useState({
    expense: '',
    price: '',
  });
  const [isShowAddNewExpense, setIsShowAddNewExpense] = useState(false);

  const masterDataOptions = useMemo(
    () => ({
      expenses: [{ id: 0, name: 'Chọn khoản chi' }, ...expenses],
    }),
    [expenses],
  );

  /**
   *
   * @param {Key} type
   */
  const removeError = (type) => {
    setFormError((error) => ({
      ...error,
      [type]: '',
    }));
  };

  /**
   *
   * @param {Key} key
   * @param {any} value
   * @returns
   */
  const validateFormField = (key, value) => {
    const validator = (() => {
      switch (key) {
        case 'expense':
          return validate.validateExpense;
        case 'price':
          return validate.validatePrice;
        default:
          return null;
      }
    })();

    removeError(key);
    const error = validator(value);
    setFormError((prevFormError) => ({
      ...prevFormError,
      [key]: error || '',
    }));

    return error;
  };

  /**
   *
   * @param {Key} key
   * @param {any} value
   * @returns
   */
  const setFormField = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };
  const handleExpenseChange = (e) => {
    removeError('expense');
    setFormField('expense', e.target.value);
  };
  const handleExpenseBlur = () => {
    validateFormField('expense', formData.expense);
  };

  const handlePriceChange = (e) => {
    const checkedValue = e.target.value
      ? e.target.value.replace(/\./g, '')
      : '0';

    if (!validateFormField('price', checkedValue)) {
      setFormField('price', +checkedValue);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isError =
      !!validateFormField('expense', formData.expense) ||
      !!validateFormField('price', `${formData.price}`);

    if (isError) {
      return;
    }

    const isSuccess = await handleAddNewExpense(formData);
    if (isSuccess) {
      setFormData(createInitialFormData());
      setIsShowAddNewExpense(false);
    }
  };

  useEventListener({
    event: 'click',
    callback: () => setIsShowAddNewExpense(false),
  });

  return (
    <Stack
      position="relative"
      alignItems="flex-start"
      onClick={(e) => e.stopPropagation()}
      sx={{
        paddingBottom: {
          xs: '12px',
          md: '20px',
        },
      }}
    >
      <Button
        variant="outlined"
        startIcon={<AddCircleOutline />}
        sx={{
          textTransform: 'none', // Prevents text from being uppercase
          fontWeight: 'bold', // Optional styling
          display: {
            md: 'none',
            fontSize: '1.6rem',
          },
        }}
        onClick={() => {
          setIsShowAddNewExpense((prev) => !prev);
        }}
      >
        Thêm khoản chi mới
      </Button>

      <form
        className={clsx(styles.form, {
          [styles.show]: isShowAddNewExpense,
        })}
        onSubmit={handleSubmit}
      >
        <div
          className={clsx(styles.inputWrapper, {
            [styles.error]: formError.expense,
          })}
          error={formError.expense}
        >
          <div className={styles.selectLabelWrapper}>
            <label htmlFor="reason" className={styles.label}>
              Khoản chi
            </label>

            {isAllowAddNewExpenseType && (
              <i
                className={clsx(styles.icon, 'fa fa-circle-plus')}
                onClick={handleAddNewExpenseType}
              />
            )}
          </div>

          <NativeSelectContainer
            value={formData.expense}
            onChange={handleExpenseChange}
            onBlur={handleExpenseBlur}
            selectClassName={styles.select}
            options={masterDataOptions.expenses}
          />
        </div>

        <div
          className={clsx(styles.inputWrapper, {
            [styles.error]: formError.price,
          })}
          error={formError.price}
        >
          <label htmlFor="price" className={styles.label}>
            Số tiền
          </label>

          <input
            id="price"
            className={styles.input}
            value={formData.price.toLocaleString('vi-VN')}
            onChange={handlePriceChange}
          />
        </div>

        <div className={styles.inputWrapper}>
          <label htmlFor="note" className={styles.label}>
            Ghi chú
          </label>
          <input
            id="note"
            className={styles.input}
            value={formData.note}
            onChange={(e) => {
              setFormField('note', e.target.value);
            }}
          />
        </div>

        <input className={styles.btn} type="submit" value="Thêm" />
      </form>
    </Stack>
  );
};

export default memo(NewExpenseForm);
