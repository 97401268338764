import { fetcher } from '../utilities';

const urlOrigin = '/api/role';

const getAll = async (cancelSignal) => {
  const result = await fetcher(`${urlOrigin}/list`, {
    signal: cancelSignal,
  });

  return await result.json();
};

export { getAll };
