const SHORT_PWD_ERR = 'Mật khẩu phải có ít nhất 8 ký tự';
const PWD_NOT_MATCH = 'Mật khẩu nhập lại không khớp';
const EMPTY_USERNAME = 'Nhập tên đăng nhập';
const EMPTY_PWD = 'Nhập mật khẩu cũ';
const EMPTY_NEW_PWD = 'Nhập mật khẩu mới';
const EMPTY_REPWD = 'Nhập lại mật khẩu';
const EMPTY_DISPLAYNAME = 'Nhập tên hiển thị';

export {
    SHORT_PWD_ERR,
    PWD_NOT_MATCH,
    EMPTY_USERNAME,
    EMPTY_PWD,
    EMPTY_NEW_PWD,
    EMPTY_REPWD,
    EMPTY_DISPLAYNAME,
};
