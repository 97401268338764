import { endpoint } from '@/config/endpoint';
import { request } from '@/utilities/request';
import { formatUsers } from './format';

const getAllUsers = async ({ user }) => {
  const options = {
    endpoint: endpoint.users,
    user,
  };
  const users = await request.get(options);

  return formatUsers(users);
};

const updateUsers = async ({ user, data }) => {
  const options = {
    endpoint: endpoint.users,
    user,
    data,
  };
  await request.put(options);

  return getAllUsers({ user });
};

export default { getAllUsers, updateUsers };
