import { memo, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/material';

import CustomDatePicker from '@/components/CustomDatePicker';

import styles from './style.module.scss';
import { NativeSelectContainer } from '@/components/organisms';
import { FilterList } from '@mui/icons-material';
import { useEventListener } from '@/hooks/ui';
import clsx from 'clsx';
import { RefreshButton } from '../RefreshButton';

/**
 *
 * @param {{
 *   value: any;
 *   handleFilterConditionChanged: (name: string, value: any) => void;
 *   expenses: { id: number; name: string }[];
 *   hasNewData: boolean;
 *   handleRefreshClick: () => void;
 * }} param0
 * @returns
 */
const FilterExpense = ({
  filterCondition,
  expenses,
  hasNewData,
  handleFilterConditionChanged,
  handleRefreshClick,
}) => {
  const [isShow, setIsShow] = useState(false);
  const masterDataOptions = useMemo(
    () => ({
      expenses: [{ id: 0, name: 'Chọn khoản chi' }, ...expenses],
    }),
    [expenses],
  );

  useEventListener({
    event: 'click',
    callback: () => setIsShow(false),
  });

  return (
    <Stack
      position="relative"
      onClick={(e) => e.stopPropagation()}
      alignItems="flex-start"
      flexDirection="row"
      sx={{
        marginLeft: {
          xs: 0,
          md: '16px',
        },
        marginTop: {
          xs: '8px',
          md: 0,
        },
      }}
    >
      <Button
        variant="outlined"
        startIcon={<FilterList />}
        sx={{
          textTransform: 'none', // Prevents text from being uppercase
          fontWeight: 'bold', // Optional styling
          fontSize: '1.6rem',
          display: {
            md: 'none',
          },
        }}
        onClick={() => {
          setIsShow((prev) => !prev);
        }}
      >
        Bộ lọc
      </Button>

      <Stack
        flexDirection="row"
        className={clsx(styles.filterContainer, {
          [styles.show]: isShow,
        })}
      >
        <NativeSelectContainer
          name="selectedExpense"
          selectClassName={styles.select}
          value={filterCondition.selectedExpense}
          onChange={(e) =>
            handleFilterConditionChanged(e.target.name, e.target.value)
          }
          options={masterDataOptions.expenses}
        />

        <CustomDatePicker
          startDate={filterCondition.startDate}
          endDate={filterCondition.endDate}
          setStartDate={(val) => handleFilterConditionChanged('startDate', val)}
          setEndDate={(val) => handleFilterConditionChanged('endDate', val)}
        />
      </Stack>

      <RefreshButton
        hasNewData={hasNewData}
        handleRefreshClick={handleRefreshClick}
      />
    </Stack>
  );
};

export default memo(FilterExpense);
