import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import actions from '@/application/actions';

import styles from './style.module.scss';
import { PaginationBar } from '@components';
import { useThrottle } from '@hooks';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { TOAST_SEVERITY } from '@/config/constants';
import { Input } from '@/components';
import { normalizeUnicode } from '@/utilities';

const PER_PAGE = 13;

function EditProvinces(props) {
  const {
    state: {
      masterData: { shipServices, provinces },
    },
    actions: { updateProvinces },
  } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const toast = useRef(null);

  const provincesData = useMemo(
    () =>
      provinces.list.map((item, idx) => ({
        item,
        shipServiceId: item.shipServiceId,
        idx,
        changed: false,
      })),
    [provinces.list],
  );
  const totalPage = useMemo(
    () => Math.floor(((data?.length ?? 0) + PER_PAGE) / PER_PAGE),
    [data],
  );
  const displayData = useMemo(
    () => data?.slice((page - 1) * PER_PAGE, page * PER_PAGE) ?? [],
    [data, page],
  );
  const updatePage = useThrottle((newPage) => setPage(newPage), 500);
  const onShipServiceChanged = (idx) => (e) => {
    const { value } = e.target;
    const newData = [...data];
    newData[idx].shipServiceId = value || null;
    newData[idx].changed =
      newData[idx].shipServiceId !== newData[idx].item.shipServiceId;

    setData(newData);
  };
  const savedList = useMemo(
    () => data.filter(({ changed }) => changed),
    [data],
  );
  const onSave = useCallback(() => {
    if (savedList.length === 0) return;

    const onSuccess = () =>
      toast?.current.show({
        severity: TOAST_SEVERITY.SUCCESS,
        summary: 'Thành công',
        detail: 'Lưu thành công',
      });
    const onError = () =>
      toast?.current.show({
        severity: TOAST_SEVERITY.SUCCESS,
        summary: 'Thất bại',
        detail: 'Lưu thất bại',
      });

    const updateData = savedList.map(({ item: { id }, shipServiceId }) => ({
      id,
      shipServiceId,
    }));

    updateProvinces({ data: updateData, onSuccess, onError });
  }, [savedList]);
  const onSearchChanged = useCallback((e) => {
    setSearchInput(e.target.value);
  }, []);
  const onSearch = useThrottle(
    () => {
      if (searchInput?.length >= 3) {
        const normalizedSearchInput = normalizeUnicode(searchInput);
        setData(
          provincesData.filter(({ item }) =>
            item?.normalizedName.includes(normalizedSearchInput),
          ),
        );
      } else {
        setData(provincesData);
      }
    },
    500,
    [searchInput, provincesData],
  );

  useEffect(onSearch, [searchInput, provincesData]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <h1 className={styles.header}>Thiết lập tỉnh thành</h1>
        <Input placeholder="Nhập tỉnh/thành..." value={searchInput} onChange={onSearchChanged} />
        <div className={styles.actions}>
          <button
            className={styles.button}
            onClick={onSave}
            disabled={savedList.length === 0}
          >
            Lưu
          </button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.table}>
            <ul className={clsx(styles.tableHeader, styles.row)}>
              <li className={styles.tableHeaderItem}>Tỉnh thành</li>
              <li className={clsx(styles.tableHeaderItem, styles.textLeft)}>
                Đơn vị vận chuyển
              </li>
            </ul>

            <ul className={styles.tableBody}>
              {displayData.map(
                ({ item: { id, name }, shipServiceId, changed, idx }) => (
                  <li
                    key={id}
                    className={clsx(styles.row, styles.tableBodyItem)}
                  >
                    <span className={styles.tableBodyItemText}>{name}</span>

                    <span
                      className={clsx(
                        styles.tableBodyItemText,
                        styles.textLeft,
                      )}
                    >
                      <select
                        className={clsx(styles.selectItem, {
                          [styles.mark]: changed,
                        })}
                        value={shipServiceId || ''}
                        onChange={onShipServiceChanged(idx)}
                      >
                        <option value="">ĐƠn vị vận chuyển</option>
                        {shipServices.list.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </select>

                      {!shipServiceId && (
                        <span className={styles.exclamation}>!</span>
                      )}
                    </span>
                  </li>
                ),
              )}
            </ul>
          </div>

          <div className={styles.contentLeftPagination}>
            <PaginationBar
              totalPage={totalPage}
              noPages={PER_PAGE}
              columnWidth={40}
              currentPage={page}
              setCurrentPage={updatePage}
            />
          </div>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({
  state,
});

export default connect(mapStateToProps, actions)(EditProvinces);
