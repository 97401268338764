import { memo } from 'react';

/**
 * 
 * @param {{
 *  children: React.ReactNode; 
 *  stopPropagation?: boolean;
 *  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
 * }} param0
 * @returns 
 */
const Div = ({ children, ...props }) => {
  const { onClick, stopPropagation = false, ...options } = props;

  const onDivClick = (e) => {
    stopPropagation && e.stopPropagation();
    onClick?.(e);
  };

  return (
    <div onClick={onDivClick} {...options}>
      {children}
    </div>
  );
};

export default memo(Div);
