import React from 'react';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { MobileHeader } from './MobileHeader';
import PCHeader from './PCHeader/PCHeader';

const routes = [
  {
    to: '/bill',
    text: 'TẠO ĐƠN',
  },
  {
    to: '/list',
    text: 'DANH SÁCH ĐƠN',
  },
  {
    to: '/expense',
    text: 'CHI',
  },
  {
    to: '/note',
    text: 'GHI CHÚ',
  },
  // {
  //   to: '/market',
  //   actualTo: '/market#detail',
  //   text: 'QUẢNG CÁO',
  // },
  {
    to: '/stock',
    text: 'KHO HÀNG',
  },
];
const routeUser = [
  {
    to: '/my-account',
    text: 'Thông tin tài khoản',
  },
  {
    to: '/statistic',
    text: 'Thống kê',
  },
  {
    to: '/settings',
    text: 'Cài đặt',
  },
  {
    to: '/sign-out',
    text: 'Đăng xuất',
  },
];

const routeSP = [
  {
    to: '/list',
    text: 'DANH SÁCH ĐƠN',
  },
  {
    to: '/expense',
    text: 'CHI',
  },
  {
    to: '/statistic',
    text: 'THỐNG KÊ',
  },
  {
    to: '/my-account',
    text: 'THÔNG TIN TÀI KHOẢN',
  },
  // {
  //   to: '/market',
  //   actualTo: '/market#detail',
  //   text: 'QUẢNG CÁO',
  // },
  // {
  //   to: '/accounts',
  //   text: 'QUẢN LÝ TÀI KHOẢN',
  // },
  {
    to: '/sign-out',
    text: 'ĐĂNG XUẤT',
  },
];

function Navigation({ user }) {
  return (
    <div className="grid wide">
      <PCHeader routes={routes} user={user} routeUser={routeUser}/>

      {/* Phone interface */}
      <MobileHeader routes={routeSP}/>
    </div>
  );
}

export default Navigation;
