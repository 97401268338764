import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

import styles from './ImportBillDetail.module.scss';

import unitOfWork from '@controllers';

function ImportBillDetail({
  invoice,
  close,
  statuses,
  setImportBills,
  setToastMessage,
}) {
  const [selectedStatus, setSelectedStatus] = React.useState(0);
  const createdAt = React.useMemo(() => {
    return (
      invoice.createdAt && moment(invoice.createdAt).format('DD/MM/YYYY HH:mm')
    );
  }, [invoice]);

  const stopPropagation = React.useCallback((e) => {
    e.stopPropagation();
  });

  const handleStatusChange = React.useCallback((e) => {
    setSelectedStatus(e.target.value);
  }, []);

  const handleClickOverlay = React.useCallback(
    () => {
      close();
    },
    [close],
  );

  const handleUpdateStatus = React.useCallback(
    (id, statusID) => () => {
      const newStatus = statuses.find(
        (status) => status.id === parseInt(statusID),
      );
      unitOfWork.importBill.updateStatus(
        id,
        newStatus,
        setToastMessage,
        setImportBills,
      );
    },
    [statuses],
  );

  const disabledSave = React.useMemo(() => {
    return (
      !invoice ||
      !invoice.status ||
      parseInt(selectedStatus) === parseInt(invoice.status.id)
    );
  }, [selectedStatus, invoice]);

  React.useEffect(() => {
    if (invoice && invoice.status) {
      setSelectedStatus(invoice.status.id);
    }
  }, [invoice]);

  return (
    <div className={styles.container}>
      <div className={styles.overlay} onClick={handleClickOverlay}></div>

      <div className={styles.content} onClick={stopPropagation}>
        <h3 className={styles.heading}>Chi tiết hóa đơn</h3>

        <div className={styles.invoice}>
          <div className={styles.invoiceInfo}>
            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Mã đơn hàng:</div>
              <div className={styles.value}>{invoice.id}</div>
            </div>

            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Nhà cung cấp:</div>
              <div className={styles.value}>
                {invoice.supplier && (
                  <React.Fragment>
                    <p>{invoice.supplier.name}</p>
                    <p>{invoice.supplier.company}</p>
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Ngày tạo:</div>
              <div className={styles.value}>{createdAt}</div>
            </div>

            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Tổng tiền:</div>
              <div className={styles.value}>
                {invoice.totalPrice &&
                  invoice.totalPrice.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
              </div>
            </div>

            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Người tạo:</div>
              <div className={styles.value}>
                {invoice.createUser &&
                  `${invoice.createUser.displayName}(${invoice.createUser.username})`}
              </div>
            </div>

            <div className={styles.invoiceInfoCell}>
              <div className={styles.label}>Số lượng:</div>
              <div className={styles.value}>{invoice.totalQuantity}</div>
            </div>
          </div>

          <div className={styles.invoiceInfoCell}>
            <div className={styles.label}>Tình trạng:</div>
            <div className={styles.value}>
              <select value={selectedStatus} onChange={handleStatusChange}>
                {statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className={styles.invoiceDetailInfo}>
          <div className={clsx(styles.header, styles.row)}>
            <div className={styles.cell}>Mã sản phẩm</div>
            <div className={styles.cell}>Tên sản phẩm</div>
            <div className={styles.cell}>Số lượng</div>
            <div className={styles.cell}>Đơn giá</div>
            <div className={styles.cell}>Thành tiền</div>
          </div>

          <div className={clsx(styles.body)}>
            {invoice.importBillDetails.map((detail) => (
              <div className={styles.row} key={detail.storeProductID}>
                <div className={styles.cell}>{detail.storeProductID}</div>
                <div className={styles.cell}>
                  {detail.storeProduct && detail.storeProduct.name}
                </div>
                <div className={styles.cell}>{detail.quantity}</div>
                <div className={styles.cell}>
                  {detail.unitPrice &&
                    detail.unitPrice.toLocaleString('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    })}
                </div>
                <div className={styles.cell}>
                  {(
                    (detail.quantity || 0) * (detail.unitPrice || 0)
                  ).toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.btns}>
          <button
            className={styles.btn}
            onClick={handleUpdateStatus(invoice.id, selectedStatus)}
            disabled={disabledSave}
          >
            Cập nhật
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImportBillDetail;
