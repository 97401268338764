import { memo, useState } from 'react';
import clsx from 'clsx';
import { useEventListener } from '@/hooks/ui';

import styles from './style.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { shopConfig } from '@/config/shop-config';

const MobileHeader = ({ routes }) => {
  const location = useLocation();
  const [hide, setHide] = useState(true);

  useEventListener({
    eventName: 'click',
    handler: () => {
      setHide(true);
    },
  });

  return (
    <ul className={clsx(styles.nav, styles.showOnPhone)}>
      <li>
        <Link to="/">
          <img
            className={styles.logo}
            src={shopConfig.images.logo}
            alt="Logo Quốc Tiến"
          />
        </Link>
      </li>

      <div className={clsx(styles.btnWrapper)}>
        <div className={styles.button}>
          <i
            className="fa-solid fa-bars"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          ></i>
        </div>

        <ul
          className={clsx(styles.btns, {
            [styles.hide]: hide,
          })}
        >
          {routes.map(({ to, actualTo, text }) => (
            <li key={to}>
              <Link
                className={clsx(styles.navBtn, {
                  [styles.active]: location.pathname.startsWith(to),
                })}
                to={actualTo || to}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </ul>
  );
};

export default memo(MobileHeader);
