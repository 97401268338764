import React from 'react';
import styles from './Loading.module.scss';

function Loading({ size, trackWidth, trackColor, color }) {
    const [style, setStyle] = React.useState({});

    React.useLayoutEffect(() => {
        const newStyle = {};

        if (size) {
            newStyle.width = size;
            newStyle.height = size;
        }

        if (color) {
            newStyle.borderColor = color;
        }

        if (trackColor) {
            newStyle.borderTopColor = trackColor;
        }

        if (trackWidth) {
            newStyle.borderWidth = trackWidth;
        }

        setStyle(newStyle);
    }, [size, trackColor, color]);

    return <div className={styles.loading} style={style} />;
}

export default React.memo(Loading);
