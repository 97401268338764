import React from 'react';
import clsx from 'clsx';

import styles from './MarketStatistic.module.scss';
import { MarketInfoTable, ToastMessage, CustomMonthPicker } from '@components';
import toastMessageType from '@constant/ToastMessage';
import { useThrottle } from '@hooks';

import unitOfWork from '@controllers';

function MarketStatistic() {
  const [ecommercePages, setEcommercePages] = React.useState([]);
  const [total, setTotal] = React.useState({});
  const [month, setMonth] = React.useState(new Date());
  const [, setActionShown] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: {
      title: 'Lỗi',
      duration: 3000,
      desc: 'Chọn ngày nhỏ hơn ngày hiện tại',
    },
  });
  // const [reportPopup, setReportPopup] = React.useState(false);
  // const [reportDate, setReportDate] = React.useState(new Date());
  // const [reportDone, setReportDone] = React.useState(true);
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  React.useEffect(() => {
    const closeActionMenu = () => setActionShown(false);
    document.addEventListener('click', closeActionMenu);

    return () => {
      document.removeEventListener('click', closeActionMenu);
    };
  }, []);

  const checkValidMonthChange = React.useCallback((selectedMonth) => {
    if (selectedMonth.getMonth() > new Date().getMonth()) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Thời điểm thống kê phải trước thời điểm hiện tại',
        },
      });
      return false;
    }

    return true;
  }, []);

  const fetchMarketInfo = () => {
    if (month) {
      const controller = new AbortController();
      unitOfWork.market.fetchByCustomDate(
        controller.signal,
        month.getMonth() + 1,
        month.getFullYear(),
        setEcommercePages,
        setTotal,
      );

      return () => {
        controller.abort();
      };
    } else {
      setEcommercePages(
        ecommercePages.map((page) => ({
          id: page.id,
          name: page.name,
          msgQuantity: 0,
          paid: 0,
          orders: 0,
        })),
      );
    }
  };

  React.useEffect(useThrottle(fetchMarketInfo, 1000), [month]);

  React.useEffect(() => {
    if (downloadUrl && downloadUrl.href && downloadUrl.fileName) {
      const downloadElement = document.createElement('a');
      downloadElement.download = downloadUrl.fileName;
      downloadElement.href = downloadUrl.href;
      downloadElement.click();
      setDownloadUrl(null);
    }
  }, [downloadUrl]);

  return (
    <React.Fragment>
      <div className={clsx(styles.header)}>
        <CustomMonthPicker
          label="Ngày thống kê"
          month={month}
          setMonth={setMonth}
          checkValid={checkValidMonthChange}
        />

        <div className={styles.rightSide}>
          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số đơn:</h3>
            <span className={styles.totalValue}>{total.orders || 0}</span>
          </div>

          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số tiền:</h3>
            <span className={styles.totalValue}>
              {(total.paid &&
                total.paid.toLocaleString('vi-VN', {
                  styles: 'currency',
                })) ||
                0}
            </span>
          </div>

          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số tin nhắn:</h3>
            <span className={styles.totalValue}>{total.msgQuantity || 0}</span>
          </div>
        </div>
      </div>

      <div className={clsx(styles.marketInfoTable)}>
        <MarketInfoTable
          pages={ecommercePages}
          inputDisabled={{ value: true, style: false }}
        />
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </React.Fragment>
  );
}

export default MarketStatistic;
