import _ from 'lodash';
import { useCallback } from 'react';

export const useArrayChange = (setFormValue) =>
  useCallback(
    (index, value, { field, Model }) =>
      setFormValue((prev) => {
        const newFieldValue = [...prev];
        if (Model && Model.create) {
          _.set(newFieldValue[index], field, Model.create(value));
        } else {
          _.set(newFieldValue[index], field, value);
        }

        return newFieldValue;
      }),
    [],
  );
