import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import ExportOrderDetail from '../export-order-detail';

export default class ExportOrderDetails extends BaseModel {
  static properties = {
    list: _p(ExportOrderDetail, new ExportOrderDetail()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: ExportOrder[];
  count: number | null;
  */
}
