import { createSlice } from '@reduxjs/toolkit';
import { mergeReducers } from './utils/merge-reducer';
import * as userReducer from './user';
import * as uiReducer from './ui';
import * as masterReducer from './master';
import * as orderReducer from './order';
import * as stockReducer from './stock';
import * as domainReducer from './domain';

const initialState = {
  user: userReducer.initialState,
  order: orderReducer.initialState,
  masterData: masterReducer.initialState,
  stock: stockReducer.initialState,
  ui: uiReducer.initialState,
  domain: domainReducer.initialState,
};

const { reducers, extraReducers, asyncActions } = mergeReducers(
  userReducer,
  uiReducer,
  orderReducer,
  masterReducer,
  stockReducer,
  domainReducer,
);

const state = createSlice({
  name: 'state',
  initialState,
  reducers,
  extraReducers,
});

export const actions = {
  ...state.actions,
  ...asyncActions,
};

export const reducer = state.reducer;
