import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import service from './service';

const ecommercesAdapter = createEntityAdapter();

const initialState = {
  status: 'idle',
  details: [],
  ecommerces: ecommercesAdapter.getInitialState(),
  filterDates: [],
  isTotalTab: false,
};

export const fetchByMonth = createAsyncThunk(
  'marketting/fetchByMonth',
  async ({ month, year }) => {
    const response = await service.fetchByMonth(month, year);

    return response?.success
      ? { details: response.details, ecommerces: response.ecommerces }
      : null;
  },
);

export const markettingSlice = createSlice({
  name: 'marketting',
  initialState,
  reducers: {
    toggleSelectedEcommerceID: (state, action) => {
      const id = action.payload;

      const selectedEcommerce = state.ecommerces.entities[id];

      if (selectedEcommerce) {
        selectedEcommerce.checked = !selectedEcommerce.checked;

        ecommercesAdapter.updateOne(state.ecommerces, selectedEcommerce);
      }
    },
    toggleAllDates: (state, action) => {
      const isChecked = action.payload;

      state.filterDates = state.filterDates.map((filterDate) => ({
        date: filterDate.date,
        checked: isChecked,
      }));
    },
    toggleAllPages: (state, action) => {
      const isChecked = action.payload;

      ecommercesAdapter.setAll(
        state.ecommerces,
        Object.values(state.ecommerces.entities).map((entity) => ({
          ...entity,
          checked: isChecked,
        })),
      );
    },
    toggleSelectedDate: (state, action) => {
      const date = action.payload;

      const filterDate = state.filterDates.find((item) => item.date === date);

      if (filterDate) {
        filterDate.checked = !filterDate.checked;
      }
    },
    setTab: (state, action) => {
      const currentTab = action.payload;

      state.isTotalTab = currentTab === '/market#total';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByMonth.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchByMonth.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload) {
          state.details = action.payload.details;

          ecommercesAdapter.setAll(
            state.ecommerces,
            action.payload.ecommerces.map((page) => ({
              ...page,
              checked: true,
            })),
          );

          state.filterDates = Object.keys(state.details).reduce(
            (prev, date) => {
              prev.push({
                date,
                checked: true,
              });

              return prev;
            },
            [],
          );
        } else {
          state.details = [];
          ecommercesAdapter.removeAll(state.ecommerces);
        }
      });
  },
});

export const {
  setSelectedEcommerceID,
  toggleSelectedEcommerceID,
  toggleAllPages,
  toggleAllDates,
  toggleSelectedDate,
  setTab,
} = markettingSlice.actions;

export default markettingSlice.reducer;

// Selector
export const {
  selectAll: pagesAll,
  selectIds: pageIdsAll,
  selectEntities: selectPageById,
} = ecommercesAdapter.getSelectors((state) => state.marketting.ecommerces);

export const selectFilterDates = (state) => state.marketting.filterDates;

export const detailAlls = (state) => state.marketting.details;

export const selectIsTotalTab = (state) => state.marketting.isTotalTab;

export const selectSelectedPageIds = createSelector(pagesAll, (pages) =>
  pages.filter((page) => page.checked).map((page) => page.id),
);

export const detailsBySelectedPage = createSelector(
  detailAlls,
  selectSelectedPageIds,
  (details, selectedPageIds) =>
    Object.keys(details).reduce((prev, date) => {
      prev[date] = _.pick(details[date], selectedPageIds);

      return prev;
    }, {}),
);

export const selectSelectedDates = createSelector(
  selectFilterDates,
  (filterDates) =>
    filterDates
      .filter((date) => date.checked)
      .map((filterDate) => filterDate.date),
);

export const selectSelectedPages = createSelector(
  detailsBySelectedPage,
  selectSelectedDates,
  (pages, selectedDates) => _.pick(pages, selectedDates),
);

export const selectAllPages = createSelector(
  selectSelectedPages,
  detailAlls,
  selectIsTotalTab,
  (pages, allPages, isTotalTab) => (isTotalTab ? allPages : pages),
);

export const selectTotalOrderByPage = createSelector(
  selectAllPages,
  pageIdsAll,
  (details, ids) => {
    const result = Object.keys(details).reduce(
      (prev, date) => {
        Object.keys(details[date]).forEach((pageId) => {
          prev[pageId] += details[date][pageId];
        });

        return prev;
      },
      ids.reduce((prev, id) => {
        prev[id] = 0;
        return prev;
      }, {}),
    );

    return result;
  },
);

export const selectTotalOrder = createSelector(
  selectTotalOrderByPage,
  (totalOrderByPage) => _.sum(Object.values(totalOrderByPage)),
);
