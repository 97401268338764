import { fetcher } from '../utilities';

const url = '/api/note-group';

const getAll = async (cancelSignal) => {
    const result = await fetcher(`${url}/list`, {
        signal: cancelSignal,
    });

    return await result.json();
};

const get = (noteGroupID) => fetcher(`${url}/index?noteGroup=${noteGroupID}`);

const update = (noteGroup) =>
    fetcher(`${url}/update`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteGroup),
    });

const create = (newGroup) =>
    fetcher(`${url}/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newGroup }),
    });

const remove = (id) =>
    fetcher(`${url}/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
    });

export { getAll, get, update, remove, create };
