export const getStartDate = (date) => {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  return startDate;
};

export const getEndDate = (date) => {
  const endDate = new Date(date);
  endDate.setHours(23, 59, 59, 999);
  return endDate;
};
