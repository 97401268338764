module.exports = (username, password, setError) => {
    const emptyError = 'Không được để trống dòng này';

    const newError = {
        wrong: '',
        username: username ? '' : emptyError,
        password: password ? '' : emptyError,
    };

    if (Object.values(newError).some(Boolean)) {
        setError(newError);
        return false;
    }

    return true;
};
