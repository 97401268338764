import { memo, useState } from 'react';
import clsx from 'clsx';
import { useEventListener } from '@/hooks/ui';

import styles from './style.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { shopConfig } from '@/config/shop-config';

const MobileHeader = ({ routes, user, routeUser }) => {
  const location = useLocation();
  const [hide, setHide] = useState(true);

  useEventListener({
    eventName: 'click',
    handler: () => {
      setHide(true);
    },
  });

  return (
    <ul className={clsx(styles.nav, styles.hideOnPhone)}>
      <li>
        <Link to="/">
          <img
            className={styles.logo}
            src={shopConfig.images.logo}
            alt="Logo Quốc Tiến"
          />
        </Link>
      </li>

      {routes.map(({ to, actualTo, text }) => (
        <li key={to}>
          <Link
            className={clsx(styles.navBtn, {
              [styles.active]: location.pathname.startsWith(to),
            })}
            to={actualTo || to}
          >
            <span>{text}</span>
          </Link>
        </li>
      ))}

      <li
        onClick={(e) => {
          e.stopPropagation();
          setHide(!hide);
        }}
      >
        <i className="fas fa-user" />
        <h3 className={styles.greeting}>Xin chào {user?.displayName}</h3>

        <ul
          className={clsx(styles.userOptions, {
            [styles.hide]: hide,
          })}
        >
          {routeUser.map(({ actualTo, to, text }) => (
            <li className={styles.userOption} key={to}>
              <Link className={clsx(styles.userLink)} to={actualTo || to}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
};

export default memo(MobileHeader);
