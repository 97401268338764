import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

import { TabBar } from '@components';
import styles from './StockManagement.module.scss';
import { connect } from 'react-redux';

const exportQtProducts = [
  {
    link: '/stock',
    name: 'Xuất hàng',
    phone: <i className="fa-solid fa-upload" />,
  },
  {
    link: '/stock/export',
    name: 'Đơn xuất',
    phone: <i className="fa-solid fa-receipt" />,
  },
];

const importQtProducts = [
  {
    link: '/stock/import/create',
    name: 'Nhập kho',
    phone: <i className="fa-solid fa-download" />,
  },
  {
    link: '/stock/import',
    name: 'Đơn nhập',
    phone: <i className="fa-solid fa-receipt" />,
  },
];

function StockManagement({ state: { user } }) {
  return (
    <div className={clsx(styles.container)}>
      <TabBar
        tabItems={
          user.canImportQtProduct
            ? [...exportQtProducts, ...importQtProducts]
            : exportQtProducts
        }
      />

      <Outlet />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps)(StockManagement);
