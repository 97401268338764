const validateYearInput = (value, setError) => {
    if (value.search(/[^0-9]/g) > -1) {
        setError('Chỉ nhập số từ 0-9');
        return false;
    }
    if (parseInt(value) > new Date().getFullYear()) {
        setError('Nhập năm nhỏ hơn năm hiện tại');
        return false;
    }
    return true;
};

export { validateYearInput };
