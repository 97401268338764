import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import styles from './TabBar.module.scss';

function TabBar({ tabItems, currentLink }) {
  const location = useLocation();
  const pathName = React.useMemo(() => {
    return currentLink || location.pathname.replace(/\/\d+$/, '');
  }, [location.pathname, currentLink]);

  return (
    <div className={clsx(styles.tab)}>
      {tabItems.map((tabItem) => (
        <Link
          className={clsx(styles.tabItem, {
            [styles.tabActive]: pathName === tabItem.link,
          })}
          to={tabItem.link}
          key={tabItem.link}
        >
          <div className={styles.icon}>{tabItem.phone}</div>
          <div className={styles.hideOnPhone}>{tabItem.name}</div>
        </Link>
      ))}
    </div>
  );
}

export default TabBar;
