export const mergeReducers = (...reducers) =>
  reducers.reduce(
    (prev, { reducers, extraReducers, asyncActions }) => {
      Object.assign(prev.reducers, reducers);
      if (extraReducers) {
        Object.assign(prev.extraReducers, extraReducers);
      }
      if (asyncActions) {
        Object.assign(prev.asyncActions, asyncActions);
      }

      return prev;
    },
    { reducers: {}, extraReducers: {}, asyncActions: {} },
  );
