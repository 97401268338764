import React from 'react';
import clsx from 'clsx';
import { classNames } from 'primereact/utils';
import { confirmDialog } from 'primereact/confirmdialog';
import { connect } from 'react-redux';

import { Button } from '@components';
import styles from './EditEcommerce.module.scss';
import EditOnRight from './EditOnRight';
import actions from '@/application/actions';

function EditEcommerce(props) {
  const {
    state: {
      masterData: { ecommerces },
    },
    actions: {
      deleteEcommerce, fetchEcommerces,
    }
  } = props;
  
  const [editButton, setEditButton] = React.useState({
    ecommerce: {
      name: '',
    },
    open: false,
  });

  const closeEditButton = React.useCallback(
    () =>
      setEditButton({
        ecommerce: {
          name: '',
        },
        open: false,
      }),
    [],
  );
  const openEditButton = React.useCallback(
    (ecommerce) => () => setEditButton({ ecommerce, open: true }),
    [],
  );
  const onDeleteItem = React.useCallback(
    ({ name, id }) => () =>
      confirmDialog({
        message: `Bạn có chắc chắn xóa ${name}?`,
        header: 'Xác nhận xóa',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => deleteEcommerce({ id }),
      }),
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Trang thương mại điện tử</h1>

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <ul className={clsx(styles.contentLeftHeader, styles.row)}>
            <li className={styles.contentLeftHeaderItem}>STT</li>
            <li className={styles.contentLeftHeaderItem}>Tên</li>
            <li className={styles.contentLeftHeaderItem}>
              Tài khoản Marketting
            </li>
            <li className={styles.contentLeftHeaderItem}>Hành động</li>
          </ul>

          <ul className={styles.contentLeftBody}>
            {ecommerces.list.map((item, index) => (
              <li
                key={item.id}
                className={clsx(styles.row, styles.contentLeftBodyItem)}
              >
                <span className={styles.contentLeftBodyItemText}>
                  {index + 1}
                </span>

                <span className={styles.contentLeftBodyItemText}>
                  {item.name}
                </span>

                <span
                  className={classNames(styles.contentLeftBodyItemText, {
                    'text-400': !item.markettingByUser,
                  })}
                >
                  {(item.markettingByUser && item.markettingByUser.username) ||
                    'Chưa có'}
                </span>

                <div className={clsx(styles.action)}>
                  <i
                    className={clsx(styles.actionIcon, 'fas fa-pen')}
                    onClick={openEditButton({
                      ...item,
                      index,
                    })}
                  />

                  <i
                    className={clsx(styles.actionIcon, 'fas fa-trash')}
                    onClick={onDeleteItem(item)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.contentRight}>
          {!editButton.open ? (
            <Button
              content={
                <React.Fragment>
                  <i className={clsx('fas fa-plus', styles.btnIcon)} />
                  Thêm mới
                </React.Fragment>
              }
              onClick={openEditButton({ name: '' })}
            />
          ) : (
            <EditOnRight
              selectedEcommerce={editButton.ecommerce}
              closeEditButton={closeEditButton}
              fetchEcommerces={fetchEcommerces}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(EditEcommerce);
