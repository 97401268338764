import { memo } from 'react';
import { Div, Select } from '@/components/atoms';

/**
 * 
 * @param {{
 *   name: string;
 *   options: { id: number, name: string }[];
 *   value: string;
 *   onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
 *   onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
 *   containerClassName?: string;
 *   selectClassName?: string;
 *   customLabelKey?: string;
 *   customValueKey?: string;
 * }} param0 
 * @returns 
 */
const SelectContainer = ({
  name,
  options,
  value,
  onChange,
  onBlur,
  containerClassName = '',
  selectClassName = '',
  customLabelKey = 'name',
  customValueKey = 'id',
}) => {
  return (
    <Div className={containerClassName}>
      <Select
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        className={selectClassName}
        customLabelKey={customLabelKey}
        customValueKey={customValueKey}
      />
    </Div>
  );
};

export default memo(SelectContainer);
