import BaseModel from '..';

class DisplayUser extends BaseModel {
  static properties = {
    id: null,
    username: null,
    name: null,
    email: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  username: string;
  name: string;
  email: string;
  role: Role;
  */
}

export default DisplayUser;
