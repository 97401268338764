import BaseValueObject from '../../../../value-object';

class Marketter extends BaseValueObject {
  static properties = {
    username: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  username: string | null;
  */
}

export default Marketter;
