import { mapAsyncActions } from './utils/async-actions-helper';
import { asyncActions as orderAsyncActions } from '../reducer/order';
import facade from '@/domain/models/utils/facade';
import OrderState from '@/domain/models/entities/order';

export const initialState = facade.create(OrderState, {});
export { actions as reducers } from '../reducer/order';
export const { asyncActions, extraReducers } = mapAsyncActions(
  'order',
  orderAsyncActions,
);
