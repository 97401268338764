import { noteGroupService } from '@services';

class NoteController {
    getAll = async (cancelSignal, setNoteGroups) => {
        try {
            const result = await noteGroupService.getAll(cancelSignal);

            if (!result.error) {
                setNoteGroups(result);
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Aborted!');
            }
        }
    };
}

export default NoteController;
