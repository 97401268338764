import clsx from 'clsx';
import React from 'react';

import styles from './MarketInfoTable.module.scss';
import { MarketInfoRow } from '@components';

function MarketInfoTable({ pages, bodyStyles, hide, ...props }) {
    const hiddenColumns = React.useMemo(() => {
        const hiddenColumns = {};

        if (hide && hide.length > 0) {
            hide.forEach((key) => {
                hiddenColumns[key] = true;
            });
        }

        return hiddenColumns;
    }, [hide]);

    return (
        <React.Fragment>
            <div
                className={clsx(
                    styles.header,
                    styles.row,
                    styles[
                        `num${5 - Object.values(hiddenColumns).length}Columns`
                    ],
                )}
            >
                <div className={styles.cell}>Kênh TMĐT</div>
                {!hiddenColumns['orders'] && (
                    <div className={styles.cell}>Số đơn</div>
                )}
                {!hiddenColumns['paid'] && (
                    <div className={styles.cell}>Số tiền</div>
                )}
                {!hiddenColumns['msgQuantity'] && (
                    <div className={styles.cell}>Số tin nhắn</div>
                )}
                {!hiddenColumns['pricePerMessage'] && (
                    <div className={styles.cell}>Tiền / tin nhắn</div>
                )}
            </div>

            <div className={clsx(styles.body)} style={bodyStyles}>
                {pages.map((page, index) => (
                    <MarketInfoRow
                        key={page.ecommerceID}
                        page={page}
                        index={index}
                        hiddenColumns={hiddenColumns}
                        {...props}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default MarketInfoTable;
