import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import NoteGroupRule from '../noteGroupRule';

class NoteGroupRules extends BaseModel {
  static properties = {
    list: _p(NoteGroupRule, new NoteGroupRule()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: NoteGroup;
  */
}

export default NoteGroupRules;
