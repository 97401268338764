import { _p } from '@/domain/models/utils/model-proto';
import User from '../../../user';
import BaseModel from '../baseReport';

class PICReport extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    pic: _p(User, new User()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default PICReport;
