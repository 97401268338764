import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { PaginationBar } from '@components';
import { Select } from '@components/atoms';
import {
  useCalculatePrice,
  useOnChangeArrayField,
  useRemoveItem,
  useThrottle,
} from '@/hooks';
import { TOAST_SEVERITY } from '@config/constants';
import actions from '@/application/actions';
import ImportOrder from '@/domain/models/entities/stock/import-order';
import { Button } from '@/components';
import styles from './ImportQtProducts.module.scss';
import QtPrice from '@/domain/models/value-object/qtPrice';
import { useClearArrayField } from '@/hooks/form/array';

const createInitialFormState = () => ({
  details: [],
  supplierId: '',
});

function ImportQtProducts(props) {
  const {
    state: {
      masterData: { categories },
      stock: { qtProducts, suppliers, filter },
      user,
      ui: { toastRef, isError },
    },
    actions: {
      fetchQtProducts,
      fetchSuppliers,
      updateQtProductFilter,
      importQtProducts,
      toggleError,
      confirmInfo,
    },
  } = props;

  const [formValue, setFormValue] = useState(createInitialFormState());
  const calcPrice = useCalculatePrice();
  const removeItem = useRemoveItem(setFormValue);
  const onChangeArrayField = useOnChangeArrayField(setFormValue);
  const onCleanArrayField = useClearArrayField(setFormValue);

  const totalPrice = formValue.details.reduce(
    (prev, { totalPrice }) => (prev += totalPrice),
    0,
  );

  const updateQtProductFilterThrottle = useThrottle(updateQtProductFilter, 500);
  const updateProductPage = (newProductPage) =>
    updateQtProductFilterThrottle({
      page: newProductPage,
    });
  const handleQtProductFilterInputChange = (e) =>
    updateQtProductFilterThrottle({ [e.target.name]: e.target.value });

  const handleFormInputChange = (e) =>
    setFormValue((_f) => ({
      ..._f,
      [e.target.name]: e.target.value,
    }));

  const handleInsertProduct = useCallback(
    (qtProduct) => () => {
      let newDetails = [...formValue.details];
      const prevDetail = newDetails.find(
        (detail) => detail.product.id === qtProduct.id,
      );

      const displayPrice = qtProduct.importPrice;

      if (prevDetail) {
        prevDetail.quantity += 1;
      } else {
        newDetails.push({
          product: qtProduct,
          displayPrice,
          quantity: 1,
        });
      }

      setFormValue({
        ...formValue,
        details: calcPrice(newDetails),
      });
    },
    [formValue],
  );
  const handleRowBlur = useCallback(
    () =>
      setFormValue((formValue) => ({
        ...formValue,
        details: calcPrice(formValue.details).filter(
          ({ quantity }) => quantity,
        ),
      })),
    [],
  );

  const handleProductQuantityChange = useCallback(
    (qtProduct) => (e) => {
      const newDetails = [...formValue.details];
      const index = newDetails.findIndex(
        (detail) => detail.product.id === qtProduct.id,
      );
      const value = parseInt(e.target.value);
      if (Number.isNaN(value)) {
        newDetails[index].quantity = 0;
        setFormValue({
          ...formValue,
          details: newDetails,
        });
      } else {
        newDetails[index].quantity = value;

        setFormValue({
          ...formValue,
          details: newDetails,
        });
      }
    },
    [formValue],
  );

  const handleProductPriceChange = useCallback(
    ({ id }) =>
      (e) =>
        onChangeArrayField(
          'details',
          'product.id',
          id,
          'displayPrice',
          QtPrice.create(e.target.value),
        ),
  );

  const handleRemoveProduct = useCallback(
    (id) => () => {
      console.log(
        '🚀 ~ file: ImportQtProduct.jsx:146 ~ ImportQtProducts ~ id:',
        id,
      );
      removeItem('details', 'product.id', id);
    },
    [],
  );

  const onImportOrderDetail = () =>
    importQtProducts({
      supplierId: formValue.supplierId,
      details: formValue.details.map((detail) => ({
        productId: detail.product.id,
        count: detail.quantity,
        displayPrice: detail.displayPrice.value,
      })),
      currentFilter: filter.qtProducts.value,
      onSuccess: () => {
        setFormValue(createInitialFormState());
        toastRef.show({
          type: TOAST_SEVERITY.SUCCESS,
          message: 'Tạo đơn thành công',
        });
      },
    });

  const handleSaveClick = React.useCallback(() => {
    const error = ImportOrder.validate(formValue);
    if (error) {
      toastRef.show({
        type: TOAST_SEVERITY.ERROR,
        message: error.map(({ message }) => <p>{message}</p>),
        callback: () =>
          toggleError({
            isError: true,
            callback: () =>
              setTimeout(() => toggleError({ isError: false }), 1500),
          }),
        life: 1500,
      });
      return;
    }

    confirmInfo({
      message: (
        <>
          <h3>Bạn có chắc chắn tạo đơn này không?</h3>
          {formValue.details.map(({ product, quantity }) => (
            <p key={product.id} className={styles.confirmText}>
              <span>{product.name}</span>
              <span>x{quantity}</span>
            </p>
          ))}
        </>
      ),
      onAccept: onImportOrderDetail,
    });
  }, [formValue]);

  const handleClearFields = () => onCleanArrayField('details');

  useEffect(() => {
    fetchSuppliers({ ...filter.suppliers, limit: 1000 });
  }, [filter.suppliers]);

  useEffect(() => {
    const { qtProducts: qtProductFilter } = filter;
    if (qtProductFilter.name.length === 0 || qtProductFilter.name.length > 1) {
      fetchQtProducts(qtProductFilter.value);
    }
  }, [filter.qtProducts]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.productContainer}>
        <input
          className={styles.searchProduct}
          placeholder="Tìm kiếm theo tên sản phẩm..."
          name="name"
          onChange={handleQtProductFilterInputChange}
        />

        <Select
          className={styles.searchProduct}
          name="categoryId"
          value={filter.qtProducts.categoryId}
          options={categories?.options || []}
          onChange={handleQtProductFilterInputChange}
        />

        <div className={styles.productList}>
          <div className={styles.productResult}>
            {qtProducts.list.map((qtProduct) => (
              <div
                className={styles.productItem}
                key={qtProduct.id}
                onClick={handleInsertProduct(qtProduct)}
              >
                <h3>{qtProduct.name}</h3>

                <div className={styles.productItemFooter}>
                  <span className={styles.productItemFooterPrice}>
                    {qtProduct.importPrice?.vnd}
                  </span>
                  <span className={styles.productItemFooterXMark}>x</span>
                  <span
                    className={clsx(styles.productItemFooterCount, {
                      [styles.hasStock]: qtProduct.count,
                    })}
                  >
                    {qtProduct.count} cái {!qtProduct.count && '(Hết hàng)'}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <PaginationBar
            totalPage={qtProducts.totalPage ?? 0}
            noPages={5}
            columnWidth={40}
            currentPage={filter.qtProducts.page}
            setCurrentPage={updateProductPage}
          />
        </div>
      </div>

      <div className={styles.invoiceContainer}>
        <div className={styles.invoiceUser}>
          <h3>Được tạo bởi {user.displayName}</h3>

          <div
            className={clsx(
              styles.invoiceDetailsBodyItem,
              styles.invoiceDetailsBodyItemAction,
            )}
            onDoubleClick={handleClearFields}
          >
            <i
              className={clsx(
                styles.invoiceDetailsBodyItemIcon,
                'fas fa-trash',
              )}
            />
          </div>
        </div>

        <div className={styles.invoiceDetails}>
          <div className={clsx(styles.invoiceDetailsHeader, styles.row)}>
            <div className={styles.invoiceDetailsHeaderItem} />
            <div className={styles.invoiceDetailsHeaderItem}>Tên sản phẩm</div>
            <div className={styles.invoiceDetailsHeaderItem}>Số lượng</div>
            <div className={styles.invoiceDetailsHeaderItem}>Đơn giá</div>
            <div className={styles.invoiceDetailsHeaderItem}>Thành tiền</div>
          </div>

          <div className={clsx(styles.invoiceDetailsBody)}>
            {formValue.details.map((invoiceDetail) => (
              <div
                className={clsx(styles.row)}
                key={`invoice_${invoiceDetail.product.id}`}
                onBlur={handleRowBlur}
              >
                <div
                  className={clsx(
                    styles.invoiceDetailsBodyItem,
                    styles.invoiceDetailsBodyItemAction,
                  )}
                  onDoubleClick={handleRemoveProduct(invoiceDetail.product.id)}
                >
                  <i
                    className={clsx(
                      styles.invoiceDetailsBodyItemIcon,
                      'fas fa-trash',
                    )}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  {invoiceDetail.product.name}
                </div>

                <div className={styles.invoiceDetailsBodyItem}>
                  <input
                    value={invoiceDetail.quantity}
                    onChange={handleProductQuantityChange(
                      invoiceDetail.product,
                    )}
                    className={styles.invoiceDetailsBodyItem}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  <input
                    value={invoiceDetail.displayPrice.vnd}
                    onChange={handleProductPriceChange(invoiceDetail.product)}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  {invoiceDetail.totalPrice &&
                    invoiceDetail.totalPrice.toLocaleString('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    })}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.invoiceInfoTotal} data-label="Tạm tính">
          {totalPrice &&
            totalPrice.toLocaleString('vi-VN', {
              style: 'currency',
              currency: 'VND',
            })}
        </div>
      </div>

      <div className={styles.invoiceInfoContainer}>
        <select
          className={styles.invoiceInfoSelect}
          value={formValue.supplierId}
          onChange={handleFormInputChange}
          name="supplierId"
        >
          <option value="">Chọn nhà cung cấp</option>
          {suppliers.list.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </select>

        <Button
          className={styles.invoiceInfoButton}
          onClick={handleSaveClick}
          disabled={isError}
          content={
            <>
              <i
                className={clsx(styles.invoiceInfoButtonIcon, 'fas fa-save')}
              />
              Tạo đơn
            </>
          }
        ></Button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(ImportQtProducts);
