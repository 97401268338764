import BaseValueObject from '.';

class QtPhone extends BaseValueObject {
  static properties = {
    value: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  value: string | null;
  */

  get display() {
    if (!this.value || this.value.length < 10) return '';
    const numbers = [[0, 4], [4, 7], [7]];
    const parts = numbers.map((number) => this.value.slice(...number));

    return parts.join(' ');
  }

  toString() {
    return this.value;
  }

  valueOf() {
    return this.value;
  }

  toJSON() {
    return this.value;
  }

  static getRawValue(phone, defaultValue) {
    const value = (phone.value ? phone.value : phone).replace(/[^0-9]/g, '');
    return value.length > 11 ? defaultValue : value;
  }
}

export default QtPhone;
